import React, {useEffect, useState} from 'react';

import '../dashboard/Dashboard.css'

import aaveIcon from './coin-icons/icon_aave.png';
import apeIcon from './coin-icons/icon_ape.png';
import adaIcon from './coin-icons/icon_ada.png';
import avaxIcon from './coin-icons/icon_avax.png';
import algoIcon from './coin-icons/icon_algo.png';
import atomIcon from './coin-icons/icon_atom.png';
import btcIcon from './coin-icons/icon_btc.png';
import daiIcon from './coin-icons/icon_dai.png';
import dotIcon from './coin-icons/icon_dot.png';
import compIcon from './coin-icons/icon_comp.png';
import dogeIcon from './coin-icons/icon_doge.png';
import ethIcon from './coin-icons/icon_eth.png';
import grtIcon from './coin-icons/icon_grt.png';
import linkIcon from './coin-icons/icon_link.png';
import ltcIcon from './coin-icons/icon_ltc.png';
import maticIcon from './coin-icons/icon_matic.png';
import mkrIcon from './coin-icons/icon_mkr.png';
import solIcon from './coin-icons/icon_sol.png';
import umaIcon from './coin-icons/icon_uma.png';
import uniIcon from './coin-icons/icon_uni.png';
import usdcIcon from './coin-icons/icon_usdc.png';
import xlmIcon from './coin-icons/icon_xlm.png';
import xrpIcon from './coin-icons/icon_xrp.png';
import BittrexMarketSummaryService from "../../services/bittrex-market-summary/BittrexMarketSummaryService";
import {formatNumber} from "../../services/utils/formatNumber";
import BittrexMarketSummary from "../../services/api/common/response/BittrexMarketSummary";
import TradeViewChart from "../crypto-graph/TradeViewChart";
import {Fees} from "../../services/api/common/response/Fees";
import FeesTickersService from "../../services/api/common/fees-tickers-service/FeesTickersService";
import {getCurrencySymbol} from "../../services/utils/cryptoSymbols";
import {useHistory, useLocation} from "react-router-dom";
import HttpClient from "../../services/api/HttpClient";

const ExchangedAssets = () => {

    const history = useHistory();

    const coinData = [
        {name: 'AAVE', icon: aaveIcon},
        {name: 'ADA', icon: adaIcon},
        {name: 'APE', icon: apeIcon},
        {name: 'AVAX', icon: avaxIcon},
        {name: 'ALGO', icon: algoIcon},
        {name: 'ATOM', icon: atomIcon},
        {name: 'BTC', icon: btcIcon},
        {name: 'COMP', icon: compIcon},
        {name: 'DAI', icon: daiIcon},
        {name: 'DOGE', icon: dogeIcon},
        {name: 'DOT', icon: dotIcon},
        {name: 'ETH', icon: ethIcon},
        {name: 'GRT', icon: grtIcon},
        {name: 'LINK', icon: linkIcon},
        {name: 'LTC', icon: ltcIcon},
        {name: 'MATIC', icon: maticIcon},
        {name: 'MKR', icon: mkrIcon},
        {name: 'SOL', icon: solIcon},
        {name: 'UMA', icon: umaIcon},
        {name: 'UNI', icon: uniIcon},
        {name: 'USDC', icon: usdcIcon},
        {name: 'XLM', icon: xlmIcon},
        {name: 'XRP', icon: xrpIcon},
    ];

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const coinSelectedParam = queryParams.get('selectedCoin') || 'BTC';

    const [coinSelected, setCoinSelected] = useState(coinSelectedParam);

    const [detailedMarketSummaries, setDetailedMarketSummaries] = useState<Array<BittrexMarketSummary> | null>([]);
    const [coinBid, setCoinBid] = useState(0);
    const [coinAsk, setCoinAsk] = useState(0);
    const [coinLast, setCoinLast] = useState(0);
    const [coinChange1Hour, setCoinChange1Hour] = useState('');
    const [coinChange1Day, setCoinChange1Day] = useState('');
    const [coinChange7Days, setCoinChange7Days] = useState('');

    const [fees, setFees] = useState<Fees | null>(null);

    const [buyFee, setBuyFee] = useState(0);
    const [sellFee, setSellFee] = useState(0);

    const [linesOfCoins, setLinesOfCoins] = useState<any>([]);
    const screenWidth = window.outerWidth;

    const feesTickerService: FeesTickersService = FeesTickersService.getInstance();
    const marketSummaryService = BittrexMarketSummaryService.getInstance();

    const [buyPrice, setBuyPrice] = useState('');
    const [buyAmount, setBuyAmount] = useState('');
    const [buyTotal, setBuyTotal] = useState('');
    const [canPlaceBuyOrder, setCanPlaceBuyOrder] = useState(false);

    const [buyOrderAmountValidation, setBuyOrderAmountValidation] = useState('');
    const [buyOrderTotalAmountValidation, setBuyOrderTotalAmountValidation] = useState('');

    const [sellPrice, setSellPrice] = useState('');
    const [sellAmount, setSellAmount] = useState('');
    const [sellTotal, setSellTotal] = useState('');
    const [canPlaceSellOrder, setCanPlaceSellOrder] = useState(false);
    const [sellOrderAmountValidation, setSellOrderAmountValidation] = useState('');
    const [sellOrderTotalAmountValidation, setSellOrderTotalAmountValidation] = useState('');

    const [usdtBalance, setUsdtBalance] = useState(10000);
    const [coinBalance, setCoinBalance] = useState(5);

    const httpClient = new HttpClient();

    useEffect(() => {

        // Calculate the number of lines and coins per line based on screen width
        let numLines, coinsPerLine;
        if (screenWidth >= 600) {
            numLines = 3;
            coinsPerLine = Math.ceil(coinData.length / numLines);
        } else {
            numLines = 6;
            coinsPerLine = 4;
        }

        // Generate lines with coins
        const linesArray = [];
        for (let i = 0; i < numLines; i++) {
            const startIdx = i * coinsPerLine;
            const endIdx = startIdx + coinsPerLine;
            const lineCoins = coinData.slice(startIdx, endIdx);

            linesArray.push(
                <div className="trade-coin-container-line" key={i}>
                    {lineCoins.map((coin) => (
                        <div
                            key={coin.name}
                            className={`trade-container-coin`}
                            style={{
                                borderRadius: coinSelected === coin.name ? '5px' : 'inherit',
                                border: coinSelected === coin.name ? '1px solid black' : 'inherit'
                            }}
                            onClick={() => setCoinSelected(coin.name)}
                        >
                            <span className="trade-container-coin-text">{coin.name}</span>
                            <img src={coin.icon} alt="ic" className="trade-icon"/>
                        </div>
                    ))}
                    {i === numLines - 1 && (
                        <div className="trade-container-coin" style={{cursor: 'default'}}>
                            <span className="trade-container-coin-text" style={{display: 'none'}}>none</span>
                            <img src={xlmIcon} alt="ic" className="trade-icon" style={{display: 'none'}}/>
                        </div>
                    )}
                </div>
            );
        }
        setLinesOfCoins(linesArray);
    }, [coinSelected, buyFee, sellFee]);

    useEffect(() => {
        const fetchMarketData = async () => {
            const summaries = marketSummaryService.getMarketDetailedSummaries();
            setDetailedMarketSummaries(summaries);
        };

        fetchMarketData().then(() => {
        });

        const intervalId = setInterval(fetchMarketData, 1000);

        resetForm();

        return () => {
            clearInterval(intervalId);
        };
    }, [coinSelected, buyFee, sellFee]);

    useEffect(() => {
        const marketSummary = detailedMarketSummaries?.find(summary => summary.market === 'USDT-' + coinSelected);
        if (marketSummary) {
            setCoinAsk(marketSummary.ask);
            setCoinBid(marketSummary.bid);
            setCoinLast(marketSummary.last);
            setCoinChange1Hour(marketSummary.percent_change_1h >= 0 ? ('+' + formatNumber(marketSummary.percent_change_1h).toString()) : (formatNumber(marketSummary.percent_change_1h).toString()));
            setCoinChange1Day(marketSummary.percent_change_24h >= 0 ? ('+' + formatNumber(marketSummary.percent_change_24h).toString()) : (formatNumber(marketSummary.percent_change_24h).toString()));
            setCoinChange7Days(marketSummary.percent_change_7d >= 0 ? ('+' + formatNumber(marketSummary.percent_change_7d).toString()) : (formatNumber(marketSummary.percent_change_7d).toString()));
        }

        if (fees) {
            setBuyFee(fees.currencies[coinSelected].orderBuy);
            setSellFee(fees.currencies[coinSelected].orderSell);
        }
    }, [coinSelected, detailedMarketSummaries, fees, buyFee, sellFee]);


    const resetForm = () => {
        setBuyTotal('');
        setBuyPrice('');
        setBuyAmount('');
        setBuyOrderAmountValidation('');
        setBuyOrderTotalAmountValidation('');
        setCanPlaceBuyOrder(false);

        setSellTotal('');
        setSellPrice('');
        setSellAmount('');
        setSellOrderAmountValidation('');
        setSellOrderTotalAmountValidation('');
        setCanPlaceSellOrder(false);
    }

    const handleBuyPriceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBuyPrice(event.target.value);
    }

    const handleBuyAmountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBuyAmount(event.target.value);
    }

    const handleBuyTotalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBuyTotal(event.target.value);
    }

    const handleSellPriceChanged = (event: any) => {
        setSellPrice(event.target.value);
    };

    const handleSellAmountChanged = (event: any) => {
        setSellAmount(event.target.value);
    };

    const handleSellTotalChanged = (event: any) => {
        setSellTotal(event.target.value);
    };

    const calculateTotal = (price: number, amount: number) => {
        const total = Math.floor(price * amount * 1e6) / 1e6; // Round to 6 decimals
        return total.toString();
    };

    const calculateAmount = (price: number, total: number) => {
        const amount = Math.floor(total / price * 1e6) / 1e6; // Round to 6 decimals
        return amount.toString();
    };

    const calculatePrice = (total: number, amount: number) => {
        const price = Math.floor(total / amount * 1e6) / 1e6; // Round to 6 decimals
        return price.toString();
    };

    const hasThisOrderMinTradeVolume = (usdtPrice: number, cryptoAmount: number): boolean => {
        const amountInUsdt = Math.ceil(usdtPrice * cryptoAmount * 1e6) / 1e6;
        const oneBtcInUsdt = marketSummaryService.getMarketDetailedSummary('USDT-BTC')?.last || 0;
        const amountInBtc = Math.ceil(amountInUsdt / oneBtcInUsdt * 1e6) / 1e6;
        const amountInSatoshi: number = 100000000 * amountInBtc;
        return amountInSatoshi > feesTickerService.getMinTradeValueInSatoshi().minTradeValueInSatoshi;
    }

    const getMinCryptoAmountToMeetMinTradeVolume = (): number => {
        const SATOSHI_MULTIPLIER: number = 100000000;
        const minTradeValueInSatoshi: number = feesTickerService.getMinTradeValueInSatoshi().minTradeValueInSatoshi + 1500;
        const minBtcAmount: number = minTradeValueInSatoshi / SATOSHI_MULTIPLIER;
        const oneBtcInUsdt: number = marketSummaryService.getMarketDetailedSummary('USDT-BTC')?.last || 0;

        const minAmountInUsdt = minBtcAmount * oneBtcInUsdt;
        const oneSelectedCoinInUsdt = marketSummaryService.getMarketDetailedSummary('USDT-' + coinSelected)?.last || 0;
        return Math.ceil(minAmountInUsdt / oneSelectedCoinInUsdt * 1e6) / 1e6;
    }

    useEffect(() => {
        if (buyPrice !== '' && buyAmount !== '') {
            const newBuyTotal = calculateTotal(parseFloat(buyPrice), parseFloat(buyAmount));
            if (!isNaN(parseFloat(newBuyTotal))) {
                setBuyTotal(newBuyTotal);
            }
        } else if (buyPrice !== '' && buyTotal !== '') {
            const newBuyAmount = calculateAmount(parseFloat(buyPrice), parseFloat(buyTotal));
            if (!isNaN(parseFloat(newBuyAmount))) {
                setBuyAmount(newBuyAmount);
            }
        } else if (buyAmount !== '' && buyTotal !== '') {
            const newBuyPrice = calculatePrice(parseFloat(buyTotal), parseFloat(buyAmount));
            if (!isNaN(parseFloat(newBuyPrice))) {
                setBuyPrice(newBuyPrice);
            }
        }

        setBuyOrderAmountValidation('');
        setBuyOrderTotalAmountValidation('');

        setCanPlaceBuyOrder(parseFloat(buyPrice) > 0 && parseFloat(buyAmount) > 0 && parseFloat(buyTotal) > 0);

    }, [buyPrice, buyAmount, buyTotal]);

    useEffect(() => {
        if (sellPrice !== '' && sellAmount !== '') {
            const newSellTotal = calculateTotal(parseFloat(sellPrice), parseFloat(sellAmount));
            if (!isNaN(parseFloat(newSellTotal))) {
                setSellTotal(newSellTotal);
            }
        } else if (sellPrice !== '' && sellTotal !== '') {
            const newSellAmount = calculateAmount(parseFloat(sellPrice), parseFloat(sellTotal));
            if (!isNaN(parseFloat(newSellAmount))) {
                setSellAmount(newSellAmount);
            }
        } else if (sellAmount !== '' && sellTotal !== '') {
            const newSellPrice = calculatePrice(parseFloat(sellTotal), parseFloat(sellAmount));
            if (!isNaN(parseFloat(newSellPrice))) {
                setSellPrice(newSellPrice);
            }
        }

        setSellOrderAmountValidation('');
        setSellOrderTotalAmountValidation('');

        setCanPlaceSellOrder(parseFloat(sellPrice) > 0 && parseFloat(sellAmount) > 0 && parseFloat(sellTotal) > 0);

    }, [sellPrice, sellAmount, sellTotal]);


    const placeBuyOrder = async (event: React.MouseEvent<HTMLButtonElement>) => {
        history.push(`/trade`);
    }

    const placeSellOrder = async (event: React.MouseEvent<HTMLButtonElement>) => {
        history.push(`/trade`);
    }

    return (
        <div>
            <div className="dashboard-container">
                <h1 className="title" style={{marginBottom: '30px'}}>Exchanged Assets</h1>
                <div className="trade-coin-container">
                    {linesOfCoins}
                </div>

                <div style={{marginBottom: '50px'}}>
                    <TradeViewChart tradingPair={`${coinSelected}USDT`}
                                    width={screenWidth < 600 ? screenWidth - 30 : 695}/>
                </div>

                <div className="trade-ask-bid-container">
                    <div className="trade-ask-bid-container-box">
                        <p className="trade-ask-bid-container-box-title">Ask</p>
                        <p className="trade-ask-bid-container-box-value">${formatNumber(coinAsk)}</p>
                    </div>
                    <div className="trade-ask-bid-container-box">
                        <p className="trade-ask-bid-container-box-title">Bid</p>
                        <p className="trade-ask-bid-container-box-value">${formatNumber(coinBid)}</p>
                    </div>
                    <div className="trade-ask-bid-container-box">
                        <p className="trade-ask-bid-container-box-title">Last</p>
                        <p className="trade-ask-bid-container-box-value">${formatNumber(coinLast)}</p>
                    </div>
                    <div className="trade-ask-bid-container-box">
                        <p className="trade-ask-bid-container-box-title">1H</p>
                        <p className="trade-ask-bid-container-box-value">{coinChange1Hour}%</p>
                    </div>
                    <div className="trade-ask-bid-container-box">
                        <p className="trade-ask-bid-container-box-title">1D</p>
                        <p className="trade-ask-bid-container-box-value">{coinChange1Day}%</p>
                    </div>
                    <div className="trade-ask-bid-container-box">
                        <p className="trade-ask-bid-container-box-title">7D</p>
                        <p className="trade-ask-bid-container-box-value">{coinChange7Days}%</p>
                    </div>
                </div>

                <div className="rectangle"
                     style={{backgroundColor: "#DDD", marginBottom: '20px', marginTop: '20px'}}></div>

                <div className="trade-ask-bid-container-buy-sell-coin"
                     style={{flexDirection: screenWidth < 600 ? 'column' : 'row'}}>
                    <div className="trade-ask-bid-container-buy-sell-coin-column">
                        <p className="trade-ask-bid-container-buy-sell-coin-p-buy-sell">BUY {coinSelected}</p>
                        <p style={{marginBottom: '-2px', cursor: 'pointer'}}
                           className="trade-ask-bid-container-buy-sell-coin-text">
                            Ask:&nbsp;
                            <span style={{
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                                  onClick={() => {
                                      setBuyPrice(coinAsk.toString());
                                  }}>
                                {getCurrencySymbol('USDT') + formatNumber(coinAsk)}
                            </span>
                        </p>
                        <p style={{marginBottom: '-2px', cursor: 'pointer'}}
                           className="trade-ask-bid-container-buy-sell-coin-text">
                            You Have: <span style={{
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}
                                            onClick={() => {
                                                setBuyAmount('');
                                                setBuyTotal(usdtBalance.toString());
                                            }}>{getCurrencySymbol('USDT') + formatNumber(usdtBalance)}</span></p>
                        <p className="trade-ask-bid-container-buy-sell-coin-text">Fee: 0.85%</p>
                        <form>
                            <input type="number" title="Price (USDT)" placeholder="Price (USDT)" value={buyPrice}
                                   onChange={handleBuyPriceChanged}/>
                            <input type="number"
                                   title={`Amount (${coinSelected})`}
                                   placeholder={`Amount (${coinSelected})`}
                                   value={buyAmount}
                                   onChange={handleBuyAmountChanged}/>
                            {buyOrderAmountValidation && buyAmount && (
                                <div className="order-validation-message">{buyOrderAmountValidation}
                                    <span style={{
                                        color: 'black',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '10px'
                                    }}
                                          onClick={() => {
                                              setBuyAmount(getMinCryptoAmountToMeetMinTradeVolume().toString());
                                          }}>{getCurrencySymbol(coinSelected) + getMinCryptoAmountToMeetMinTradeVolume().toString()}</span>
                                </div>)}
                            <input type="number"
                                   title="Total (USDT)"
                                   placeholder="Total (USDT)"
                                   value={buyTotal}
                                   onChange={handleBuyTotalChanged}/>
                            {buyOrderTotalAmountValidation && buyTotal && (
                                <div className="order-validation-message">
                                    {buyOrderTotalAmountValidation} You have&nbsp;
                                    <span style={{
                                        color: 'black',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '10px'
                                    }}
                                          onClick={() => {
                                              setBuyAmount('');
                                              setBuyTotal(usdtBalance.toString());
                                          }}>{getCurrencySymbol('USDT') + formatNumber(usdtBalance)}</span>
                                </div>)}
                            <button className="trade-button"
                                    style={{
                                        backgroundColor: !canPlaceBuyOrder ? '#BBB' : '#111',
                                        cursor: 'pointer'
                                    }}
                                    onClick={placeBuyOrder}>BUY
                            </button>
                        </form>

                        <p className="trade-ask-bid-container-buy-sell-coin-text"
                           style={{
                               textDecoration: buyTotal !== '' || buyPrice !== '' || buyAmount !== '' ? 'underline' : 'inherit',
                               cursor: buyTotal !== '' || buyPrice !== '' || buyAmount !== '' ? 'pointer' : 'inherit',
                               fontWeight: buyTotal !== '' || buyPrice !== '' || buyAmount !== '' ? 'bold' : 'inherit',
                               color: buyTotal !== '' || buyPrice !== '' || buyAmount !== '' ? 'black' : 'white',
                               alignSelf: 'baseline',
                               paddingLeft: '10px'
                           }}
                           onClick={() => {
                               if (buyTotal !== '' || buyPrice !== '' || buyAmount !== '') {
                                   setBuyTotal('');
                                   setBuyPrice('');
                                   setBuyAmount('');
                               }
                           }}>Reset Form</p>
                    </div>

                    {screenWidth < 600 && (<div className="rectangle" style={{
                        backgroundColor: "#DDD",
                        marginBottom: '30px',
                        marginTop: '60px'
                    }}></div>)}

                    <div className="trade-ask-bid-container-buy-sell-coin-column">
                        <p className="trade-ask-bid-container-buy-sell-coin-p-buy-sell">SELL {coinSelected}</p>
                        <p style={{
                            marginBottom: '-2px',
                            cursor: 'pointer'
                        }} className="trade-ask-bid-container-buy-sell-coin-text">
                            Bid:&nbsp;
                            <span onClick={() => {
                                setSellPrice(coinBid.toString());
                            }}
                                  style={{
                                      cursor: 'pointer',
                                      textDecoration: 'underline'
                                  }}>
                                {getCurrencySymbol('USDT') + formatNumber(coinBid)}
                            </span>
                        </p>
                        <p style={{
                            marginBottom: '-2px',
                            cursor: 'pointer'
                        }}
                           className="trade-ask-bid-container-buy-sell-coin-text">
                            You Have: <span onClick={() => {
                            setSellAmount(coinBalance.toString());
                        }}
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline'
                                            }}>{getCurrencySymbol(coinSelected) + formatNumber(coinBalance)} </span>
                        </p>
                        <p className="trade-ask-bid-container-buy-sell-coin-text">Fee: 0.85%</p>
                        <form>
                            <input type="number"
                                   title="Price (USDT)"
                                   placeholder="Price (USDT)"
                                   value={sellPrice}
                                   onChange={handleSellPriceChanged}/>
                            <input type="number"
                                   title={`Amount (${coinSelected})`}
                                   placeholder={`Amount (${coinSelected})`}
                                   value={sellAmount}
                                   onChange={handleSellAmountChanged}/>
                            {sellOrderAmountValidation && sellAmount && (
                                <div className="order-validation-message">{sellOrderAmountValidation}
                                    You have&nbsp;
                                    <span style={{
                                        color: 'black',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '10px'
                                    }}
                                          onClick={() => {
                                              setSellAmount(coinBalance.toString());
                                          }}>{getCurrencySymbol(coinSelected) + formatNumber(coinBalance)}</span>
                                </div>)}
                            {sellOrderTotalAmountValidation && sellAmount && (
                                <div className="order-validation-message">
                                    {sellOrderTotalAmountValidation}
                                    <span style={{
                                        color: 'black',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '10px'
                                    }}
                                          onClick={() => {
                                              setSellAmount(getMinCryptoAmountToMeetMinTradeVolume().toString());
                                          }}>{getCurrencySymbol(coinSelected) + getMinCryptoAmountToMeetMinTradeVolume().toString()}</span>
                                </div>)}
                            <input type="number"
                                   title="Total (USDT)"
                                   placeholder="Total (USDT)"
                                   value={sellTotal}
                                   onChange={handleSellTotalChanged}
                            />
                            <button className="trade-button"
                                    style={{
                                        backgroundColor: !canPlaceSellOrder ? '#BBB' : '#111',
                                        cursor: 'pointer'
                                    }}
                                    onClick={placeSellOrder}>SELL
                            </button>
                        </form>

                        <p className="trade-ask-bid-container-buy-sell-coin-text"
                           style={{
                               textDecoration: sellTotal !== '' || sellPrice !== '' || sellAmount !== '' ? 'underline' : 'inherit',
                               cursor: sellTotal !== '' || sellPrice !== '' || sellAmount !== '' ? 'pointer' : 'inherit',
                               fontWeight: sellTotal !== '' || sellPrice !== '' || sellAmount !== '' ? 'bold' : 'inherit',
                               alignSelf: 'baseline',
                               color: sellTotal !== '' || sellPrice !== '' || sellAmount !== '' ? 'black' : 'white',
                               paddingLeft: '10px'
                           }}
                           onClick={() => {
                               if (sellTotal !== '' || sellPrice !== '' || sellAmount !== '') {
                                   setSellTotal('');
                                   setSellPrice('');
                                   setSellAmount('');
                               }
                           }}>Reset Form</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExchangedAssets;