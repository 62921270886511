import BittrexMarketSummary from "../../services/api/common/response/BittrexMarketSummary";

class DefaultBittrexMarketSummary implements BittrexMarketSummary {
    market: string = '';
    ask: number = 0;
    bid: number = 0;
    last: number = 0;
    updated: number = 0;
    percent_change_1h: number = 0;
    percent_change_24h: number = 0;
    percent_change_7d: number = 0;
}

export default DefaultBittrexMarketSummary;
