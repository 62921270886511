import HttpClient from "../../services/api/HttpClient";
import {Link, Redirect, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_SITE_KEY} from "../../services/constants";
import {useAuth} from "../../services/auth/AuthContext";
import {api} from "../../services/api/api";
import {
    ChangePasswordAfterForgotItViewModel
} from "../../services/api/web/request/ChangePasswordAfterForgotItViewModel";

interface GeneratePasswordRouteParams {
    forgotPasswordToken: string;
}

const GenerateNewPassword = () => {

    const {isLoggedIn} = useAuth();

    const httpClient = new HttpClient();
    const {forgotPasswordToken} = useParams<GeneratePasswordRouteParams>();

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const [passwordValidation, setPasswordValidation] = useState('');
    const [passwordConfirmValidation, setPasswordConfirmValidation] = useState('');

    const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [displayError, setDisplayError] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const [generateNewPasswordButtonClass, setGenerateNewPasswordButtonClass] = useState('login-button');

    useEffect(() => {

        const isPasswordValid = /^(?=.*\d)(?=.*[A-Z]).{8,}$/.test(password);

        const isValid = password.trim() !== ''
            && passwordConfirm.trim() !== ''
            && password === passwordConfirm
            && isPasswordValid
            && isPasswordValid
            && isRecaptchaChecked;
        setIsFormValid(isValid);

        setPasswordValidation(
            password.trim() === '' ? 'Password should not be empty' :
                !isPasswordValid ? 'Password must have at least 8 characters, one uppercase letter, and one digit' :
                    '');
        setPasswordConfirmValidation(
            passwordConfirm.trim() === '' ? 'Password Confirm should not be empty' :
                password !== passwordConfirm ? 'Passwords do not match' : '');

        setGenerateNewPasswordButtonClass(isValid ? 'login-button' : 'login-button disabled');

    }, [password, passwordConfirm, isRecaptchaChecked]);

    if (isLoggedIn) {
        return <Redirect to="/dashboard"/>;
    }

    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const handlePasswordChange = (event: any) => {
        setDisplayError('');
        setPassword(event.target.value);
    };

    const handlePasswordConfirmChange = (event: any) => {
        setDisplayError('');
        setPasswordConfirm(event.target.value);
    };

    const handleRecaptchaChange = (event: any) => {
        setDisplayError('');
        setIsRecaptchaChecked(event !== null);
    };

    const handleGenerateNewPasswordButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!isFormValid) {
            return;
        }

        let captchaVal = recaptchaRef.current?.getValue();
        if (!captchaVal) {
            return;
        }

        recaptchaRef.current?.reset();
        setIsRecaptchaChecked(false);

        let changePasswordAfterForgotItViewModel: ChangePasswordAfterForgotItViewModel = {
            forgotLoginToken: forgotPasswordToken,
            password: password
        }

        const genericErrorMessage = 'An error occurred. Please try again later. If the problem persists, contact our staff at office@vtrader.io.';

        try {
            let response = await httpClient.post<ChangePasswordAfterForgotItViewModel, any>(api.web.GENERATE_NEW_PASSWORD, changePasswordAfterForgotItViewModel);

            if (response.status !== 200) {
                if (response.data.message) {
                    if (response.data.message === "Invalid reset password token" ||
                        response.data.message === "Reset password token expired") {
                        setDisplayError(response.data.message);
                    } else {
                        setDisplayError(genericErrorMessage);
                    }
                } else {
                    setDisplayError(genericErrorMessage);
                }
                setIsFormValid(false);
                setGenerateNewPasswordButtonClass('register-button disabled');
            } else {
                setConfirmationMessage('Your password has been reset. You can login now using the new password.');
                setIsFormValid(false);
                setGenerateNewPasswordButtonClass('register-button disabled');
            }
        } catch (error) {
            setDisplayError(genericErrorMessage);
        } finally {
            resetForm();
        }
    };

    const resetForm = () => {
        setPassword('');
        setPasswordConfirm('');
        setIsRecaptchaChecked(false);
    }

    return (
        <div className="login-form">
            <p style={{marginBottom: '-10px'}}>Generate new password</p>
            <form>
                <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange}/>
                {passwordValidation && password && <div className="validation-message">{passwordValidation}</div>}

                <input type="password" placeholder="Password Confirm" value={passwordConfirm}
                       onChange={handlePasswordConfirmChange}/>
                {passwordConfirmValidation && passwordConfirm &&
                    <div className="validation-message">{passwordConfirmValidation}</div>}
                <div className="recaptcha">
                    <ReCAPTCHA
                        sitekey={RECAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        onChange={handleRecaptchaChange}
                    />
                </div>
                <button className={generateNewPasswordButtonClass} disabled={!isFormValid}
                        onClick={handleGenerateNewPasswordButtonClick}>
                    GENERATE NEW PASSWORD
                </button>
                {displayError !== '' && <div className="registration-error-message">{displayError}</div>}
                {confirmationMessage !== '' &&
                    <div className="registration-confirmation-message">{confirmationMessage}</div>}
            </form>
            <Link to='/login' style={{color: 'black', fontSize: '13px'}}>Go back to login</Link>
        </div>
    );
}

export default GenerateNewPassword;