import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import './Settings.css';
import UserService from "../../services/user/UserService";
import JwtUser from "../../services/api/common/jwtUser/JwtUser";
import 'react-datepicker/dist/react-datepicker.css';
import UserServiceUtils from "../../services/user/UserServiceUtils";
import {toast, ToastContainer} from "react-toastify";
import ChangePasswordViewModel from "../../services/api/web/request/ChangePasswordViewModel";
import {showErrorToast, showSuccessToast} from "../../services/utils/toastUtils";
import TwoFactorValidationRequestViewModel from "../../services/api/web/request/TwoFactorValidationRequestModel";

const Settings = () => {

    const [user, setUser] = useState<JwtUser | null>(null);


    const userService = UserService.getInstance();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const [currentPasswordValidation, setCurrentPasswordValidation] = useState('');
    const [newPasswordValidation, setNewPasswordValidation] = useState('');
    const [newPasswordConfirmValidation, setNewPasswordConfirmValidation] = useState('');

    const [isChangePasswordFormValid, setChangePasswordFormValid] = useState(false);

    const [initialIsUserSignedUpToNewsletter, setInitialIsUserSignedUpToNewsletter] = useState(false);
    const [isUserSignedUpToNewsletter, setIsUserSignedUpToNewsletter] = useState(false);

    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);

    const [currentTwoFactorStep, setCurrentTwoFactorStep] = useState(1);
    const [twoFactorQrLink, setTwoFactorQrLink] = useState('');
    const [twoFactorSecret, setTwoFactorSecret] = useState('');
    const [twoFactorInputCode, setTwoFactorInputCode] = useState('');

    useEffect(() => {
        const user = userService.getUser();
        if (user) {
            setUser(user);
            setTwoFactorEnabled(user?.twoFactor);
            setIsUserSignedUpToNewsletter(user?.tierZeroInfo?.receiveNewsletterEmails);
            setInitialIsUserSignedUpToNewsletter(user?.tierZeroInfo?.receiveNewsletterEmails);
        }


    }, [userService, user]);

    useEffect(() => {
        if (currentTwoFactorStep === 2 && twoFactorSecret === '' && twoFactorQrLink === '') {
            userService.generateTwoFactorSecretAndQrLink().then(response => {
                if (response && response.status === 200) {
                    if ((response?.data as any)?.Secret) {
                        setTwoFactorSecret((response?.data as any).Secret);
                    }
                    if ((response?.data as any)?.QrLink) {
                        setTwoFactorQrLink((response?.data as any).QrLink);
                    }
                } else {
                    showErrorToast(toast, 'Could not generate two factor secret. Please try again later.');
                }
            });
        }
    }, [currentTwoFactorStep]);

    useEffect(() => {

        const isNewPasswordValid = /^(?=.*\d)(?=.*[A-Z]).{8,}$/.test(newPassword);

        const isChangePasswordFormValid = currentPassword.trim() !== ''
            && newPassword.trim() !== ''
            && newPassword === newPasswordConfirm;


        setCurrentPasswordValidation(currentPassword.trim() === '' ? 'Current password cannot not be empty' : '');

        setNewPasswordValidation(
            newPassword.trim() === '' ? ('New password cannot not be empty')
                : (!isNewPasswordValid ? 'Password must have at least 8 characters, one uppercase letter, and one digit' : ''));

        setNewPasswordConfirmValidation(
            newPasswordConfirm.trim() === '' ? ('Password Confirm should not be empty')
                : (newPassword !== newPasswordConfirm ? 'Passwords do not match' : ''));

        setChangePasswordFormValid(isChangePasswordFormValid);
    }, [currentPassword, newPassword, newPasswordConfirm, isChangePasswordFormValid]);

    const handleCurrentPasswordChange = (event: any) => {
        setCurrentPassword(event.target.value);
    };

    const handleNewPasswordChange = (event: any) => {
        setNewPassword(event.target.value);
    };

    const handleNewPasswordConfirmChange = (event: any) => {
        setNewPasswordConfirm(event.target.value);
    };

    const handleTwoFactorInputCodeChange = (event: any) => {
        setTwoFactorInputCode(event.target.value);
    };

    async function handleChangePasswordClick() {
        if (!isChangePasswordFormValid) {
            return;
        }

        const changePasswordViewModel: ChangePasswordViewModel = {
            oldPassword: currentPassword,
            password: newPassword
        };

        const response = await userService.changePassword(changePasswordViewModel);
        if (response && response.status === 200) {
            await userService.fetchUser();
            setUser(userService.getUser());
            showSuccessToast(toast, 'Password has been changed. You can login with the new password');
            setCurrentPassword('');
            setNewPassword('');
            setNewPasswordConfirm('');
            setChangePasswordFormValid(false);
        } else {
            let serverMessage = '';
            if ((response?.data as any)?.message) {
                serverMessage = (response?.data as any).message;
            }

            let messageToDisplay = 'An error occurred while submitting your data. Please try again later. If the problem persists, contact our staff.'
            if (serverMessage === 'Wrong password') {
                messageToDisplay = 'Current password is wrong';
            }
            showErrorToast(toast, messageToDisplay);
        }
    }

    async function setNewsletterSubscription() {
        const response = await userService.changeNewsletterFlag(isUserSignedUpToNewsletter);
        if (response && response.status === 200) {
            await userService.fetchUser();
            setUser(userService.getUser());
            showSuccessToast(toast, 'Saved');
            setInitialIsUserSignedUpToNewsletter(isUserSignedUpToNewsletter);
        } else {
            showErrorToast(toast, 'An error occurred while submitting your data. Please try again later. If the problem persists, contact our staff.');
        }
    }

    async function handleChangeTwoFactor(enable: boolean) {
        if (twoFactorInputCode.trim() === '') {
            return;
        }

        const twoFactorValidationRequestViewModel: TwoFactorValidationRequestViewModel = {
            twoFactorVerificationCode: twoFactorInputCode
        };

        let response;
        if (enable) {
            response = await userService.enableTwoFactor(twoFactorValidationRequestViewModel);
        } else {
            response = await userService.disableTwoFactor(twoFactorValidationRequestViewModel);
        }

        if (response && response.status === 200) {
            await userService.fetchUser();
            setUser(userService.getUser());
            const action = enable ? 'enabled' : 'disabled';
            setTwoFactorInputCode('');
            setTwoFactorQrLink('');
            setTwoFactorSecret('');
            setCurrentTwoFactorStep(1);
            showSuccessToast(toast, `Two-Factor Authentication has been ${action}`);
        } else {
            let serverMessage = '';
            if ((response?.data as any)?.message) {
                serverMessage = (response?.data as any).message;
            }

            let messageToDisplay = 'An error occurred while submitting your data. Please try again later. If the problem persists, contact our staff.'
            if (serverMessage === 'INVALID_TWO_FACTOR_VERIFICATION_CODE') {
                messageToDisplay = 'Wrong code';
            }
            showErrorToast(toast, messageToDisplay);
        }
    }

    return (
        <div>
            <div className="center-container">
                <div className="menu">
                    <Link to="/dashboard" className="link-custom"><p>DASHBOARD</p></Link>
                    <Link to="/orders" className="link-custom"><p>ORDERS</p></Link>
                    <Link to="/trade" className="link-custom"><p>TRADE</p></Link>
                    <Link to="/deposit" className="link-custom"><p>ADD FUNDS</p></Link>
                    <Link to="/withdraw" className="link-custom"><p>WITHDRAW FUNDS</p></Link>
                </div>
            </div>

            <h1 className="title">Settings</h1>

            <div className="dashboard-container">

                <div className="settings-section">
                    <p className="settings-section-title-text">Change Password</p>

                    <input type="password" placeholder="Current password" value={currentPassword}
                           onChange={handleCurrentPasswordChange}/>
                    {currentPasswordValidation && currentPassword &&
                        <div className="validation-message">{currentPasswordValidation}</div>}

                    <input type="password" placeholder="New Password" value={newPassword}
                           onChange={handleNewPasswordChange}/>
                    {newPasswordValidation && newPassword &&
                        <div className="validation-message">{newPasswordValidation}</div>}

                    <input type="password" placeholder="Confirm New Password" value={newPasswordConfirm}
                           onChange={handleNewPasswordConfirmChange}/>
                    {newPasswordValidation && newPasswordConfirm &&
                        <div className="validation-message">{newPasswordConfirmValidation}</div>}

                    <button className="settings-change-password-button"
                            disabled={!isChangePasswordFormValid}
                            style={{backgroundColor: !isChangePasswordFormValid ? '#BBB' : '#111'}}
                            onClick={handleChangePasswordClick}>CHANGE PASSWORD
                    </button>
                </div>

                <div className="rectangle"
                     style={{backgroundColor: "#DDD", marginBottom: '30px', marginTop: '20px'}}></div>

                <div className="settings-section">
                    <p className="settings-section-title-text">Newsletter Signup</p>
                    <p className="settings-section-text">Helpful information that you may find interesting (e.g., blog
                        posts, analysis and interesting facts about the Bitcoin industry, special offers, etc.)</p>
                    <div className="settings-newsletter-buttons-container">
                        <button
                            className={isUserSignedUpToNewsletter ? 'section-newsletter-button-selected' : 'section-newsletter-button-unselected'}
                            onClick={() => {
                                setIsUserSignedUpToNewsletter(!isUserSignedUpToNewsletter);
                            }}>ON
                        </button>
                        <button
                            className={!isUserSignedUpToNewsletter ? 'section-newsletter-button-selected' : 'section-newsletter-button-unselected'}
                            onClick={() => {
                                setIsUserSignedUpToNewsletter(!isUserSignedUpToNewsletter);
                            }}>OFF
                        </button>
                    </div>
                    <div className="settings-change-password-button"
                         style={{
                             width: '24px',
                             marginTop: '10px',
                             backgroundColor: isUserSignedUpToNewsletter === initialIsUserSignedUpToNewsletter ? '#BBB' : '#111'
                         }}
                         onClick={setNewsletterSubscription}
                    >SAVE
                    </div>
                </div>

                <div className="rectangle"
                     style={{backgroundColor: "#DDD", marginBottom: '30px', marginTop: '40px'}}></div>

                <div className="settings-section" style={{marginBottom: '50px'}}>
                    <p className="settings-section-title-text">Two-Factor Authentication</p>

                    {!twoFactorEnabled && (<div>
                        <p className="settings-section-text">Two factor authentication is an additional security step
                            added
                            to you account. After you activate Two Factor Authentication, you will be required to
                            provide an
                            additional Code when logging into your vTrader Account and when doing some requests on the
                            platform.</p>

                        <p className="settings-section-text"
                           style={{color: "#111", fontWeight: '700'}}>Step {currentTwoFactorStep}/3</p>
                        {currentTwoFactorStep === 1 && (<div>
                            <button className="settings-change-password-button" onClick={() => {
                                const googleAuthenticatorUrl = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US';
                                window.open(googleAuthenticatorUrl, '_blank');
                            }}>DOWNLOAD GOOGLE AUTHENTICATOR
                            </button>
                        </div>)}

                        {currentTwoFactorStep === 2 && (<div>
                            <p style={{color: "#111"}} className="settings-section-text">Scan this QR Code with Google
                                Authenticator:</p>
                            {twoFactorQrLink && (<div className="settings-section">
                                <img style={{width: '200px', alignSelf: 'center'}} src={twoFactorQrLink}/>
                            </div>)}
                            <p style={{color: "#111"}} className="settings-section-text">Your two factor secret is shown
                                below. Store it somewhere safe in case you need to re-enter it in Google Authenticator
                                App.
                                Make sure only you have access to it.</p>
                            {twoFactorSecret && (<div>
                                <p className="settings-section-text"
                                   style={{color: "#111", fontSize: '20px'}}> {twoFactorSecret}</p>
                            </div>)}
                        </div>)}

                        {currentTwoFactorStep === 3 && (<div>
                            <p className="settings-section-text" style={{color: "#111"}}> Enter the code shown by Google
                                Authenticator:</p>
                            <div className="settings-section">
                                <input type="numeric" placeholder="Two Factor Code*" value={twoFactorInputCode}
                                       onChange={handleTwoFactorInputCodeChange}
                                       style={{width: '300px', alignSelf: 'center'}}/>
                            </div>
                        </div>)}

                        <div className="setting-section-two-factor-buttons-container">
                            <button disabled={currentTwoFactorStep === 1}
                                    className={currentTwoFactorStep === 1 ? 'setting-section-two-factor-button-disabled' : 'setting-section-two-factor-button-enabled'}
                                    style={{cursor: currentTwoFactorStep === 1 ? 'none' : 'pointer'}}
                                    onClick={() => {
                                        setCurrentTwoFactorStep(currentTwoFactorStep - 1)
                                    }}>BACK
                            </button>
                            <button disabled={currentTwoFactorStep === 3 && !twoFactorInputCode}
                                    className={currentTwoFactorStep === 3 ? 'setting-section-two-factor-button-submit' : 'setting-section-two-factor-button-enabled'}
                                    style={{
                                        cursor: currentTwoFactorStep === 3 && !twoFactorInputCode ? 'none' : 'pointer',
                                        backgroundColor:
                                            currentTwoFactorStep === 3
                                                ? !twoFactorInputCode
                                                    ? '#BBB'
                                                    : 'black'
                                                : 'inherit'
                                    }}

                                    onClick={() => {
                                        if (currentTwoFactorStep < 3) {
                                            setCurrentTwoFactorStep(currentTwoFactorStep + 1)
                                        } else {
                                            handleChangeTwoFactor(true).then(() => {});
                                        }
                                    }}>{currentTwoFactorStep === 3 ? 'SUBMIT' : 'NEXT'}</button>
                        </div>
                    </div>)}

                    {twoFactorEnabled && (<div>

                        <p className="settings-section-text" style={{color: "#111"}} >Two-Factor Authentication is enabled. You can disable it by following the instructions below.</p>
                        <p className="settings-section-text" style={{color: "#111"}}> Enter the code shown by Google
                            Authenticator:</p>
                        <div className="settings-section">
                            <input type="numeric" placeholder="Two Factor Code*" value={twoFactorInputCode}
                                   onChange={handleTwoFactorInputCodeChange}
                                   style={{width: '300px', alignSelf: 'center'}}/>
                            <button className="settings-change-password-button"
                                    style={{width: '320px', backgroundColor: !twoFactorInputCode ? '#BBB' : '#111'}}
                                    onClick={() => handleChangeTwoFactor(false).then(() => {})}>DISABLE TWO-FACTOR AUTHENTICATION</button>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    );
};

export default Settings;
