import React, {useEffect, useState} from 'react';
import './ConfirmEmail.css';
import { Link, useParams } from 'react-router-dom';
import HttpClient from "../../services/api/HttpClient";
import {api} from "../../services/api/api";

interface ConfirmEmailRouteParams {
    confirmationToken: string;
}

const ConfirmEmail = () => {

    const httpClient = new HttpClient();
    const { confirmationToken } = useParams<ConfirmEmailRouteParams>();
    const [messageToDisplay, setMessageToDisplay] = useState('');
    const genericMessage = "ERROR";

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const queryParams = { token: confirmationToken };

                const response = await httpClient.get<any>(api.web.CONFIRM_EMAIL, queryParams);

                setMessageToDisplay(response.data.message);
            } catch (error) {
                console.log(error);
                setMessageToDisplay(genericMessage);
            }
        }

        confirmEmail().then(() => {});
    }, [confirmationToken]); // Use confirmationToken as a dependency to re-run when it changes


    if (messageToDisplay === '') {
        return (
            <div className="loading-container">
                <div style={{textAlign: 'center'}}>Loading...</div>
                <div className="loading-spinner"></div>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="message">
                {messageToDisplay === 'USER_CONFIRMED' && <p>Your account has been successfully activated.</p> }
                {messageToDisplay === 'USER_CONFIRMED' && <p>You can now proceed to <Link to="/login" style={{ fontWeight: 'bolder', textDecoration: 'none', color: 'black' }}>login</Link>.</p> }

                {messageToDisplay === 'USER_ALREADY_CONFIRMED' && <p>Your account has been already activated.</p>}
                {messageToDisplay === 'USER_ALREADY_CONFIRMED' && <p>You can go to <Link to="/login" style={{ fontWeight: 'bolder', textDecoration: 'none', color: 'black' }}>login</Link>.</p> }

                {messageToDisplay === 'INVALID_CONFIRMATION_LINK' && <p>Confirmation link is either invalid or expired.</p>}
                {messageToDisplay === 'INVALID_CONFIRMATION_LINK' && <p>Proceed to <Link to="/register" style={{ fontWeight: 'bolder', textDecoration: 'none', color: 'black' }}>register</Link>.</p> }

                {messageToDisplay === genericMessage && <p>Please try again. An error occurred. If the issue persists please contact us at office@vtrader.io</p>}
                {messageToDisplay === genericMessage && <p>Proceed to <Link to="/register" style={{ fontWeight: 'bolder', textDecoration: 'none', color: 'black' }}>register</Link>.</p> }
            </div>
        </div>
    );
};

export default ConfirmEmail;
