export const getLongNameForCrypto = (coin: string): string => {
    switch (coin.toUpperCase()) {
        case 'AAVE':
            return 'Aave';
        case 'APE':
            return 'Ape';
        case 'ADA':
            return 'Cardano';
        case 'AVAX':
            return 'Avalanche';
        case 'ALGO':
            return 'Algorand';
        case 'ATOM':
            return 'Cosmos';
        case 'BTC':
            return 'Bitcoin';
        case 'DAI':
            return 'Dai';
        case 'DOT':
            return 'Polkadot';
        case 'COMP':
            return 'Compound';
        case 'DOGE':
            return 'Dogecoin';
        case 'ETH':
            return 'Ethereum';
        case 'GRT':
            return 'The Graph';
        case 'LINK':
            return 'Chainlink';
        case 'LTC':
            return 'Litecoin';
        case 'MATIC':
            return 'Polygon';
        case 'MKR':
            return 'Maker';
        case 'SOL':
            return 'Solana';
        case 'UMA':
            return 'UMA';
        case 'UNI':
            return 'Uniswap';
        case 'USDC':
            return 'USD Coin';
        case 'USDT':
            return 'Tether';
        case 'XLM':
            return 'Stellar';
        case 'XRP':
            return 'Ripple';
        default:
            return 'Unknown';
    }
};