import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ReactPDF, { Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import '../dashboard/Dashboard.css'

import sortIcon from './sort.png';
import leftIcon from './left.png'
import rightIcon from './right.png'
import UserService from "../../services/user/UserService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {formatNumber} from "../../services/utils/formatNumber";
import {getCurrencySymbol} from "../../services/utils/cryptoSymbols";
import {formatDateForTable} from "../../services/utils/dateUtils";
import pdf = ReactPDF.pdf;
import {showErrorToast, showInfoToast, showSuccessToast} from "../../services/utils/toastUtils";
import {OrderViewModel} from "../../services/api/web/response/OrderViewModel";
import CancelOrderModal from "./CancelOrderModal";
import {CancelOrderRequestViewModel} from "../../services/api/web/request/CancelOrderRequestViewModel";
import eventBus from "../../EventBus";
const Orders = () => {

    const [historySelected, setHistorySelected] = useState('OPEN');

    const userService = UserService.getInstance();

    const [searchBoxText, setSearchBoxText] = useState('');

    const [orders, setOrders] = useState<Array<OrderViewModel>>([]);
    const [displayedOrders, setDisplayedOrders] = useState<Array<OrderViewModel>>([]);

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);

    const [sortDirectionPerColumn, setSortDirectionPerColumn] = useState<Array<number>>(Array(11).fill(1));

    const [isCancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);
    const [orderToCancelId, setOrderToCancelId] = useState(0);

    const [viewOnlyNonZeroOrders, setViewOnlyNonZeroOrders] = useState(false);

    useEffect(() => {

        const handleRefresh = () => {
            refreshOrders();
        };

        eventBus.on('refreshOrders', handleRefresh);

        // Unsubscribe when the component unmounts
        return () => {
            eventBus.off('refreshOrders', handleRefresh);
        };
    }, []);

    useEffect(() => {
        refreshOrders();
    }, [historySelected]); // Empty dependency array means the effect runs only once

    const refreshOrders = () => {

        let txOrders = userService.getOrders();
        txOrders = txOrders.filter(order => order.state === historySelected);

        if (viewOnlyNonZeroOrders) {
            txOrders = txOrders.filter(order => order.filledQuantity !== 0);
        }

        setOrders(txOrders);
        setDisplayedOrders(txOrders);
    }

    const copyToClipboard = (text: string) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    };

    const pageDisplayedOrders = () => {
        const startIndex = (currentPageNumber - 1) * currentPageSize;

        const slicedOrders = orders.slice(startIndex, startIndex + currentPageSize);

        setDisplayedOrders(slicedOrders);
    }

    useEffect(() => {
        refreshOrders();
    }, [viewOnlyNonZeroOrders]);

    useEffect(() => {
        pageDisplayedOrders();
    }, [currentPageNumber, currentPageSize, orders]);

    const handleSearchBoxTextChange = (event: any) => {
        setSearchBoxText(event.target.value);
    }

    const handlePageSizeChange = (event: any) => {
        setCurrentPageNumber(1);
        setCurrentPageSize(parseInt(event.target.value, 10));
    }

    const cancelOrder = async () => {
        let cancelOrderRequestViewModel: CancelOrderRequestViewModel = {
            orderId: orderToCancelId
        };

        const response = await userService.cancelOrder(cancelOrderRequestViewModel);

        if (response && response.status === 200) {
            // @ts-ignore
            userService.setUser(response?.data?.user);

            // @ts-ignore
            userService.modifyOrderIfExists(response?.data?.order);

            showSuccessToast(toast, 'Your order cancellation has been successfully submitted.');
        } else {
            // @ts-ignore
            if (response?.data?.message === 'ORDER_ALREADY_CANCELED') {
                await userService.fetchOrders();
                refreshOrders();
                showInfoToast(toast, 'Order #' + orderToCancelId + ' was completed / closed.');
            } else {
                const defaultMessage = 'An error occurred while submitting your order cancellation. Please try again later. If the problem persists, contact our staff.'
                showErrorToast(toast, defaultMessage);
            }
        }
    }

    useEffect(() => {
        if (searchBoxText.length < 3) {
            setDisplayedOrders([...orders]);
            pageDisplayedOrders();
            return;
        }

        // Filter withdrwals based on searchBoxText
        const filteredOrders = orders.filter((order) => {
            const formattedCreated = formatDateForTable(order.datePlaced).toLowerCase();

            // Check if any property contains the searchBoxText (case-insensitive)
            return Object.values(order).some((value) => {
                if (typeof value === 'number' && value.toString().toLowerCase().includes(searchBoxText.toLowerCase())) {
                    return true;
                } else if (typeof value === 'string' && value.toLowerCase().includes(searchBoxText.toLowerCase())) {
                    return true;
                } else if (value === order.datePlaced && formattedCreated.includes(searchBoxText.toLowerCase())) {
                    return true;
                }
                return false;
            });
        });

        setDisplayedOrders(filteredOrders);
    }, [searchBoxText]);

    const handleViewOnlyNonZeroOrdersChange = (event: any) => {
        setViewOnlyNonZeroOrders(event.target.checked);
    }

    const handleSortTable = (column: number) => {
        const sortedOrders = [...orders];

        sortDirectionPerColumn[column] = sortDirectionPerColumn[column] === 1 ? -1 : 1;

        switch (column) {
            case 0:
                sortedOrders.sort((a, b) => (a.id - b.id) * sortDirectionPerColumn[column]);
                break;
            case 1:
                sortedOrders.sort((a, b) => a.type.localeCompare(b.type) * sortDirectionPerColumn[column]);
                break;
            case 2:
                sortedOrders.sort((a, b) => a.market.localeCompare(b.market) * sortDirectionPerColumn[column]);
                break;
            case 3:
                sortedOrders.sort((a, b) => a.asset.localeCompare(b.asset) * sortDirectionPerColumn[column]);
                break;
            case 4:
                sortedOrders.sort((a, b) => (a.rate - b.rate) * sortDirectionPerColumn[column]);
                break;
            case 5:
                sortedOrders.sort((a, b) => (a.initialQuantity - b.initialQuantity) * sortDirectionPerColumn[column]);
                break;
            case 6:
                sortedOrders.sort((a, b) => (a.filledQuantity - b.filledQuantity) * sortDirectionPerColumn[column]);
                break;
            case 7:
                sortedOrders.sort((a, b) => (a.unitsFilled - b.unitsFilled) * sortDirectionPerColumn[column]);
                break;
            case 8:
                sortedOrders.sort((a, b) => ((a.fee || 0) - (b.fee || 0)) * sortDirectionPerColumn[column]);
                break;
            case 9:
                sortedOrders.sort((a, b) => (a.datePlaced - b.datePlaced) * sortDirectionPerColumn[column]);
                break;
            case 10:
                sortedOrders.sort((a, b) => ((a.dateClosed || 0) - (b.dateClosed || 0)) * sortDirectionPerColumn[column]);
                break;
            default:
                // Handle the case when an invalid column is provided
                break;
        }

        setOrders(sortedOrders);
    };

    const generateCsvFromOrders = () => {
        const csvRows = [];

        // Add header row
        const headerRow = [
            'Order', 'Type', 'Market', 'Asset', 'Price', 'Volume', 'Filled', 'Costs/Proceeds', 'Fee', 'Opened', 'Closed'
        ];
        csvRows.push(headerRow.join(','));

        // Add data rows
        orders.forEach((row) => {
            const dataRow = [
                row.id,
                row.type,
                row.market,
                row.asset,
                formatNumber(row.rate, 6).replace(/,/g, '.'),
                formatNumber(row.initialQuantity, 6).replace(/,/g, '.'),
                formatNumber(row.filledQuantity, 6).replace(/,/g, '.'),
                formatNumber(row.unitsFilled, 6).replace(/,/g, '.'),
                formatNumber(row.fee, 6).replace(/,/g, '.'),
                formatDateForTable(row.datePlaced).replace(/,/g, '.'),
                row.dateClosed ? formatDateForTable(row.dateClosed).replace(/,/g, '.') : '-',
            ];
            csvRows.push(dataRow.join(','));
        });


        return csvRows.join('\n');
    }

    const downloadOrdersCsv = () => {
        const csvData = generateCsvFromOrders();

        // Create a CSV blob
        const blob = new Blob([csvData], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(blob);

        // Create a download link and trigger the click event to download the CSV file
        const a = document.createElement('a');
        a.href = csvUrl;
        a.download = `vtrader_orders_history_report_${new Date().toISOString().replace(/[^a-zA-Z0-9]/g, '_').slice(0, -5)}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(csvUrl);
    }

    const downloadOrdersPdf = () => {
        const data = generateCsvFromOrders().split('\n').map((row) => row.split(','));

        const styles = StyleSheet.create({
            cell: {
                fontSize: 10,
                margin: 2,
                padding: 2,
            },
        });

        const headerRow = [
            'Order', 'Type', 'Market', 'Asset', 'Price', 'Volume', 'Filled', 'Costs/Proceeds', 'Fee', 'Opened', 'Closed'
        ];

        const pdfData = (
            <Document>
                <Page size="A4">
                    <View>
                        {data.slice(1).map((row, rowIndex) => (
                            <View key={rowIndex} style={{ flexDirection: 'column', marginBottom: 10 }}>
                                {row.map((cell, cellIndex) => (
                                    <Text key={cellIndex} style={styles.cell}>
                                        {headerRow[cellIndex]} : {cell}
                                    </Text>
                                ))}
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        );

        pdf(pdfData)
            .toBlob()
            .then((blob) => {
                const filename = `vtrader_orders_history_report_${new Date().toISOString().replace(/[^a-zA-Z0-9]/g, '_').slice(0, -5)}.pdf`;
                saveAs(blob, filename);
            });
    }

    const formatType = (type: string) => {
        if (type === 'LIMIT_BUY') {
            return 'BUY';
        }

        return 'SELL';
    }

    return (
        <div>
            <div className="center-container">
                <div className="menu">
                    <Link to="/dashboard" className="link-custom"><p>DASHBOARD</p></Link>
                    <Link to="/orders" className="link-custom"><p className="selected">ORDERS</p></Link>
                    <Link to="/trade" className="link-custom"><p>TRADE</p></Link>
                    <Link to="/deposit" className="link-custom"><p>ADD FUNDS</p></Link>
                    <Link to="/withdraw" className="link-custom"><p>WITHDRAW FUNDS</p></Link>
                </div>
            </div>

            <h1 className="title">Orders</h1>
                <div className="dashboard-container">
                    <div className="orders-history-table-header-container">
                        <div className="order-history-titles">
                            <div style={{cursor: 'pointer', textDecoration: historySelected === 'OPEN' ? 'underline' : 'inherit'}}
                                 className={historySelected === 'OPEN' ? 'deposit-active-tab' : 'deposit-inactive-tab'}
                                 onClick={() => {
                                     setHistorySelected('OPEN');
                                 }}><p>Open Orders</p></div>
                            <div style={{cursor: 'pointer', textDecoration: historySelected === 'CLOSED' ? 'underline' : 'inherit'}}
                                 className={historySelected === 'CLOSED' ? 'deposit-active-tab' : 'deposit-inactive-tab'}
                                 onClick={() => {
                                     setHistorySelected('CLOSED');
                                 }}><p>Completed Orders</p></div>
                        </div>

                        {isCancelOrderModalOpen && <CancelOrderModal
                            closeModal={() => setCancelOrderModalOpen(false)}
                            dismiss={() => {}}
                            confirmCancelOrder={cancelOrder}
                            orderId={orderToCancelId}/>
                        }

                        <div className="history-table-header-container"
                             style={{marginTop: '20px',
                                     justifyContent: displayedOrders.length > 0 ? 'space-between': 'center'}}>
                            {(displayedOrders.length > 0 || searchBoxText.trim().length > 0) && (
                                <input type="text" placeholder="SEARCH" style={{backgroundColor: 'white', width: '250px', marginRight: '50px'}}
                                   value={searchBoxText} onChange={handleSearchBoxTextChange}/>)}
                            {displayedOrders.length > 0 && (<div className="history-table-header-export-buttons-container">
                                <p className="history-table-header-text"
                                   onClick={() => {
                                       downloadOrdersPdf();
                                   }}>EXPORT PDF</p>
                                <p className="history-table-header-text"
                                   onClick={() => {
                                       downloadOrdersCsv();
                                   }}>EXPORT CSV</p>
                                <p className="history-table-header-text"
                                   onClick={() => {
                                       copyToClipboard(generateCsvFromOrders());
                                       showSuccessToast(toast, 'Order history copied to clipboard');
                                   }}>COPY TO CLIPBOARD</p>
                            </div>)}
                        </div>
                    </div>
                </div>

                {displayedOrders.length > 0 && (<div>
                    <div className="history-table-container" style={{maxWidth: '1200px'}}>
                        {historySelected === 'CLOSED' && (<>
                            <div className="checkbox-custom" style={{marginBottom: '5px'}}>
                                <input type="checkbox" id="terms" checked={viewOnlyNonZeroOrders} onChange={handleViewOnlyNonZeroOrdersChange}/>
                                <label htmlFor="terms" style={{fontSize: '12px'}}>View only non-zero orders</label>
                            </div></>)}

                        <table style={{borderCollapse: 'collapse', width: '1200px'}}>
                            <thead>
                            <tr className="history-table-row">
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Order</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(0); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Type</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(1); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Market</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(2); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Asset</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(3); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Price</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(4); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Volume</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(5); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Filled</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(6); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Cost/Proceeds</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(7); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Fee</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(8); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                <th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Opened</p>
                                        <div className="history-table-sort-icon-container">
                                            <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(9); }}/>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>
                                {historySelected === 'CLOSED' && (<th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Closed</p>
                                        <div className="history-table-sort-icon-container">
                                            <div className="history-pagination-icon-container">
                                                <img src={sortIcon} alt="ic" className="history-table-sort-icon" onClick={() => { handleSortTable(10); }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>)}
                                {historySelected === 'OPEN' && (<th className="history-table-thead-text">
                                    <div className="history-table-text-and-icon-header">
                                        <p>Action</p>
                                    </div>
                                    <div className="history-table-thead-hidden-section"></div>
                                </th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {displayedOrders.map((row, index) => (
                                <tr key={index} className="history-table-row">
                                    <td className="history-table-cell" style={{width: '8%'}} >{'#' + row.id}</td>
                                    <td className="history-table-cell" style={{width: '8%'}} >{formatType(row.type)}</td>
                                    <td className="history-table-cell" style={{width: '8%'}} >{row.market}</td>
                                    <td className="history-table-cell" style={{width: '8%'}} >{row.asset}</td>
                                    <td className="history-table-cell" style={{width: '10%'}} >{getCurrencySymbol(row.market) + formatNumber(row.rate, 6)}</td>
                                    <td className="history-table-cell" style={{width: '10%'}} >{formatNumber(row.initialQuantity, 6)}</td>
                                    <td className="history-table-cell" style={{width: '8%'}}  >{formatNumber(row.filledQuantity, 6)}</td>
                                    <td className="history-table-cell" style={{width: '8%'}} >{formatNumber(row.unitsFilled, 6)}</td>
                                    <td className="history-table-cell" style={{width: '8%'}} >{formatNumber(row.fee, 6, true) + '%'}</td>
                                    <td className="history-table-cell" style={{width: '12%'}}  >{formatDateForTable(row.datePlaced)}</td>
                                    {historySelected === 'CLOSED' && (
                                        <td className="history-table-cell" style={{width: '12%'}}>{row.dateClosed ? formatDateForTable(row.dateClosed) : '---'}</td>)}
                                    {historySelected === 'OPEN' && (
                                        <td className="history-table-cell"
                                            style={{width: '12%',
                                                    textDecoration: row.pendingCancel ? 'inherit' : 'underline',
                                                    cursor: row.pendingCancel ? 'default' : 'pointer'}}
                                            onClick={() => {
                                                if (!row.pendingCancel) {
                                                    setOrderToCancelId(row.id);
                                                    setCancelOrderModalOpen(true);
                                                }
                                            }}>
                                            {row.pendingCancel && (<div style={{display: 'flex', flexDirection: 'row'}}>
                                                <p style={{paddingRight: '7px'}}>Pending Cancel</p>
                                                <div style={{display: 'flex'}}>
                                                    <div className="order-pending-cancel-loading-spinner"></div>
                                                </div>

                                            </div>)}

                                            {!row.pendingCancel && (<>Cancel</>)}
                                        </td>)}
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        {searchBoxText.length < 3 && (<div>
                            <div className="history-pagination-container">
                                <p style={{ color: 'black', alignSelf: 'center', cursor: 'inherit' }} className="history-table-header-text">
                                    Showing {`${(currentPageNumber - 1) * currentPageSize + 1}-${Math.min(
                                    currentPageNumber * currentPageSize,
                                    orders.length
                                )}`} of {formatNumber(orders.length, 0, false)}
                                </p>

                                <div className="history-pagination-buttons-container">
                                    <button className="history-pagination-button"
                                            disabled={currentPageNumber === 1}
                                            onClick={() => {
                                                setCurrentPageNumber(currentPageNumber - 1);
                                            }}>
                                        <div className="history-pagination-button-icon-text-container">
                                            <div className="history-pagination-icon-container" style={{paddingRight: '7px'}}>
                                                <img src={leftIcon} className="history-pagination-button-icon" alt="icon"/>
                                            </div>
                                            <p>Previous</p>
                                        </div>
                                    </button>
                                    <button className="history-pagination-button"
                                            disabled={(currentPageNumber * currentPageSize) > orders.length}
                                            onClick={() => {
                                                setCurrentPageNumber(currentPageNumber + 1);
                                            }}>
                                        <div className="history-pagination-button-icon-text-container">
                                            <p>Next</p>
                                            <div className="history-pagination-icon-container" style={{paddingLeft: '5px'}}>
                                                <img src={rightIcon} className="history-pagination-button-icon" alt="icon"/>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <select value={currentPageSize} onChange={handlePageSizeChange}
                                        className="history-current-page-size-dropdown">
                                    {[10, 25, 50, 100].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            View {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>)}


                    </div>
                </div>)}

            {displayedOrders.length === 0 && searchBoxText.trim().length === 0 && (<div>
                <div className="orders-no-orders-to-display-container">
                    {historySelected === 'OPEN' && (<p>
                        You currently don't have any open orders.
                    </p>)}

                    {historySelected === 'CLOSED' && (<p>
                        Your order history is currently empty.
                    </p>)}
                </div>
            </div>)}
        </div>
    );
};

export default Orders; // Default export of the Orders component