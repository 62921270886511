import React from 'react';

import '../dashboard/Dashboard.css'
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";

const CookiePolicy = () => {

    return (
        <div>
            <h1 className="title">Cookie Policy</h1>
            <div className="dashboard-container" style={{marginBottom: '50px'}}>
                <div className="deposit-titles" style={{gap: '25px'}}>
                    <Link to="/terms-and-conditions" className="deposit-inactive-tab"><p>Terms & Conditions</p></Link>
                    <Link to="/privacy-policy" className="deposit-inactive-tab"><p>Privacy Policy</p></Link>
                    <Link to="/cookie-policy" className="deposit-active-tab"><p>Cookie Policy</p></Link>
                    <Link to="/aml-policy" className="deposit-inactive-tab"><p>AML Policy</p></Link>
                    <Link to="/refund-policy" className="deposit-inactive-tab"><p>Refund Policy</p></Link>
                </div>
                <p className="terms-title-section" style={{marginTop: '50px'}}>About cookies</p>
                <p className="terms-text">The site hosted on the "vtrader.io" domain still the vTrader site uses cookies.</p>
                <p className="terms-text">The information below is intended to inform the user about the placement, use and administration of cookies used by the vTrader Site. There are also some helpful links to this topic. If you need more information and cannot be found below, you can contact us at: support@vtrader.io</p>
                <p className="terms-title-section">Please read carefully the following information: </p>
                <p className="terms-text">This website uses cookies to provide visitors with a much better browsing experience and services tailored to the needs and interests of everyone. In what we call "web 2.0," cookies play an important role in facilitating the access and delivery of the many services the user enjoys on the Internet, such as:</p>
                <ul>
                    <li className="terms-text">Customizing certain settings</li>
                    <li className="terms-text">Cookies provide site owners with valuable feedback on how their sites are used by users so they can make them even more effective and accessible to users.</li>
                    <li className="terms-text">Allow multimedia or other applications from other sites to be included in a particular site to create a more valuable, useful, and enjoyable browsing experience;</li>
                    <li className="terms-text">Improve the efficiency of online advertising.</li>
                </ul>
                <p className="terms-title-section">What is a cookie?</p>
                <p className="terms-text">A "Cookie Internet" (also known as "cookie browser" or "HTTP cookie" or simply "cookie") is a small file of letters and numbers that will be stored on your computer, other equipment of a user on which the Internet is accessed. The cookie is installed through a web browser's browser request (e.g. Internet Explorer, Chrome) and is completely "passive" (it does not contain software, viruses or spyware and cannot access the information on the user's hard drive).</p>
                <p className="terms-text">A cookie consists of two parts: the name and the content or the value of the cookie. Furthermore, the duration of a cookie is determined; technically, only the web server that sent the cookie can access it again when a user returns to the website associated with that web server.</p>
                <p className="terms-text">Cookies themselves do not require personal information to be used and, in most cases, do not personally identify Internet users.</p>
                <ul>
                    <li className="terms-text">Session cookies - they are temporarily stored in the web browser's cookie folder to save them until the user exits the site or closes the browser window (for example, when logging in / logging off to an account webmail or social networking)</li>
                    <li className="terms-text">Persistent cookies - These are stored on the hard drive of a computer or device (and generally depends on the default cookie lifetime). Persistent cookies include those placed on a website other than the one the user is currently visiting - known as 'third party cookies' - which can be used anonymously to memorize the interests of a user, so that advertising is delivered as relevant as possible to users</li>
                </ul>
                <p className="terms-title-section">What are the benefits of cookies?</p>
                <p className="terms-text">A cookie contains information linking a web browser (user) and a specific web-server (website). If a browser accesses that web-server again, it can read the already stored information and react accordingly. Cookies provide users with a pleasant browsing experience and support the efforts of many websites to provide comfortable user services: e.g. - online privacy preferences, site language options, shopping carts, or relevant advertising.</p>
                <p className="terms-title-section">What is the lifetime of a cookie?</p>
                <p className="terms-text">Cookies are managed by web servers. The life of a cookie may vary significantly, depending on the purpose for which it is placed. Some cookies are used exclusively for one session (cookies session) and are no longer retained once the user has left the website and some cookies are retained and reused whenever the user returns to that site (permanent cookies). However, cookies can be deleted by a user at any time through browser settings.</p>
                <p className="terms-title-section">What are cookies placed by third parties?</p>
                <p className="terms-text">Certain sections of content on some sites may be provided through third parties / suppliers (eg news box, video or advertisement). These third parties may also place cookies through the site and they are called "third party cookies" because they are not placed by the owner of that website. Third-party vendors must also comply with the applicable law and the site's privacy policies.</p>
                <p className="terms-title-section">How cookies can be used by this site</p>
                <p className="terms-text">A visit to this site may place cookies for purposes of:</p>
                <ul>
                    <li className="terms-text">Site performance cookies</li>
                    <li className="terms-text">Recording cookies</li>
                    <li className="terms-text">Cookies for advertising</li>
                </ul>
                <p className="terms-text">These cookies may come from third parties.</p>
                <p className="terms-title-section">Performance cookies</p>
                <p className="terms-text">This type of cookie retains the user's preferences on this site, so they no longer need to be set at every site visit.</p>
                <p className="terms-title-section">Cookies for registration</p>
                <p className="terms-text">When you sign up for this site, we generate a cookie that tells us if you are registered or not. Our servers use these cookies to show us your account and if you have permission for a particular service. It also allows us to associate any comments you post on our site with your username. If you did not select "keep me registered," this cookie will automatically delete when you close your browser or computer.</p>
                <p className="terms-title-section">Cookies for advertising</p>
                <p className="terms-text">These cookies allow us to find out whether or not you have viewed an online ad, what is its type, and how long it has been since you saw the ad message. These cookies are also used to target online advertising. We may also use third-party cookies for better advertising targeting, for example, showing holiday ads if the user has recently visited an article on vacancies. These cookies are anonymous, they store information about the viewed content, not the users. We also set up anonymous cookies through other sites that we advertise. By accepting them, we can use them to recognize you as a visitor to that site if you later visit our site, we will be able to deliver your advertising based on this information.</p>
                <p className="terms-title-section">Other cookies of third parties</p>
                <p className="terms-text">On some pages, third parties can set their own anonymous cookies in order to track the success of an application, or to customize an application. Because of its use, this site cannot access these cookies, just as third parties cannot access cookies owned by this site. For example, when you share an article using the social network button on this site, that social network will record your activity.</p>
                <p className="terms-title-section">What type of information is stored and accessed through cookies?</p>
                <p className="terms-text">Cookies keep information in a small text file that allows a website to recognize a browser. The Web server will recognize the browser until the cookie expires or is deleted. The cookie stores important information that enhances Internet browsing experience (e.g., setting the language of a site, keeping a user logged in to your webmail account, online banking security, keeping your products in the shopping cart).</p>
                <p className="terms-title-section">Why are Internet Cookies Important?</p>
                <p className="terms-text">Cookies are the central focus of the efficient Internet, helping to generate a friendly browsing experience and tailored to the preferences and interests of each user. Denying or disabling cookies can make some sites unusable.</p>
                <p className="terms-title-section">Security and privacy issues</p>
                <p className="terms-text">Cookies are NOT Viruses! They use plain text formats. They are not made up of pieces of code so they cannot be executed or can auto-run. Consequently, they cannot duplicate or replicate on other networks to run or replicate again. Because they cannot perform these functions, they cannot be considered viruses.</p>
                <p className="terms-text">Cookies can still be used for negative purposes. Because it stores information about user preferences and browsing history, both on a particular site and on several other sites, cookies can be used as a form of Spyware. Many anti-spyware products are aware of this and consistently mark cookies to be deleted in anti-virus / anti-spyware removal / scanning procedures.</p>
                <p className="terms-text">In general, browsers have built-in privacy settings that provide different levels of cookie acceptance, shelf life, and automatic deletion after the user has visited a particular site.</p>
                <p className="terms-title-section">Other security issues related to cookies</p>
                <p className="terms-text">Since identity protection is very valuable and is the right of every internet user, it is advisable to know what issues can create cookies. In order to transmit constant information in both ways between the browser and the website, if an attacker or unauthorized person intervenes during the data transmission, the information contained in the cookie can be intercepted. Although very rarely, this can happen if the browser connects to the server using an unencrypted network (e.g. an unsecured Wi-Fi network).</p>
                <p className="terms-text">Other cookie-based attacks involve bad cookie settings on servers. If a website does not require the browser to use only encrypted channels, attackers can use this vulnerability to prevent browsers from sending information through insecure channels. Attackers then use the information for the purpose of unauthorized access to certain sites. It is very important to be careful in choosing the most appropriate method of protecting personal information. </p>
                <p className="terms-title-section">Tips for safe and responsible cookie-based navigation.</p>
                <p className="terms-text">Due to their flexibility and the fact that most of the most visited sites and the biggest ones use cookies, they are almost inevitable. Disabling cookies will not allow the user to access the most popular and used sites including YouTube, Gmail, Yahoo and others. Here are a few tips that can help you navigate safely with cookies:</p>
                <ul>
                    <li className="terms-text">Customize your browser settings for cookies to reflect a comfortable level for you on cookie security</li>
                    <li className="terms-text">If you do not mind cookies and you are the only person using your computer, you can set expiration dates to store your browsing history and personal access data. If you share access to your computer, you can consider the browser setting to delete individual browsing data each time you close your browser. This is a way to access sites that place cookies and delete any business information when you close the browsing session.</li>
                    <li className="terms-text">Install and constantly update anti-spyware applications.</li>
                </ul>
                <p className="terms-text">Many of the spyware detection and prevention applications include site attacks detection. This prevents the browser from accessing websites that could exploit browser vulnerabilities or download dangerous software. Make sure your browser is always up-to-date. Many of the cookies attacks are exploited by exploiting the weaknesses of the old versions of browsers.</p>
                <p className="terms-text">Cookies are everywhere and cannot be avoided if you want to enjoy access to the best and greatest websites on the Internet - local or international. With a clear understanding of how they work and the benefits they bring, you can take the necessary security measures so you can browse with confidence on the internet.</p>
                <p className="terms-title-section">How do I stop cookies?</p>
                <p className="terms-text">Disabling and refusing to receive cookies can make some sites unfeasible or difficult to visit and use. Also, refusing to accept cookies does not mean you will not get/see online advertising anymore. It is possible to set up the browser so that these cookies are no longer supported or you can set the browser to accept cookies from a particular site. But for example, if you are not registered using cookies, you will not be able to leave comments. All modern browsers offer the ability to change cookie settings. These settings are usually found in your browser's "options" or "preferences" menu. To understand these settings, the following links may be useful, otherwise you can use the browser's "help" option for more details.</p>
                <p className="terms-title-section">More information</p>
                <p className="terms-text">For more info, visit one of the following links:</p>
                <ul>
                    <li className="terms-text"><a href="http://www.youronlinechoices.eu/ro" style={{color: 'black'}} target="_blank">http://www.youronlinechoices.eu/ro</a></li>
                    <li className="terms-text"><a href="http://www.guardian.co.uk/info/cookies" style={{color: 'black'}}  target="_blank">http://www.guardian.co.uk/info/cookies</a></li>
                    <li className="terms-text"><a href="http://www.iabeurope.eu/cookies-faq/internet-cookies---increasing-and-enhancing-yourinternet-surfing-experience/what-are-cookies-how-do-they-work---cookies-faq.aspx" target="_blank"  style={{color: 'black'}} >
                        http://www.iabeurope.eu/cookies-faq/internet-cookies---increasing-and-enhancing-yourinternet-surfing-experience/what-are-cookies-how-do-they-work---cookies-faq.aspx
                    </a>
                    </li>
                </ul>

            </div>
        </div>
    );
};

export default CookiePolicy;
