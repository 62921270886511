class RegisterUserViewModel {

    public email: string = '';

    public firstName: string = '';

    public lastName: string = '';

    public password: string = '';

    public phoneNumber: string = '';

    public phonePrefix: string = '';

    public webPhoneValidationCode: string = '';

    public countryId: number = 0;

    public language: string = 'EN';

    public receiveAdministrativeEmails: boolean = false;

    public receiveNewsletterEmails: boolean = false;

    public termsAndConditions: number = 0;

    public privacyPolicy: number = 0;

    public captcha: string = '';

    public voucherCode: string | null = null;

    public referral: string | null = null;
}

export default RegisterUserViewModel;