import React from 'react';

import '../dashboard/Dashboard.css'
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";

const RefundPolicy = () => {

    return (
        <div>
            <h1 className="title">Refund Policy</h1>
            <div className="dashboard-container" style={{marginBottom: '50px'}}>
                <div className="deposit-titles" style={{gap: '25px'}}>
                    <Link to="/terms-and-conditions" className="deposit-inactive-tab"><p>Terms & Conditions</p></Link>
                    <Link to="/privacy-policy" className="deposit-inactive-tab"><p>Privacy Policy</p></Link>
                    <Link to="/cookie-policy" className="deposit-inactive-tab"><p>Cookie Policy</p></Link>
                    <Link to="/aml-policy" className="deposit-inactive-tab"><p>AML Policy</p></Link>
                    <Link to="/refund-policy" className="deposit-active-tab"><p>Refund Policy</p></Link>
                </div>
                <p className="terms-text" style={{marginTop: '50px'}}>Due to the immutability of transactions made on the blockchain network, vTrader does not offer the guarantee of a refund or refund of funds transferred to vTrader via the bank card. A conversion order can be canceled until the actual payment. Once the payment has been made, vTrader will review your refund requests and make the refund if the funds are still in the vTrader custody and the request has a reasonable basis.</p>
            </div>
        </div>
    );
};

export default RefundPolicy;
