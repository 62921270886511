import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import UserService from "../../services/user/UserService";
import {
    getNotificationDescription,
    isNotificationGood,
    NotificationViewModel
} from "../../services/api/web/response/NotificationModel";
import {formatDateForTable} from "../../services/utils/dateUtils";

import xIcon from './x-icon.png'
import gIcon from './g-icon.png'

const customStyles = {
    content: {
        height: '100%',
        left: 'auto',
        right: '0',
        bottom: '0',
        padding: '0',
        margin: '0',
        boxShadow: 'none',
        border: 'none',
    },
};


interface RightPanelModalProps {
    closeModal: () => void;
}

Modal.setAppElement('#root');

const AlertsModal: React.FC<RightPanelModalProps> = ({closeModal}) => {
    const [scrollDisabled, setScrollDisabled] = useState(false);

    const userService = UserService.getInstance();

    const [notifications, setNotifications] = useState<NotificationViewModel[]>([]);

    useEffect(() => {
        if (!scrollDisabled) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        const notificationsRes = userService.getNotifications();
        if (notificationsRes) {
            setNotifications(notificationsRes);
        }

        return () => {
            document.body.style.overflow = 'auto';
        };

    }, [scrollDisabled, notifications]);


    const getNotifIcon = (notif: NotificationViewModel): string => {
        if (isNotificationGood(notif)) {
            return gIcon;
        }

        return xIcon;
    }

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <div style={{display: 'flex', justifyContent: 'start', cursor: 'pointer'}} onClick={() => {
                        closeModal();
                        setScrollDisabled(true);
                    }}>
                        <p className="wallet-details-text" style={{marginRight: '4px'}}>&lt;</p>
                        <p className="wallet-details-text">Back</p>
                    </div>

                    <p>Alerts</p>

                    {notifications && (
                        <table>
                            <tbody>
                            {notifications.map((notif, index) => {
                                const notificationDescription = getNotificationDescription(notif);
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="alert-modal-outer-box">
                                                <img src={getNotifIcon(notif)} alt="ic" className="alert-modal-icon"/>
                                                <div className="alert-modal-inner-box">
                                                    <p className="alert-modal-title-text"
                                                       style={{marginBottom: '-8px'}}>
                                                        {notif.type.replace(/_/g, ' ')}
                                                    </p>
                                                    {notificationDescription && (
                                                        <p className="alert-modal-description-text"
                                                           style={{marginBottom: '-8px'}}>
                                                            {notificationDescription}
                                                        </p>
                                                    )}
                                                    <p className="alert-modal-date-text">{formatDateForTable(notif.created)}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    )}
                    <div style={{marginBottom: '50px'}}></div>
                </div>
            </Modal>
        </div>
    );
}

export default AlertsModal;
