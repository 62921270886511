import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';

import './LeftPanelModal.css'
import closeIcon from "./icons/close.png";
import {Link} from "react-router-dom";

import facebookIcon from './icons/facebook.png'
import linkedinIcon from './icons/linkedin.png'
import twitterIcon from './icons/twitter.png'
import instagramIcon from './icons/instagram.png'
import youtubeIcon from './icons/youtube.png'
import telegramIcon from './icons/telegram.png'
import mediumIcon from './icons/medium.png'

const customStyles = {
    content: {
        width: '350px',
        height: '93%',
        left: '0',
        right: 'auto',
        bottom: '0',
        padding: '0',
        margin: '0',
        boxShadow: 'none',
        border: 'none'
    },
};


interface LeftPanelModalProps {
    closeModal: () => void;
}

Modal.setAppElement('#root');

const LeftPanelModal: React.FC<LeftPanelModalProps> = ({closeModal}) => {
    const [scrollDisabled, setScrollDisabled] = useState(false);

    useEffect(() => {
        if (!scrollDisabled) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };

    }, [scrollDisabled]);

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                style={customStyles}
            >

                <div className="left-panel-modal-container">
                    <div className="left-panel-title">
                        <img
                            src={closeIcon}
                            alt="Close"
                            className="left-panel-exit-icon"
                            onClick={() => {
                                closeModal();
                                setScrollDisabled(true);
                            }}
                        />
                    </div>

                    <div className="left-panel-big-section">
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/dashboard" className="left-panel-big-section-link">
                                <p className="left-panel-big-section-text">Home</p>
                            </Link>
                        </div>
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/account" className="left-panel-big-section-link">
                                <p className="left-panel-big-section-text">Account</p>
                            </Link>
                        </div>
                        <div onClick={() => {
                            window.open('https://vtrader.io/news', '_blank');
                        }}>
                            <p className="left-panel-big-section-text" style={{cursor: 'pointer'}}>News</p>
                        </div>
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/exchanged-assets" className="left-panel-big-section-link">
                                <p className="left-panel-big-section-text">Exchanged Assets</p>
                            </Link>
                        </div>
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/fees" className="left-panel-big-section-link">
                                <p className="left-panel-big-section-text">Fees</p>
                            </Link>
                        </div>
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/about" className="left-panel-big-section-link">
                                <p className="left-panel-big-section-text">About</p>
                            </Link>
                        </div>
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/faq" className="left-panel-big-section-link">
                                <p className="left-panel-big-section-text">Faq</p>
                            </Link>
                        </div>
                    </div>

                    <div className="left-panel-icon-section">
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/terms-and-conditions" className="left-panel-big-section-link">
                                <p className="left-panel-small-section-text">Terms & Conditions</p>
                            </Link>
                        </div>
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/privacy-policy" className="left-panel-big-section-link">
                                <p className="left-panel-small-section-text">Privacy Policy</p>
                            </Link>
                        </div>
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/cookie-policy" className="left-panel-big-section-link">
                                <p className="left-panel-small-section-text">Cookie Policy</p>
                            </Link>
                        </div>
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/aml-policy" className="left-panel-big-section-link">
                                <p className="left-panel-small-section-text">AML & CTF Policy</p>
                            </Link>
                        </div>
                        <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <Link to="/refund-policy" className="left-panel-big-section-link">
                                <p className="left-panel-small-section-text">Refund Policy</p>
                            </Link>
                        </div>
                        <p className="left-panel-small-section-text">support@vtrader.io</p>
                        <p className="left-panel-small-section-text">©2023 vTrader. All Rights Reserved.</p>
                        <a href="https://www.facebook.com/vtrader.io/"><img src={facebookIcon} alt="c"
                                                                            className="left-panel-sm-icon"/></a>
                        <a href="https://www.linkedin.com/company/13012475/"><img src={linkedinIcon} alt="c"
                                                                                  className="left-panel-sm-icon"/></a>
                        <a href="https://twitter.com/vTrader_io"><img src={twitterIcon} alt="c"
                                                                      className="left-panel-sm-icon"/></a>
                        <a href="https://www.instagram.com/vtrader.io/"><img src={instagramIcon} alt="c"
                                                                             className="left-panel-sm-icon"/></a>
                        <a href="https://www.youtube.com/@vtrader9212"><img src={youtubeIcon} alt="c"
                                                                            className="left-panel-sm-icon"/></a>
                        <a href="https://t.me/vtrader_exchange"><img src={telegramIcon} alt="c"
                                                                     className="left-panel-sm-icon"/></a>
                        <a href="https://medium.com/@marketing_82136/"><img src={mediumIcon} alt="c"
                                                                            className="left-panel-sm-icon"/></a>
                    </div>
                </div>

            </Modal>
        </div>
    );
}

export default LeftPanelModal;
