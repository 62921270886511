import React, {useEffect, useState} from 'react';
import './Register.css';
import {Link, Redirect, useLocation} from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha"
import {RECAPTCHA_SITE_KEY} from "../../services/constants";
import {useAuth} from "../../services/auth/AuthContext";
import AllowedCountriesService from "../../services/allowed-countries/AllowedCountriesService";
import AllowedCountry from "../../services/api/common/response/AllowedCountry";
import HttpClient from "../../services/api/HttpClient";
import SendPhoneVerificationCodeViewModel from "../../services/api/web/request/SendPhoneVerificationCodeViewModel";
import {api} from "../../services/api/api";
import RegisterUserViewModel from "../../services/api/web/request/RegisterUserViewModel";

const Register = () => {
    const {isLoggedIn} = useAuth();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get('ref') || '';

    // form values
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('Country');
    const [phonePrefix, setPhonePrefix] = useState('+40');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneVerificationCode, setPhoneVerificationCode] = useState('');
    const [agreeWithTermsAndConditions, setAgreeWithTermsAndConditions] = useState(false);
    const [newsletterSubscribed, setNewsletterSubscribed] = useState(false);

    // validations
    const [emailValidation, setEmailValidation] = useState('');
    const [passwordValidation, setPasswordValidation] = useState('');
    const [passwordConfirmValidation, setPasswordConfirmValidation] = useState('');
    const [phoneNumberValidation, setPhoneNumberValidation] = useState('');

    const [registerButtonClass, setRegisterButtonClass] = useState('register-button');

    const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [displayError, setDisplayError] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const [allCountries, setAllCountries] = useState<AllowedCountry[]>([]);

    const [phoneNumberVerificationButtonPressed, setPhoneNumberVerificationButtonPressed] = useState(false);
    const [phoneNumberVerificationButtonTimer, setPhoneNumberVerificationButtonTimer] = useState(0);

    const httpClient = new HttpClient();

    useEffect(() => {
        const timerInterval = setInterval(() => {
            if (phoneNumberVerificationButtonTimer > 0) {
                setPhoneNumberVerificationButtonTimer(phoneNumberVerificationButtonTimer - 1);
            }
        }, 1000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [phoneNumberVerificationButtonTimer]);

    useEffect(() => {
        async function fetchAllowedCountries() {
            const countries = await AllowedCountriesService.getInstance().getAllCountries();
            setAllCountries(countries);
        }

        fetchAllowedCountries().then(() => {});
    }, []);

    useEffect(() => {

        const isPasswordValid = /^(?=.*\d)(?=.*[A-Z]).{8,}$/.test(password);
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        const isPhoneNumberValid = /^\d{4,20}$/.test(phoneNumber);

        const isValid = email.trim() !== ''
            && isEmailValid
            && password.trim() !== ''
            && passwordConfirm.trim() !== ''
            && password === passwordConfirm
            && isPasswordValid
            && firstname.trim() !== ''
            && lastname.trim() !== ''
            && phoneNumber.trim() !== ''
            && phoneVerificationCode.trim() !== ''
            && agreeWithTermsAndConditions
            && isPasswordValid
            && isRecaptchaChecked;
        setIsFormValid(isValid);
        setRegisterButtonClass(isValid ? 'register-button' : 'register-button disabled');

        setEmailValidation(!isEmailValid ? 'Email should have a valid format' : '');

        setPasswordValidation(
            password.trim() === '' ? 'Password should not be empty' :
                !isPasswordValid ? 'Password must have at least 8 characters, one uppercase letter, and one digit' :
                    '');
        setPasswordConfirmValidation(
            passwordConfirm.trim() === '' ? 'Password Confirm should not be empty' :
                password !== passwordConfirm ? 'Passwords do not match' : '');

        setPhoneNumberValidation(
            phoneNumber.trim() === '' ? 'Phone number should not be empty' :
                !isPhoneNumberValid ? 'Phone number should be valid' :
                    '');

    }, [firstname, lastname, email, password, passwordConfirm, phoneNumber, phoneVerificationCode, agreeWithTermsAndConditions, isRecaptchaChecked]);

    if (isLoggedIn) {
        return <Redirect to="/dashboard"/>;
    }

    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const handleFirstnameChange = (event: any) => {
        setDisplayError('');
        setFirstname(event.target.value);
    };

    const handleLastnameChange = (event: any) => {
        setDisplayError('');
        setLastname(event.target.value);
    };

    const handleEmailChange = (event: any) => {
        setDisplayError('');
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setDisplayError('');
        setPassword(event.target.value);
    };

    const handlePasswordConfirmChange = (event: any) => {
        setDisplayError('');
        setPasswordConfirm(event.target.value);
    };

    const handleCountrySelect = (event: any) => {
        setDisplayError('');
        const selectedCountry = event.target.value;

        let selectedPhonePrefix = "+" + allCountries.find(country => country.name === selectedCountry)?.phonePrefix;

        setPhonePrefix(selectedPhonePrefix || "+40");
        setSelectedCountry(selectedCountry);
    };

    const handlePhoneNumberChange = (event: any) => {
        setDisplayError('');
        setPhoneNumber(event.target.value);
    }

    const handlePhoneVerificationCodeChange = (event: any) => {
        setDisplayError('');
        setPhoneVerificationCode(event.target.value);
    }

    const handleAgreeWithTermsAndConditionsChange = (event: any) => {
        setDisplayError('');
        setAgreeWithTermsAndConditions(event.target.checked);
    }

    const handleNewsletterSubscribedChange = (event: any) => {
        setDisplayError('');
        setNewsletterSubscribed(event.target.checked);
    }

    const handleRecaptchaChange = (event: any) => {
        setDisplayError('');
        setIsRecaptchaChecked(event !== null);
    };

    const handleRegisterButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!isFormValid) {
            return;
        }

        let captchaVal = recaptchaRef.current?.getValue();
        if (!captchaVal) {
            return;
        }

        recaptchaRef.current?.reset();
        setIsRecaptchaChecked(false);

        let registerModel: RegisterUserViewModel = new RegisterUserViewModel();
        registerModel.email = email;
        registerModel.firstName = firstname;
        registerModel.lastName = lastname;
        registerModel.password = password;
        registerModel.phoneNumber = phoneNumber;
        registerModel.phonePrefix = phonePrefix;
        registerModel.webPhoneValidationCode = phoneVerificationCode;
        registerModel.countryId = allCountries.find(country => country.name === selectedCountry)?.id || 28;
        registerModel.language = 'EN' ;
        registerModel.receiveAdministrativeEmails = true;
        registerModel.receiveNewsletterEmails = newsletterSubscribed;
        registerModel.termsAndConditions = Math.floor(Date.now() / 1000);
        registerModel.privacyPolicy = Math.floor(Date.now() / 1000);
        registerModel.captcha = captchaVal;
        registerModel.voucherCode = null;
        registerModel.referral = referral;

        try {
            let response = await httpClient.post<RegisterUserViewModel, any>(api.web.REGISTER, registerModel);
            let genericRegistrationErrorMessage = 'Registration failed. Please try again. If the problem persists please reach out to us at office@vtrader.io.';

            if (response.status !== 200) {
                if (response.data.message) {
                    if (response.data.message === 'EMAIL_ALREADY_EXISTS') {
                        setDisplayError('Email is already registered');
                    } else if (response.data.message === 'PHONE_NUMBER_NOT_VERIFIED') {
                        setDisplayError('Phone confirmation code is not valid');
                    } else {
                        setDisplayError(genericRegistrationErrorMessage);
                    }
                } else {
                    setDisplayError(genericRegistrationErrorMessage);
                }
                setIsFormValid(false);
                setRegisterButtonClass('register-button disabled');
            } else {
                setConfirmationMessage('A confirmation email has been sent to ' + email + '. Use the link from the email to activate your vTrader account.');
                resetForm();
                setIsFormValid(false);
                setRegisterButtonClass('register-button disabled');
            }
        } catch (error) {
            setDisplayError('Registration failed');
        }
    };

    const handleVerifyPhoneNumberClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!phoneNumberVerificationButtonPressed) {
            setPhoneNumberVerificationButtonPressed(true);
        }

        const sendVerificationCodeRequest: SendPhoneVerificationCodeViewModel = new SendPhoneVerificationCodeViewModel();
        sendVerificationCodeRequest.phonePrefix = phonePrefix;
        sendVerificationCodeRequest.phoneNumber = phoneNumber;

        try {
            let response = await httpClient.post<SendPhoneVerificationCodeViewModel, any>(api.web.VERIFY_PHONE_NUMBER, sendVerificationCodeRequest);
            if (response.status !== 200) {
                if (response.data.message && response.data.message === "PHONE_NUMBER_ALREADY_EXISTS") {
                    setPhoneNumberValidation('Phone number is already registered');
                    setPhoneNumberVerificationButtonPressed(false);
                    return;
                }
            }
        } catch (error) {
            console.log('Error ', error);
        }

        setPhoneNumberVerificationButtonTimer(30);
    }

    const resetForm = () => {
        setFirstname('');
        setLastname('');
        setEmail('');
        setPassword('');
        setPasswordConfirm('');
        setPhoneNumber('');
        setPhoneVerificationCode('');
        setIsRecaptchaChecked(false);
        setPhoneNumberVerificationButtonPressed(false);
        setPhoneNumberVerificationButtonTimer(0);
        setAgreeWithTermsAndConditions(false);
        setNewsletterSubscribed(false);
    }

    return (
        <div className="login-form">
            <div className="tabs">
                <Link to="/login" className="inactive-tab">Log In</Link>
                <Link to="/register" className="active-tab">Register</Link>
            </div>
            <form>
                <input type="text" placeholder="First Name" value={firstname} onChange={handleFirstnameChange}/>
                <input type="text" placeholder="Last Name" value={lastname} onChange={handleLastnameChange}/>

                <input type="email" placeholder="Email" value={email} onChange={handleEmailChange}/>
                {emailValidation && email && <div className="validation-message">{emailValidation}</div>}

                <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange}/>
                {passwordValidation && password && <div className="validation-message">{passwordValidation}</div>}

                <input type="password" placeholder="Password Confirm" value={passwordConfirm}
                       onChange={handlePasswordConfirmChange}/>
                {passwordConfirmValidation && passwordConfirm && <div className="validation-message">{passwordConfirmValidation}</div>}

                <select value={selectedCountry} onChange={handleCountrySelect} className="register-widget">
                    <option value="">{selectedCountry}</option>
                    {allCountries.length > 0 &&
                        allCountries.map((country) => (
                            <option key={country.id} value={country.name}>
                                {country.name}
                            </option>
                        ))}
                </select>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span  className="register-widget" style={{ width: '8%', marginRight: '5px' }}>{phonePrefix}</span>
                    <input style={{ width: '47%', marginRight: '5px' }} type="text" value={phoneNumber} onChange={handlePhoneNumberChange} placeholder="Mobile Phone" />
                    <button
                        className="register-widget"
                        style={{
                            width: '45%',
                            cursor: phoneNumber && !phoneNumberValidation && phoneNumberVerificationButtonTimer === 0 ? 'pointer' : 'default',
                            color: phoneNumber && !phoneNumberValidation && phoneNumberVerificationButtonTimer === 0 ? 'black' : 'gray',
                        }}
                        disabled={!phoneNumberVerificationButtonPressed ? (!phoneNumber || phoneNumberVerificationButtonPressed) : (phoneNumberVerificationButtonTimer > 0)}
                        onClick={handleVerifyPhoneNumberClick}
                    >
                        {!phoneNumberVerificationButtonPressed ? 'Verify' : ((phoneNumberVerificationButtonTimer > 0 ? `Resend Code (${phoneNumberVerificationButtonTimer})` : 'Resend Code'))}
                    </button>
                </div>
                {phoneNumberValidation && phoneNumber && <div className="validation-message">{phoneNumberValidation}</div>}

                {phoneNumberVerificationButtonPressed && (
                    <input
                        type="text"
                        value={phoneVerificationCode}
                        onInput={(e) => {
                            e.currentTarget.value = e.currentTarget.value.toUpperCase();
                        }}
                        onChange={handlePhoneVerificationCodeChange}
                        placeholder="Mobile Phone Verification Code"
                    />
                )}

                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="checkbox-custom">
                        <input type="checkbox" id="terms" checked={agreeWithTermsAndConditions} onChange={handleAgreeWithTermsAndConditionsChange}/>
                        <label htmlFor="terms" style={{fontSize: '12px'}}>I accept the <Link  target="_blank" rel="noopener noreferrer" to="/terms-and-conditions" style={{color: '#757575'}}>Terms & Privacy Agreement</Link></label>
                    </div>
                </div>

                <div className="checkbox-custom">
                    <input type="checkbox" id="newsletter" checked={newsletterSubscribed} onChange={handleNewsletterSubscribedChange}/>
                    <label htmlFor="newsletter" style={{fontSize: '12px'}}>Sign me up for the mailing list</label>
                </div>
                <div className="recaptcha">
                    <ReCAPTCHA
                        sitekey={RECAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        onChange={handleRecaptchaChange}
                    />
                </div>
                <button className={registerButtonClass} disabled={!isFormValid}
                        onClick={handleRegisterButtonClick}>REGISTER MY ACCOUNT
                </button>
                {displayError !== '' && <div className="registration-error-message">{displayError}</div>}
                {confirmationMessage !== '' && <div className="registration-confirmation-message">{confirmationMessage}</div>}
            </form>
        </div>
    );
};

export default Register;
