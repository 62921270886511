import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {getCurrencySymbol} from "../../services/utils/cryptoSymbols";

const customStyles = {
    content: {
        width: '320px',
        height: '350px',
        margin: 'auto',
        top: '50%',
        left: '50%',
        right: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        border: '1px solid #000', // Thin black border
        boxShadow: 'none', // No shadow
    },
};


interface ConfirmTradeModalProps {
    closeModal: () => void;
    submitWithdrawal: () => Promise<void>;
    confirmCancel: () => void;

    withdrawalAddress: string;
    withdrawalAmount: string;
    coin: string;
    estimatedReceivedAmount: string;
}

Modal.setAppElement('#root');

const ConfirmWithdrawalModal: React.FC<ConfirmTradeModalProps> = ({closeModal,
                                                                  submitWithdrawal,
                                                                  confirmCancel,
                                                                  withdrawalAddress,
                                                                  estimatedReceivedAmount,
                                                                  withdrawalAmount,
                                                                  coin
                                                                  }) => {
    const [scrollDisabled] = useState(false);

    const [isSubmittingWithdrawal, setIsSubmittingWithdrawal] = useState(false);

    useEffect(() => {
        if (!scrollDisabled) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };

    }, [scrollDisabled]);

    const handleSubmitWithdrawal = async () => {
        setIsSubmittingWithdrawal(true);
        try {
            await submitWithdrawal();
        } catch (error) {
            // Handle errors if necessary
        } finally {
            setIsSubmittingWithdrawal(false);
            closeModal();
        }
    };

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
            >


                {isSubmittingWithdrawal ? (
                    <>
                        <p style={{fontSize: '12px', textAlign: 'center', paddingTop: '120px'}}>Submitting
                            withdrawal...</p>
                        <div className="loading-spinner"></div>
                    </>
                ) : (
                    <>
                        <p style={{textAlign: 'center', fontSize: '15px'}}>Withdrawal Summary (Confirmation)</p>
                        <div className="rectangle"
                             style={{backgroundColor: "#DDD", marginBottom: '15px', marginTop: '15px'}}></div>

                        <div className="confirm-trade-modal-container">
                            <div className="confirm-trade-modal-row" style={{flexDirection: 'column'}}>
                                <p className="confirm-trade-modal-row-text">Withdraw To</p>
                                <p className="confirm-trade-modal-row-text">{withdrawalAddress}</p>
                            </div>
                            <div className="confirm-trade-modal-row" style={{flexDirection: 'column'}}>
                                <p className="confirm-trade-modal-row-text">Withdrawn Amount</p>
                                <p className="confirm-trade-modal-row-text">{getCurrencySymbol(coin) + ' ' + withdrawalAmount}</p>
                            </div>
                            <div className="confirm-trade-modal-row" style={{flexDirection: 'column'}}>
                                <p className="confirm-trade-modal-row-text">Estimated Received Amount</p>
                                <p className="confirm-trade-modal-row-text">{estimatedReceivedAmount.substring(0, estimatedReceivedAmount.indexOf('('))}</p>
                            </div>
                        </div>

                        <div className="rectangle"
                             style={{backgroundColor: "#DDD", marginBottom: '25px', marginTop: '15px'}}></div>

                        <div className="setting-section-two-factor-buttons-container">
                            <button className='setting-section-two-factor-button-disabled'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        confirmCancel();
                                        closeModal();
                                    }}>CANCEL
                            </button>
                            <button className='setting-section-two-factor-button-enabled'
                                    style={{cursor: 'pointer'}}
                                    onClick={handleSubmitWithdrawal}>SUBMIT WITHDRAWAL
                            </button>
                        </div>
                    </>
                )}


            </Modal>
        </div>
    );
}

export default ConfirmWithdrawalModal;
