export const formatNumber = (
    number: number | null | undefined,
    decimals: number = 2,
    omitZeros: boolean = false
): string => {
    if (!number) {
        return '0.' + '0'.repeat(decimals); // Ensure the specified number of decimal places with '0'.
    }
    const parts = number.toFixed(decimals).split('.');
    const integerPart = parts[0];
    let decimalPart = parts[1] || '0'.repeat(decimals); // If there's no decimal part, use '0'.

    if (omitZeros) {
        decimalPart = decimalPart.replace(/0+$/, ''); // Remove trailing zeros
    }

    let formattedInteger = '';
    for (let i = integerPart.length - 1, count = 0; i >= 0; i--) {
        formattedInteger = integerPart[i] + formattedInteger;
        count++;
        if (count === 3 && i !== 0) {
            formattedInteger = ',' + formattedInteger;
            count = 0;
        }
    }

    return formattedInteger + (decimalPart ? '.' + decimalPart : '');
};
