export namespace api {
    //export const BASE_URL = 'http://localhost:8010/vApi';
    export const BASE_URL = 'https://app.vtrader.io/vApi'

    //export const SOCKET_URL = 'ws://localhost:8010/vSock';
    export const SOCKET_URL = 'wss://app.vtrader.io/vSock';

    export const web = {
        LOGIN: `${BASE_URL}/auth`,
        REGISTER: `${BASE_URL}/register`,
        REGISTER_RESEND: `${BASE_URL}/resend_confirmation_mail`,
        FORGOT_PASSWORD: `${BASE_URL}/user/forgot_password`,
        GENERATE_NEW_PASSWORD: `${BASE_URL}/user/generate_new_password`,
        CONFIRM_EMAIL: `${BASE_URL}/confirm`,
        VERIFY_PHONE_NUMBER: `${BASE_URL}/send_verification_code_for_web_register`,
        JWT_USER: `${BASE_URL}/user/`,
        DOWNLOAD_USER_FILE: `${BASE_URL}/user/file/`,
        REQUEST_TIER_ONE: `${BASE_URL}/user/request_tier_one`,
        REQUEST_TIER_TWO: `${BASE_URL}/user/request_tier_two`,
        BANK_DETAILS: `${BASE_URL}/user/bank_details`,
        CHANGE_PASSWORD: `${BASE_URL}/user/change_password`,
        RECEIVE_NEWSLETTER: `${BASE_URL}/user/receive_newsletter_emails/`,
        GENERATE_TWO_FACTOR_SECRET_AND_QR_LINK: `${BASE_URL}/user/generate_two_factor_secret_and_qr_link`,
        ENABLE_TWO_FACTOR: `${BASE_URL}/user/enable_two_factor`,
        DISABLE_TWO_FACTOR: `${BASE_URL}/user/disable_two_factor`,
        GET_TRANSACTIONS: `${BASE_URL}/user/transactions/read`,
        WITHDRAWAL: `${BASE_URL}/user/withdrawal/request`,
        PLACE_ORDER: `${BASE_URL}/user/order/place`,
        CANCEL_ORDER: `${BASE_URL}/user/order/cancel`,
        GET_NOTIFICATIONS: `${BASE_URL}/user/notification`,
    }

    export const common = {
        USDT_TICKER: `${BASE_URL}/usdt_ticker`,
        NGN_USDT_RATE: `${BASE_URL}/ngn_usdt_rate`,
        FEES: `${BASE_URL}/fees`,
        MIN_TRADE_VALUE_IN_SATOSHI: `${BASE_URL}/min_trade_value_in_satoshi`,
        MARKET_OHLC: `${BASE_URL}/ohlc`,
        MARKET_SUMMARIES: `${BASE_URL}/market_summaries`,
        MARKET_SUMMARY: `${BASE_URL}/market_summary`,
        ALL_COUNTRIES: `${BASE_URL}/res/country`,
        COUNTRIES_TIER_ONE_BVN: `${BASE_URL}/res/countries_tier_one_bvn`,
        COUNTRIES_PERSONA_CHECK: `${BASE_URL}/res/countries_persona_check`,
        PERSONA_CHECK: `${BASE_URL}/persona_verification`,
        BVN_LIVENESS_CHECK: `${BASE_URL}/bvn_liveness_check`
    }
}
