import React from 'react';

import '../dashboard/Dashboard.css'
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";

const PrivacyPolicy = () => {


    return (
        <div>


            <h1 className="title">Privacy Policy</h1>
            <div className="dashboard-container" style={{marginBottom: '50px'}}>
                <div className="deposit-titles" style={{gap: '25px'}}>
                    <Link to="/terms-and-conditions" className="deposit-inactive-tab"><p>Terms & Conditions</p></Link>
                    <Link to="/privacy-policy" className="deposit-active-tab"><p>Privacy Policy</p></Link>
                    <Link to="/cookie-policy" className="deposit-inactive-tab"><p>Cookie Policy</p></Link>
                    <Link to="/aml-policy" className="deposit-inactive-tab"><p>AML Policy</p></Link>
                    <Link to="/refund-policy" className="deposit-inactive-tab"><p>Refund Policy</p></Link>
                </div>
                <p className="terms-title-section" style={{marginTop: '50px'}}>1. About the Privacy Policy</p>
                <p className="terms-text">This Privacy Policy aims to explain in a simple and transparent way which type of personal data does
                    VTRADER ONLINE SERVICES S.R.L. ("VTrader" or "the Company") collect(The Company, headquartered in
                    Romania, Bucharest, 6th District, 279 Prelungirea Ghencea St., 5th floor, registered with the Trade
                    Register under no. J40 / 8628 / 18.06.2018, having unique identification code 39501244) in
                    connection with the clients / potential clients, hereinafter referred to as "persons" and how we
                    process them. Applies to the following people:</p>
                <ul>
                    <li className="terms-text">All existing clients, current and potential clients of vTrader. We have a legal obligation to
                        keep the data we collect about our customers, including a certain amount of time after the
                        relationship is terminated, in accordance with the "know your customer" obligations;
                    </li>
                    <li className="terms-text">Any person involved in any transaction with VTRADER ONLINE SERVICES S.R.L. as a natural
                        person;
                    </li>
                    <li className="terms-text">Any person providing personal data through the Company's website (for example, when registering
                        an account).
                    </li>
                </ul>
                <p className="terms-text">Personal information is communicated when a person becomes a client, is registered with the vTrader
                    Services, completes an online form, uses our services, or contacts us through one of our
                    channels.</p>
                <p className="terms-text">Personal data refers to any information that tells us something about the client or that we can
                    relate to the customer. These include the name, address, date of birth, account number, IP address,
                    or transaction information the customer has made from the vTrader Online Account. Processing refers
                    to all operations we can do with data such as collecting, recording, storing, adjusting, organizing,
                    using, disclosing, transferring and deleting data.</p>
                <p className="terms-text">We also use data that is legally available from public sources or is legitimately provided by third
                    parties or by the media.</p>
                <p className="terms-title-section">2. The types of data we collect</p>
                <p className="terms-text">The personal data we collect includes:</p>
                <ul>
                    <li className="terms-text">Identification data such as name, surname, place and date of birth, home address, personal
                        identification number, serial number and ID number, phone number, e-mail address, bank account,
                        identity card photo, picture utility bills, etc.;
                    </li>
                    <li className="terms-text">Transaction data, such as account number, holdings of cryptocurrency, purpose and nature of
                        transactions;
                    </li>
                    <li className="terms-text">Mobile app-related data: When you use our mobile app, we may collect and process your phone
                        contacts. The purpose of this collection is to display on the mobile app screen which of your
                        contacts (phone numbers only) already have an account on our platform. This enables us to assist
                        you in finding and connecting with your friends on our platform. It's important to note that we
                        do not store these phone numbers in our database. Instead, we perform a cross-check to determine
                        if your contacts have an account on vtrader, and display this information directly on your
                        mobile app screen.
                    </li>
                </ul>
                <p className="terms-title-section">Sensitive data</p>
                <p className="terms-text">We do not record sensitive data on racial or ethnic origin, political opinions, religious confession
                    or philosophical beliefs, or membership of trade unions and genetic data processing, biometric data
                    to identify a single individual, data on health or data on sexual life, or the sexual orientation of
                    a natural person, unless we have the explicit consent of the person concerned and only if this is
                    absolutely necessary. When we record such data, we limit ourselves to specific circumstances.</p>
                <p className="terms-title-section">3. What we do with personal data</p>
                <p className="terms-text">We do not use personal data except for legitimate business purposes. These include:</p>
                <ul>
                    <li className="terms-text">Administration. When a person uses the vTrader Services, we have a legal obligation to collect
                        personal identification data (for example, a copy of your identity card or passport) and to
                        assess whether we can accept you as a vTrader Services participant. We also need to know the
                        address and e-mail address to be able to contact the person.
                    </li>
                    <li className="terms-text">Providing products and services. We use the information to assess whether the person is eligible
                        for certain products and services.
                    </li>
                    <li className="terms-text">Managing customer relationships. The Company may be requesting feedback on the products and
                        services offered and communicating to certain members of our staff in order to improve service
                        quality. We can also use notes from conversations that we carry online, by phone or face to
                        face, so that we personalize the products and services we offer.
                    </li>
                    <li className="terms-text">Customized marketing. The company may send letters, emails or text messages to provide a product
                        or service based on personal circumstances, or may offer such an offer when people connect to
                        our site or mobile applications. People will be able to unsubscribe from personalized offers of
                        this kind. Individuals have the right to opt out of or oppose to commercial or marketing
                        activities directly in the "Settings" section of your vTrader Online Account.
                    </li>
                    <li className="terms-text">Improvement and development of products and services: The analysis of how the Company's products
                        and services are used, helps us to better understand and show us what we can improve. We analyse
                        the results of our marketing activities to measure their effectiveness and the relevance of
                        their campaigns. Sometimes we can use automated processes to analyse personal data. We can use
                        personal data to send personalized offers via email or our website or mobile apps. Individuals
                        have the right to oppose at any time commercial or direct marketing activities, including the
                        creation of profiles for the purpose of such activities.
                    </li>
                    <li className="terms-text">Fraud Prevention and Detection and Data Security: The Company has the duty to protect personal
                        data and prevent, detect and limit data breach. These include information that the Company is
                        required to collect, for example, for compliance with anti-money laundering regulations, counter
                        terrorist financing, and tax fraud.
                    </li>
                    <li className="terms-text">Internal and external reporting: The company processes the data to conduct transaction
                        operations and to help the management team make better decisions on the operations and services
                        offered and to comply with a number of legal obligations and statutory requirements (e.g.
                        legislation to combat money laundering and tax legislation).
                    </li>
                </ul>
                <p className="terms-title-section">To whom and why we are communicating the data</p>
                <p className="terms-text">In order to provide the best services and keep our competitiveness in the cryptocurrency market, we
                    communicate certain data within and outside the Company. These include:</p>
                <p className="terms-title-section">Within the Company</p>
                <p className="terms-text">We transfer data to the departments and agencies of the Company for operational, regulatory or
                    reporting purposes, such as performing new customer verification, complying with certain laws,
                    guaranteeing the security of IT systems, or providing specific services. We can also transfer data
                    to centralized storage systems or to global processing, thus ensuring greater efficiency.</p>
                <p className="terms-title-section">Government authorities</p>
                <p className="terms-text">In order to comply with regulatory obligations, it is possible to disclose data to relevant
                    authorities, for example, to combat terrorism and prevent money laundering. In some cases, we are
                    legally required to communicate the data of the external parties, including:</p>
                <ul>
                    <li className="terms-text">Public authorities, regulators and supervisors</li>
                    <li className="terms-text">Tax authorities can ask us to report assets owned by our clients. We can process the social
                        security number for this purpose.
                    </li>
                    <li className="terms-text">Judicial / investigative authorities, such as the police, public prosecutors, tribunals and
                        arbitration / mediation bodies, at their express and legal request.
                    </li>
                    <li className="terms-text">Lawyers, notaries, administrators who manage the interests of other parties and auditors of the
                        company.
                    </li>
                </ul>
                <p className="terms-title-section">Financial Institutions</p>
                <p className="terms-text">If you withdraw cash, pay with your debit card or make a payment to an account opened with another
                    bank, the transaction always involves another bank or a specialized financial company. For payment
                    processing, we need to tell the other bank information about you, such as your account name and
                    account number. We also communicate information to financial sector specialists providing financial
                    services, such as:</p>
                <ul>
                    <li className="terms-text">Exchange of secure messages on financial transactions</li>
                    <li className="terms-text">processing transactions</li>
                    <li className="terms-text">processing electronic transactions</li>
                </ul>
                <p className="terms-text">Sometimes we communicate personal information to banks or financial institutions in other countries;
                    for example, if you make or receive an external payment.</p>
                <p className="terms-title-section">Service providers</p>
                <p className="terms-text">If we use other service providers, we only communicate the personal data that is required to perform
                    a particular task. Service providers support the Company with activities such as:</p>
                <ul>
                    <li className="terms-text">execution of certain services and operations</li>
                    <li className="terms-text">marketing activities or events and managing customer communication;</li>
                    <li className="terms-text">preparing reports and statistics, printing materials and designing products;</li>
                </ul>
                <p className="terms-title-section">5. The rights of person and how we respect them</p>
                <p className="terms-text">We respect the rights of the individual as a client or potential client to determine how personal
                    information is used. These rights include:</p>
                <p className="terms-title-section">The right to access the information</p>
                <p className="terms-text">The person has the right to request a report of the personal data we process.</p>
                <p className="terms-title-section">Right to rectification</p>
                <p className="terms-text">If personal data is incorrect, the person has the right to ask us to rectify them. If we communicate
                    to third parties later corrected data, we will also inform the third parties accordingly.</p>
                <p className="terms-title-section">The right to oppose data processing</p>
                <p className="terms-text">The person may oppose the use of personal data by the Company in its legitimate interests. This right
                    may be exercised by email, online or by telephone. We will consider the opposition and if the
                    processing of information has any undue impact that requires the processing of the data to
                    cease.</p>
                <p className="terms-text">Also, the person may oppose receiving personalized commercial messages from us. When the person
                    becomes a client of the Company, they are asked if they want to receive personalized offers. If he
                    or she changes later, he may choose not to receive any messages, using the "unsubscribe" link in the
                    bottom of each commercial e-mail.</p>
                <p className="terms-text">The person cannot object to the processing of personal data by the Company if we have a legal
                    obligation to process them; if it is necessary to conclude a contract with the person; if there are
                    security issues with the customer account, even if they opted not to receive personalized business
                    messages.</p>
                <p className="terms-title-section">The right to restrict the processing</p>
                <p className="terms-text">The person has the right to request us to restrict the use of personal data if:</p>
                <ul>
                    <li className="terms-text">The person considers the information to be inaccurate</li>
                    <li className="terms-text">We process the data illegally</li>
                    <li className="terms-text">The company no longer needs the data, but the person wants to keep it for use in legal action
                    </li>
                    <li className="terms-text">The person opposed the use of personal data by the Company in its legitimate interests</li>
                </ul>
                <p className="terms-title-section">The right to data portability</p>
                <p className="terms-text">The person has the right to ask us to transfer the personal data directly to it or to another
                    company. This applies to personal data that we process by automatic means and with the consent of
                    the person or on the basis of a contract with the person. If this is technically possible, we will
                    transfer personal data.</p>
                <p className="terms-title-section">The right to erase</p>
                <p className="terms-text">The person may ask us to delete personal information if:</p>
                <ul>
                    <li className="terms-text">data is no longer required for the purpose for which it was collected or processed,</li>
                    <li className="terms-text">withdraws the consent on the basis of which the processing takes place</li>
                    <li className="terms-text">objects to data processing by the Company in its legitimate interests</li>
                    <li className="terms-text">the company processes personal data illegally, or</li>
                    <li className="terms-text">a regulation of the European Union or a Member State of the European Union requires the deletion
                        of personal data by the Company.
                    </li>
                </ul>
                <p className="terms-title-section">Right to complaints</p>
                <p className="terms-text">If the person is not satisfied with the way we respond to the request, he / she has the right to make
                    a complaint. If he / she is still dissatisfied with the Company's reaction to the complaint he/she
                    has submitted, he / she may forward it to the Data Protection Officer of the Company. The person can
                    also contact the data protection authority of his / her country of residence.</p>
                <p className="terms-title-section">Exercise of rights</p>
                <p className="terms-text">If the person wishes to exercise their rights or make a complaint, we may be contacted according to
                    the data displayed on the Company's website.</p>
                <p className="terms-text">How the person exercises his / her rights depends on the service of the Company he / she wishes to
                    use and the availability of the services in the country of residence. It can be done by electronic
                    mail or by phone. We intend to respond to the request as soon as possible. In some cases, the
                    response time may be up to one month (if permitted by law). If we need a longer time to solve the
                    request, we will inform you about how much extra time is needed and give the reasons for the
                    delay.</p>
                <p className="terms-text">In some cases, we can reject the request. If permitted by law, we will inform in due time why we
                    rejected the request.</p>
                <p className="terms-title-section">6. The person's duty to provide the data</p>
                <p className="terms-text">There is some information we need to know about people, so we can honor our associated contractual
                    obligations. We also have a legal obligation to collect certain information. Without this data, we
                    may not be able to open an account or be able to perform certain vTrader Services.</p>
                <p className="terms-title-section">7. How we protect personal data</p>
                <p className="terms-text">We apply an internal policy framework and necessary standards at all levels to preserve data
                    security. These policies and standards are periodically updated to meet regulatory and market
                    developments. Specifically, and in accordance with the law, we take appropriate technical and
                    organizational measures (policies and procedures, IT security, etc.) to ensure the confidentiality
                    and integrity of personal data and the way it is processed.</p>
                <p className="terms-text">In addition, Company employees are required to maintain confidentiality and may not disclose personal
                    data unlawfully or unnecessarily.</p>
                <p className="terms-text"><span>You can check the cookie policy</span></p>
                <p className="terms-title-section">8. What you can do to help us keep data safe</p>
                <p className="terms-text">We do our best to protect the data, but there are certain things the person can do in turn:</p>
                <ul>
                    <li className="terms-text">Can install antivirus, antispyware and firewall applications and can update these programs on a
                        regular basis;
                    </li>
                    <li className="terms-text">It can disconnect from the trading application when it is not used;</li>
                    <li className="terms-text">Keep strictly confidential passwords;</li>
                    <li className="terms-text">Be vigilant in the online environment and learn how to detect unusual activities, such as a new
                        website address or phishing emails that request personal information.
                    </li>
                </ul>
                <p className="terms-title-section">9. How long do we keep personal data</p>
                <p className="terms-text">We do not have the right to keep personal data until the time it is needed for the purpose we
                    initially requested. After this period, we are looking for feasible solutions, such as
                    archiving.</p>
                <p className="terms-title-section">10. Contact us</p>
                <p className="terms-text">You can exercise the rights described above, very easily, by simply transmitting a request to the
                    vTrader headquarters in Bucharest, 6th District, 279 Prelungirea Ghencea St., 5th Floor or by e-mail
                    at: support@vtrader.io. In addition, we have appointed a Data Protection Officer who can be
                    contacted if there are any concerns about any aspect that concerns the protection of personal data
                    by submitting a request to the above-mentioned contact details.</p>
                <p className="terms-title-section">11. Scope of this Privacy Policy</p>
                <p className="terms-text">This document represents the Privacy Policy of VTRADER ONLINE SERVICES S.R.L. The policy applies to
                    the Company and the vTrader agencies. </p>
                <p className="terms-text">We may modify this Privacy Policy to maintain compliance with changes to the law and / or reflect how
                    the Company processes personal data.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
