import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import closeIcon from "./icons/close.png";

import aaveIcon from './icons/coin-icons/icon_aave.png';
import apeIcon from './icons/coin-icons/icon_ape.png';
import adaIcon from './icons/coin-icons/icon_ada.png';
import avaxIcon from './icons/coin-icons/icon_avax.png';
import algoIcon from './icons/coin-icons/icon_algo.png';
import atomIcon from './icons/coin-icons/icon_atom.png';
import btcIcon from './icons/coin-icons/icon_btc.png';
import daiIcon from './icons/coin-icons/icon_dai.png';
import dotIcon from './icons/coin-icons/icon_dot.png';
import compIcon from './icons/coin-icons/icon_comp.png';
import dogeIcon from './icons/coin-icons/icon_doge.png';
import ethIcon from './icons/coin-icons/icon_eth.png';
import grtIcon from './icons/coin-icons/icon_grt.png';
import linkIcon from './icons/coin-icons/icon_link.png';
import ltcIcon from './icons/coin-icons/icon_ltc.png';
import maticIcon from './icons/coin-icons/icon_matic.png';
import mkrIcon from './icons/coin-icons/icon_mkr.png';
import solIcon from './icons/coin-icons/icon_sol.png';
import umaIcon from './icons/coin-icons/icon_uma.png';
import uniIcon from './icons/coin-icons/icon_uni.png';
import usdcIcon from './icons/coin-icons/icon_usdc.png';
import usdtIcon from './icons/coin-icons/icon_usdt.png';
import xlmIcon from './icons/coin-icons/icon_xlm.png';
import xrpIcon from './icons/coin-icons/icon_xrp.png';
import UserService from "../../services/user/UserService";
import {formatNumber} from "../../services/utils/formatNumber";
import {useHistory} from "react-router-dom";

const customStyles = {
    content: {
        width: '320px',
        height: 'auto',
        margin: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        border: '1px solid #000', // Thin black border
        boxShadow: 'none', // No shadow
    },
};


interface WalletDetailsModalProps {
    closeModal: () => void;
}

Modal.setAppElement('#root');

const WalletDetailsModal: React.FC<WalletDetailsModalProps> = ({ closeModal }) => {
    const [scrollDisabled, setScrollDisabled] = useState(false);
    const [wallets, setWallets] = useState<any>(null);

    const userService = UserService.getInstance();
    const history = useHistory();

    const coinData = [
        { name: 'AAVE', icon: aaveIcon },
        { name: 'ADA', icon: adaIcon },
        { name: 'APE', icon: apeIcon },
        { name: 'AVAX', icon: avaxIcon },
        { name: 'ALGO', icon: algoIcon },
        { name: 'ATOM', icon: atomIcon },
        { name: 'BTC', icon: btcIcon },
        { name: 'COMP', icon: compIcon },
        { name: 'DAI', icon: daiIcon },
        { name: 'DOGE', icon: dogeIcon },
        { name: 'DOT', icon: dotIcon },
        { name: 'ETH', icon: ethIcon },
        { name: 'GRT', icon: grtIcon },
        { name: 'LINK', icon: linkIcon },
        { name: 'LTC', icon: ltcIcon },
        { name: 'MATIC', icon: maticIcon },
        { name: 'MKR', icon: mkrIcon },
        { name: 'SOL', icon: solIcon },
        { name: 'UMA', icon: umaIcon },
        { name: 'UNI', icon: uniIcon },
        { name: 'USDC', icon: usdcIcon },
        { name: 'USDT', icon: usdtIcon },
        { name: 'XLM', icon: xlmIcon },
        { name: 'XRP', icon: xrpIcon },
    ];

    useEffect(() => {
        if (!scrollDisabled) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        const user = userService.getUser();
        if (user) {
            setWallets(user?.wallets);
        }

        return () => {
            document.body.style.overflow = 'auto';
        };

    }, [scrollDisabled, wallets]);

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Wallet Details Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div className="wallet-details-title">
                    <h3>Wallet Details</h3>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={closeIcon}
                            alt="Close"
                            className="wallet-details-exit-icon" // Add class for close icon styling
                            onClick={() => {
                                closeModal();
                                setScrollDisabled(true);
                            }}
                        />
                    </div>
                </div>

                {wallets && (
                    <table>
                        <tbody>
                            {coinData.map((coin, index) => (
                                <tr>
                                    <div className="wallet-details-coin-box">
                                            <td style={{width: '25px'}}>
                                                <img src={coin.icon} alt={`${coin.name} Icon`} className="wallet-details-icon"/>
                                            </td>
                                            <td className="wallet-details-text" style={{width: '40px'}}>{coin.name}</td>
                                            <td className="wallet-details-text" style={{width: '170px', textAlign: 'right'}}>{formatNumber(wallets[coin.name]?.balance, 8) || 0.00}</td>
                                            <td style={{paddingLeft: '20px', paddingBottom: '5px'}}>
                                               <button className="button-with-text-only" onClick={() => {history.push(`/withdraw?selectedCoin=${coin.name}`)}} style={{marginRight: '20px'}}>&minus;</button>
                                                <button className="button-with-text-only" onClick={() => {history.push(`/deposit?selectedCoin=${coin.name}`)}}>+</button>
                                            </td>
                                    </div>
                                    <div className="rectangle" style={{backgroundColor: "#DDD", marginBottom: '15px', marginTop: '15px'}}></div>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </Modal>
        </div>
    );
}

export default WalletDetailsModal;
