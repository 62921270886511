import React from 'react';

import '../dashboard/Dashboard.css'
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";

const AmlPolicy = () => {

    return (
        <div>

            <div className="dashboard-container" style={{marginBottom: '50px'}}>
                <h1 className="title">AML & CTF Policy</h1>
                <div className="deposit-titles" style={{gap: '25px'}}>
                    <Link to="/terms-and-conditions" className="deposit-inactive-tab"><p>Terms & Conditions</p></Link>
                    <Link to="/privacy-policy" className="deposit-inactive-tab"><p>Privacy Policy</p></Link>
                    <Link to="/cookie-policy" className="deposit-inactive-tab"><p>Cookie Policy</p></Link>
                    <Link to="/aml-policy" className="deposit-active-tab"><p>AML Policy</p></Link>
                    <Link to="/refund-policy" className="deposit-inactive-tab"><p>Refund Policy</p></Link>
                </div>
                <p className="terms-text" style={{marginTop: '50px'}}>vTrader’s Anti-Money-Laundering and Counter-Terrorist-Financing policy
                    prohibits and actively
                    prevents money laundering or any activity that aids in money laundering or financing terrorist and
                    criminal activities. We shall respect all requirements and applicable regulations. Money laundering
                    is generally defined as involvement in acts meant to hide the real origins of crime-derived
                    products, seemingly making revenues have a legitimate origin.</p>
                <p className="terms-text">Internal audits and procedures regarding the money laundering policy are
                    conceived in a manner that
                    assures compliance with every applicable regulation and are periodically updated to assure the
                    existence of adequate policies, procedures and internal audits that account for regulation changes
                    and business alterations.</p>
                <p className="terms-text">vTrader has appointed a responsible person to report money laundering: Anti
                    Money Laundering
                    Officer("AMLO"). AMLO's tasks will include supervising employee communications and training and
                    overseeing software changes to ensure compliance with AMLO's responsibilities.</p>
                <p className="terms-title-section">Know your customer procedure</p>
                <p className="terms-text">We will collect enough information from every customer who has an account to
                    allow us to identify the
                    customer. Upon opening an account, vTrader Online Services SRL will collect the following
                    information, if applicable, for any person, entity or organization who opens a new account and whose
                    name is on the account prior to activating the account for deposits and withdrawals from FIAT
                    currencies: name, date and place of birth (for a natural person), country of residence, e-mail,
                    telephone number, identity card or passport. If a potential or existing customer refuses to provide
                    the information described above on request or appears to have provided erroneous information
                    intentionally, vTrader Online Services will not provide access to the trading services.</p>
                <p className="terms-text">We will respond to a request ("Request") of an authority regarding accounts
                    and transactions by
                    promptly searching our records to determine whether we maintain or retained an account or that they
                    have engaged in any transaction with any person, entity or organization named in the Request . We
                    will designate one or more people to be the contact point for requests and we will update the
                    information immediately. Unless otherwise specified in the Request, we are required to search our
                    files for each person, entity, or organization named in the Request. If AMLO searches for our
                    records and does not find an appropriate account or transaction, then AMLO does not respond to the
                    request.</p>
                <p className="terms-text">We will keep a record of money laundering related information along with the
                    documentation that we
                    did the required search by saving the logs, which will be available at any time on request (from an
                    authorized entity). We will not disclose that the authorities have requested or obtained information
                    from us unless it is necessary to comply with the request. AMLO will review, maintain and implement
                    procedures to protect the security and confidentiality of authorities' requests regarding the
                    protection of non-public customer information. We will send the authorities the questions we have
                    about the Request. Unless otherwise specified in the Request, we will not be obliged to treat the
                    request for information as being of a continuing nature and we will not be obliged to treat the
                    request as a list of terrorists suspected by the government for the purpose of identifying and
                    verifying customers.</p>
                <p className="terms-text">We will keep either the original or another copy or reproduction of each of
                    the following:</p>
                <ul>
                    <li className="terms-text"><span>The registration of each advice, request or instruction received or given in respect of any transaction that results (or intends to result and later canceled if it is normally recorded) in the transfer of currency or other monetary instruments, funds exceeding EUR 10,000; a record of any advice, request or instruction given to another financial institution (including broker-dealers) or another person in respect of a transaction that aims to transfer funds or currency, other monetary instruments, greater than 10.000 EUR;</span>
                    </li>
                    <li className="terms-text"><span>Each document granting a trading authority the account of each client, - a record of each transfer or transfer of funds or a currency exceeding EUR 10,000 per person, account or place, and a record of each currency reception and each transfer of funds over EUR 10,000 received at any time directly and not through a national financial institution, from any person, account or place. We will work closely with all the collaborating institutions for to detect money laundering. We will exchange information, records and data to comply with AML / CFT laws. We will develop ongoing employee training under the leadership of AMLO and senior management. Our training will take place at least once a year.</span>
                    </li>
                </ul>

            </div>
        </div>
    );
};

export default AmlPolicy;
