import React, {useEffect, useState} from 'react';
import menuIcon from './icons/menu.png';
import logoIcon from './icons/lg.png';
import aaveIcon from './icons/coin-icons/icon_aave.png';
import apeIcon from './icons/coin-icons/icon_ape.png';
import adaIcon from './icons/coin-icons/icon_ada.png';
import avaxIcon from './icons/coin-icons/icon_avax.png';
import algoIcon from './icons/coin-icons/icon_algo.png';
import atomIcon from './icons/coin-icons/icon_atom.png';
import btcIcon from './icons/coin-icons/icon_btc.png';
import daiIcon from './icons/coin-icons/icon_dai.png';
import dotIcon from './icons/coin-icons/icon_dot.png';
import compIcon from './icons/coin-icons/icon_comp.png';
import dogeIcon from './icons/coin-icons/icon_doge.png';
import ethIcon from './icons/coin-icons/icon_eth.png';
import grtIcon from './icons/coin-icons/icon_grt.png';
import linkIcon from './icons/coin-icons/icon_link.png';
import ltcIcon from './icons/coin-icons/icon_ltc.png';
import maticIcon from './icons/coin-icons/icon_matic.png';
import mkrIcon from './icons/coin-icons/icon_mkr.png';
import solIcon from './icons/coin-icons/icon_sol.png';
import umaIcon from './icons/coin-icons/icon_uma.png';
import uniIcon from './icons/coin-icons/icon_uni.png';
import usdcIcon from './icons/coin-icons/icon_usdc.png';
import xlmIcon from './icons/coin-icons/icon_xlm.png';
import xrpIcon from './icons/coin-icons/icon_xrp.png';
import {useAuth} from "../../services/auth/AuthContext";
import './Header.css'
import {Link, useHistory} from 'react-router-dom';
import MarketData from "../../services/api/common/response/BittrexMarketPrice";
import BittrexMarketSummaryService from "../../services/bittrex-market-summary/BittrexMarketSummaryService";
import {formatNumber} from "../../services/utils/formatNumber";
import LeftPanelModal from "../left-panel-modal/LeftPanelModal";
import RightPanelModal from "../right-panel-modal/RightPanelModal";
import UserService from "../../services/user/UserService";
import WalletSidebarModal from "../wallet-details-modal/WalletSidebarModal";
import AlertsModal from "../alerts-modal/AlertsModal";
import LogoutModal from "../logout-modal/LogoutModal";

const Header = () => {
    const {isLoggedIn, logout} = useAuth();

    const history = useHistory();

    const [marketData, setMarketData] = useState<MarketData | null>(null);
    const [isLeftPanelModalOpen, setLeftPanelModalOpen] = useState(false);
    const [isRightPanelModalOpen, setRightPanelModalOpen] = useState(false);
    const [isWalletSidebarModalOpen, setWalletSidebarOpen] = useState(false);
    const [isAlertsModalOpen, setAlertsModalOpen] = useState(false);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [userInitials, setUserInitials] = useState('');

    const userService = UserService.getInstance();

    useEffect(() => {
        const fetchData = async () => {
            const marketSummaryService = BittrexMarketSummaryService.getInstance();
            const summaries = marketSummaryService.getMarketSummaries();
            setMarketData(summaries);

            if (userInitials === '') {
                const user = userService.getUser();
                if (user) {
                    const userInitials = (user?.tierZeroInfo?.firstname?.[0] ?? '-').toUpperCase() + (user?.tierZeroInfo?.lastname?.[0] ?? '-').toUpperCase();
                    setUserInitials(userInitials);
                }
            }
        };

        fetchData().then(() => {
        });

        const intervalId = setInterval(fetchData, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const openLeftPanelModal = () => {
        setLeftPanelModalOpen(true);
    };

    const openRightPanelModal = () => {
        setRightPanelModalOpen(true);
    }

    const openWalletSidebarModal = () => {
        setWalletSidebarOpen(true);
    }

    return (
        <div>
            {isLeftPanelModalOpen && <LeftPanelModal closeModal={() => setLeftPanelModalOpen(false)}/>}
            {isRightPanelModalOpen && <RightPanelModal
                closeModal={() => setRightPanelModalOpen(false)}
                openWalletSidebarModal={() => setWalletSidebarOpen(true)}
                openAlertsModal={() => setAlertsModalOpen(true)}
                openLogoutModal={() => setIsLogoutModalOpen(true)}/>}
            {isWalletSidebarModalOpen && <WalletSidebarModal closeModal={() => setWalletSidebarOpen(false)}/>}
            {isAlertsModalOpen && <AlertsModal closeModal={() => setAlertsModalOpen(false)}/>}
            {isLogoutModalOpen && <LogoutModal closeModal={() => setIsLogoutModalOpen(false)} confirm={() => {logout(); history.push('/login');}} />}
            <header className="header">
                {marketData && (
                    <div className="market-data">
                    <span className="market-data-item">
                        <img src={aaveIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">AAVE ${formatNumber(marketData.AAVE)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={adaIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">ADA ${formatNumber(marketData.ADA)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={apeIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">APE ${formatNumber(marketData.APE)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={algoIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">ALGO ${formatNumber(marketData.ALGO)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={atomIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">ATOM ${formatNumber(marketData.ATOM)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={avaxIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">AVAX ${formatNumber(marketData.AVAX)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={btcIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">BTC ${formatNumber(marketData.BTC)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={compIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">COMP ${formatNumber(marketData.COMP)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={daiIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">DAI ${formatNumber(marketData.DAI)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={dogeIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">DOGE ${formatNumber(marketData.DOGE)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={dotIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">DOT ${formatNumber(marketData.DOT)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={ethIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">ETH ${formatNumber(marketData.ETH)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={grtIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">GRT ${formatNumber(marketData.GRT)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={linkIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">LINK ${formatNumber(marketData.LINK)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={ltcIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">LTC ${formatNumber(marketData.LTC)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={maticIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">MATIC ${formatNumber(marketData.MATIC)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={mkrIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">MKR ${formatNumber(marketData.MKR)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={solIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">SOL ${formatNumber(marketData.SOL)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={umaIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">UMA ${formatNumber(marketData.UMA)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={uniIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">UNI ${formatNumber(marketData.UNI)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={usdcIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">USDC ${formatNumber(marketData.USDC)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={xlmIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">XLM ${formatNumber(marketData.XLM)}</span>
                    </span>

                        <span className="market-data-item">
                        <img src={xrpIcon} alt="ic" className="market-icon"/>
                        <span className="market-data-text">XRP ${formatNumber(marketData.XRP)}</span>
                    </span>

                    </div>
                )}
                <div className="logo-container" style={{justifyContent: 'space-between'}}>
                    {!isLeftPanelModalOpen && (<img src={menuIcon} alt="Menu" className="menu-icon" onClick={openLeftPanelModal}/>)}
                    {isLeftPanelModalOpen && (<img src={menuIcon} alt="Menu" className="menu-icon" style={{visibility: 'hidden'}}/>)}
                    <div className="logo">
                        <Link to="/dashboard"><img className="logo-image" src={logoIcon} alt="Logo"/></Link>
                    </div>

                    {isLoggedIn && (<div>
                        {!isRightPanelModalOpen && (
                            <div className="header-user-circle-container" onClick={openRightPanelModal}>
                                <p className="header-user-circle-text">{userInitials}</p>
                            </div>)}

                        {isRightPanelModalOpen && (<div className="header-user-circle-container" style={{backgroundColor: 'white'}}></div>)}
                    </div>)}
                    {!isLoggedIn && <div className="header-user-circle-container" style={{backgroundColor: 'white', cursor: 'default'}}></div>}
                </div>
            </header>
        </div>
    );
};

export default Header;