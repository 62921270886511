import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRouter from './Router'; // Import the Router component
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./services/auth/AuthContext";
import {WebSocketProvider} from "./websocket/WebsocketContext";
import TagManager from 'react-gtm-module'
import Hotjar from "@hotjar/browser";

const hotjarSiteId = 3719567;
const hotjarVersion = 6;

Hotjar.init(hotjarSiteId, hotjarVersion);

const tagManagerArgs = {
    gtmId: 'GTM-5L3NC6P9'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <>
        <AuthProvider>
            <WebSocketProvider>
            <AppRouter />
            </WebSocketProvider>
        </AuthProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
