import React from 'react';

import '../dashboard/Dashboard.css'
import 'react-toastify/dist/ReactToastify.css';

const Faq = () => {

    return (
        <div>
            <div className="dashboard-container" style={{marginBottom: '50px'}}>
                <h1 className="title">Frequently Asked Questions</h1>

                <div className="rectangle" style={{backgroundColor: "#DDD"}}></div>
                <p className="faq-title-section-text">- How do I deposit fiat on vTrader?</p>
                <p className="faq-content-section-text">vTrader platform only operates with cryptocurrency. It doesn't offer the possibility to deposit fiat currency. Instead, vTrader operates USDT which has the sole purpose to offer stability and behave as a traditional fiat currency with all the crypto/blockchain advantages. USDT Add Funds Orders can be executed in the "Add Funds" section of our platform. Users buy USDT through us by issuing a simple bank transfer, specifying their customer reference on the transfer details. USDT will be credited to the customer's account when the deposit is processed by our staff. The received funds(EUR) will be converted to USDT at the exchange rate displayed on our website.</p>

                <div className="rectangle" style={{backgroundColor: "#DDD"}}></div>
                <p className="faq-title-section-text">- How do I withdraw my funds?</p>
                <p className="faq-content-section-text">Customers can issue a withdrawal of USDT in the "Withdrawal" section of our platform. For security reasons, and in order for AML(Anti-Money-Laundering) standards to be respected, users can only withdraw fiat in a bank account from which they already bought any amount of USDT. The withdrawal will be processed by our staff and the USDT submitted for withdrawal will be converted into EUR at the moment of processing.</p>

                <div className="rectangle" style={{backgroundColor: "#DDD"}}></div>
                <p className="faq-title-section-text">- Can my funds interact with the rest of the blockchain?</p>
                <p className="faq-content-section-text">One of the main advantages of holding cryptocurrency is the freedom of these financial assets. Here at vTrader we endorse this key-feature of cryptos, and we enable our customers to handle their funds as they see fit. Customers can initiate transactions on third-party wallets via our platform and thus moving their funds on the broad blockchain. This can be done in the "Withdrawal" section of our website.</p>

                <div className="rectangle" style={{backgroundColor: "#DDD"}}></div>
                <p className="faq-title-section-text">- I have some bitcoin, ethereum, cryptocurrency. Can i deposit them on vTrader and exchange them for other cryptocurrencies?</p>
                <p className="faq-content-section-text">Yes. Most coins listed on vTrader automatically open blockchain wallets for our users. Users are given a public key for these coins that they can see in the "Deposit" section of our platform. vTrader interogates the blockchain to update the balance of these wallets and automatically updates your funds after several block confirmations.</p>

                <div className="rectangle" style={{backgroundColor: "#DDD"}}></div>
                <p className="faq-title-section-text">- How does vTrader calculate the EUR-USDT exchange rates?</p>
                <p className="faq-content-section-text">vTrader checks the market exchange rate from several trusted third parties, then adds the deposit/withdrawal fee to the resulting exchange rate.</p>

                <div className="rectangle" style={{backgroundColor: "#DDD"}}></div>
                <p className="faq-title-section-text">- What do I have to keep in mind as a vTrader customer?</p>
                <p className="faq-content-section-text">We encourage responsible trading. Cryptocurrencies have very volatile prices, and the fluctuations are very hard to predict. Trade only funds that you afford losing.</p>
            </div>
        </div>
    );
};

export default Faq;
