import React, {useEffect, useState} from 'react';
import './Login.css';
import {Link, Redirect} from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha"
import {RECAPTCHA_SITE_KEY} from "../../services/constants";
import HttpClient from "../../services/api/HttpClient";
import LoginRequestViewModel from "../../services/api/web/request/LoginRequestViewModel";
import {api} from "../../services/api/api";
import LoginResponseViewModel from "../../services/api/web/response/LoginResponseViewModel";
import {useAuth} from "../../services/auth/AuthContext";
import UserService from "../../services/user/UserService";
import {toast} from "react-toastify";
import {showErrorToast} from "../../services/utils/toastUtils";

const Login = () => {
    const { isLoggedIn, login } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [twoFactor, setTwoFactor] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const [loginButtonClass, setLoginButtonClass] = useState('login-button');

    const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const [emailValidation, setEmailValidation] = useState('');

    const userService = UserService.getInstance();

    useEffect(() => {
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        const isValid = email.trim() !== ''
            && isEmailValid
            && password.trim() !== ''
            && isRecaptchaChecked;
        setIsFormValid(isValid);
        setEmailValidation(!isEmailValid ? 'Email should have a valid format' : '');
        setLoginButtonClass(isValid ? 'login-button' : 'login-button disabled');
    }, [email, password, isRecaptchaChecked]);

    if (isLoggedIn) {
        return <Redirect to="/dashboard" />;
    }

    const httpClient = new HttpClient();
    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const handleTwoFactorChange = (event: any) => {
        setTwoFactor(event.target.value);
    }

    const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(event.target.checked);
    };

    const handleRecaptchaChange = (event: any) => {
        setIsRecaptchaChecked(event !== null);
    };

    const handleLoginButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!isFormValid) {
            return;
        }

        let captchaVal = recaptchaRef.current?.getValue();
        if (!captchaVal) {
            return;
        }

        recaptchaRef.current?.reset();

        const loginRequest: LoginRequestViewModel = new LoginRequestViewModel();

        loginRequest.username = email;
        loginRequest.password = password;
        loginRequest.captcha = captchaVal;

        if (twoFactor.trim() !== '') {
            loginRequest.twoFactorVerificationCode = twoFactor;
        }

        try {
            const response = await httpClient.post<LoginRequestViewModel, LoginResponseViewModel>(api.web.LOGIN, loginRequest);

            if (response.status !== 200) {
                showErrorToast(toast,'Authentication failed. Please check your credentials.');
                setPassword('');
                setTwoFactor('');
                setRememberMe(false);
                setIsFormValid(false);
                setIsRecaptchaChecked(false);
            } else {
                login(response.data.token, rememberMe);
                await userService.fetchUser();
                await userService.fetchBankDetails();
                await userService.fetchDeposits();
                await userService.fetchWithdrawals();
                await userService.fetchOrders();
                await userService.fetchMobileTransfers();
                await userService.fetchNotifications();
            }
        } catch (error) {
            showErrorToast(toast,'Authentication failed. Please check your credentials.');
        }
    };

    return (
        <div className="login-form">
            <div className="tabs">
                <Link to="/login" className="active-tab">Log In</Link>
                <Link to="/register" className="inactive-tab">Register</Link>
            </div>
            <form>
                <input type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                {emailValidation && email && <div className="validation-message">{emailValidation}</div>}
                <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                <input type="text" placeholder="Two Factor Code" value={twoFactor} onChange={handleTwoFactorChange} />
                <div className="remember-forgot">
                    <div className="remember">
                        <input type="checkbox" id="remember" checked={rememberMe} onChange={handleRememberMeChange}/>
                        <label htmlFor="remember">Remember Me</label>
                    </div>
                    <div className="forgot">
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                </div>
                <div className="recaptcha">
                    <ReCAPTCHA
                        sitekey={RECAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        onChange={handleRecaptchaChange}
                    />
                </div>
                <button className={loginButtonClass} disabled={!isFormValid} onClick={handleLoginButtonClick}>LOG IN</button>
            </form>
        </div>
    );
};

export default Login;
