class UserServiceUtils {
    private static instance: UserServiceUtils | null = null;

    private constructor() {}

    static getInstance(): UserServiceUtils {
        if (!UserServiceUtils.instance) {
            UserServiceUtils.instance = new UserServiceUtils();
        }
        return UserServiceUtils.instance;
    }

    hasTier(authorities: any, tier: string) {
        if (!Array.isArray(authorities)) {
            return false;
        }

        return authorities.some((authority: any) => authority && authority.authority === tier);
    }
}

export default UserServiceUtils;