export interface OrderViewModel {
    id: number;
    market: string;
    asset: string;
    type: string;
    state: string;
    pendingCancel: boolean;
    fee: number | null; // Assuming fee can be nullable
    initialQuantity: number;
    filledQuantity: number;
    unitsFilled: number;
    rate: number;
    datePlaced: number;
    dateClosed: number | null; // Assuming dateClosed can be nullable
}

export function areOrdersEqual(order1: OrderViewModel, order2: OrderViewModel): boolean {
    return (
        order1.id === order2.id &&
        order1.market === order2.market &&
        order1.asset === order2.asset &&
        order1.type === order2.type &&
        order1.state === order2.state &&
        order1.pendingCancel === order2.pendingCancel &&
        order1.fee === order2.fee &&
        order1.initialQuantity === order2.initialQuantity &&
        order1.filledQuantity === order2.filledQuantity &&
        order1.unitsFilled === order2.unitsFilled &&
        order1.rate === order2.rate &&
        order1.datePlaced === order2.datePlaced &&
        order1.dateClosed === order2.dateClosed
    );
}
