import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import '../dashboard/Dashboard.css';
import UserService from "../../services/user/UserService";
import JwtUser from "../../services/api/common/jwtUser/JwtUser";
import AllowedCountry from "../../services/api/common/response/AllowedCountry";
import AllowedCountriesService from "../../services/allowed-countries/AllowedCountriesService";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UserDocumentFileViewModel from "../../services/api/common/jwtUser/UserDocumentFileViewModel";
import TierOneRequestViewModel from "../../services/api/web/request/TierOneRequestViewModel";
import checkedIcon from './checked.png'
import UserServiceUtils from "../../services/user/UserServiceUtils";
import {toast} from "react-toastify";
import {api} from "../../services/api/api";
import {
    ActivityFieldOptions,
    EmployeeStatusOptions, FiscalResidenceOptions,
    SourceOfIncomeOptions, TransactionPurposeOptions, YesNoOptions
} from "../../services/utils/tierTwoFieldsOptions";
import TierTwoRequestViewModel from "../../services/api/web/request/TierTwoRequestViewModel";
import {showErrorToast, showSuccessToast} from "../../services/utils/toastUtils";
import ConfirmSubmitTierModal from "./ConfirmSubmitTierModal";

const Account = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialTierSelectedParam = queryParams.get('selectedTier') || 'tier1';

    const [selectedTier, setSelectedTier] = useState(initialTierSelectedParam);
    const [user, setUser] = useState<JwtUser | null>(null);

    const [allCountries, setAllCountries] = useState<AllowedCountry[]>([]);
    const [countriesPersonaCheck, setCountriesPersonaCheck] = useState<AllowedCountry[]>([]);
    const [countriesTierOneBvn, setCountriesTierOneBvn] = useState<AllowedCountry[]>([]);

    const [shouldUsePersonaCheck, setShouldUsePersonaCheck] = useState(false);
    const [shouldUseBvn, setShouldUseBvn] = useState(false);

    const userService = UserService.getInstance();
    const userServiceUtils = UserServiceUtils.getInstance();

    const [isSubmitTierModalOpen, setSubmitTierModalOpen] = useState(false);
    const [submitTierModalMessage, setSubmitTierModalMessage] = useState('');
    const [submitTierModalHeight, setSubmitTierModalHeight] = useState('');
    const [submitTierModalFunctor, setSubmitTierModalFunctor] = useState<() => Promise<void>>(() => new Promise<void>(() => {}));

    /* tier one */

    // first row
    const [firstname, setFirstname] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [bvnNumber, setBvnNumber] = useState('');

    // second row
    const [lastname, setLastname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [birthDate, setBirthDate] = useState<Date | null>(null);

    const [allTierOneFiles, setAllTierOneFiles] = useState<UserDocumentFileViewModel[]>([]);
    const [tierOneUploadedFilesNumber, setTierOneUploadedFilesNumber] = useState(0);
    const [shouldDisableTierOneUploadButton, setShouldDisableTierOneUploadButton] = useState(false);

    const [addressValidation, setAddressValidation] = useState('');
    const [cityValidation, setCityValidation] = useState('');
    const [stateValidation, setStateValidation] = useState('');
    const [postalCodeValidation, setPostalCodeValidation] = useState('');
    const [birthdayValidation, setBirthdayValidation] = useState('');
    const [bvnNumberValidation, setBvnNumberValidation] = useState('');

    const [isTierOneFormValid, setIsTierOneFormValid] = useState(false);
    const [shouldDisableTierOneRequestButton, setShouldDisableTierOneRequestButton] = useState(true);

    const [hasTierOne, setHasTierOne] = useState(false);
    const [hasPendingTierOne, setHasPendingTierOne] = useState(false);

    /* end tier one */

    /* tier two */

    const [employmentStatus, setEmploymentStatus] = useState('');
    const [activityField, setActivityField] = useState('');
    const [moneySource, setMoneySource] = useState('');
    const [fiscalResidence, setFiscalResidence] = useState('');
    const [employerName, setEmployerName] = useState('');
    const [employerStateInstitution, setEmployerStateInstitution] = useState('');
    const [employerListedOnStockExchange, setEmployerListedOnStockExchange] = useState('');
    const [purposeOfTransaction, setPurposeOfTransaction] = useState('');
    const [userPublicPerson, setUserPublicPerson] = useState('');

    const [hasTierTwo, setHasTierTwo] = useState(false);
    const [hasPendingTierTwo, setHasPendingTierTwo] = useState(false);

    const [allTierTwoFiles, setAllTierTwoFiles] = useState<UserDocumentFileViewModel[]>([]);
    const [tierTwoUploadedFilesNumber, setTierTwoUploadedFilesNumber] = useState(0);
    const [shouldDisableTierTwoUploadButton, setShouldDisableTierTwoUploadButton] = useState(false);
    const [shouldDisableTierTwoRequestButton, setShouldDisableTierTwoRequestButton] = useState(true);
    const [isTierTwoFormValid, setIsTierTwoFormValid] = useState(false);

    /* end tier two */

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const screenWidth = window.outerWidth;

    useEffect(() => {
        const user = userService.getUser();
        if (user) {
            setUser(user);
        }

        async function fetchAllowedCountries() {
            const countries = await AllowedCountriesService.getInstance().getAllCountries();
            setAllCountries(countries);
        }

        async function fetchCountriesPersonaCheck() {
            const personaCheckCountries = await AllowedCountriesService.getInstance().getCountriesPersonaCheck();
            setCountriesPersonaCheck(personaCheckCountries);
        }

        async function fetchCountriesTierOneBvn() {
            const tierOneBvnCountries = await AllowedCountriesService.getInstance().getCountriesTierOneBvn();
            setCountriesTierOneBvn(tierOneBvnCountries);
        }

        setPhoneNumber((user?.tierZeroInfo?.phonePrefix || '+40') + user?.tierZeroInfo?.phoneNumber);
        setFirstname(user?.tierZeroInfo?.firstname || '');
        setLastname(user?.tierZeroInfo?.lastname || '');
        setEmail(user?.tierZeroInfo?.email || '');

        setAddress(user?.tierOneInfo?.address || '');
        setCity(user?.tierOneInfo?.city || '');
        setState(user?.tierOneInfo?.state || '');
        setPostalCode(user?.tierOneInfo?.postalCode || '');
        setBirthDate(user?.tierOneInfo?.birthdate || null);

        setBvnNumber(user?.tierOneInfo?.extraInfo?.bvnNumber || '');

        setAllTierOneFiles(user?.tierOneInfo?.files || []);

        setHasTierOne(userServiceUtils.hasTier(user?.authorities, 'ROLE_USER_TIER_1'));
        setHasPendingTierOne(userServiceUtils.hasTier(user?.authorities, 'PENDING_VERIFICATION_TIER_1'));

        ////

        setHasTierTwo(userServiceUtils.hasTier(user?.authorities, 'ROLE_USER_TIER_2'));
        setHasPendingTierTwo(userServiceUtils.hasTier(user?.authorities, 'PENDING_VERIFICATION_TIER_2'))

        setAllTierTwoFiles(user?.tierTwoInfo?.files || []);

        setEmploymentStatus(user?.tierTwoInfo?.employeeStatus || '');
        setActivityField(user?.tierTwoInfo?.activityField || '');
        setMoneySource(user?.tierTwoInfo?.moneySource || '');
        setFiscalResidence(user?.tierTwoInfo?.fiscalResidence || '');
        setEmployerName(user?.tierTwoInfo?.employerName || '');
        setEmployerStateInstitution(user?.tierTwoInfo?.employerStateInstitution === undefined ? ('') : (user?.tierTwoInfo?.employerStateInstitution ? 'Yes' : 'No'));
        setEmployerListedOnStockExchange(user?.tierTwoInfo?.employerListedOnStockExchange === undefined ? ('') : (user?.tierTwoInfo?.employerListedOnStockExchange ? 'Yes' : 'No'));
        setPurposeOfTransaction(user?.tierTwoInfo?.purposeOfTransaction || '');
        setUserPublicPerson(user?.tierTwoInfo?.userPublicPerson === undefined ? ('') : (user?.tierTwoInfo?.userPublicPerson ? 'Yes' : 'No'));

        Promise.all([fetchAllowedCountries(), fetchCountriesPersonaCheck(), fetchCountriesTierOneBvn()])
            .then(() => {
                setCountry(allCountries.find(country => country.id === user?.tierZeroInfo?.countryId)?.name || 'Romania');
                const foundPersonaCountry = countriesPersonaCheck.find(personaCountry => personaCountry.name === country);
                setShouldUsePersonaCheck(!!foundPersonaCountry);

                const foundTierOneBvnCountry = countriesTierOneBvn.find(personaCountry => personaCountry.name === country);
                setShouldUseBvn(!!foundTierOneBvnCountry);
            })
            .catch(error => {
                console.error("Error during fetching countries", error);
            });
    }, [countriesTierOneBvn, allCountries, countriesPersonaCheck, country, shouldUsePersonaCheck, shouldUseBvn, userService, user]);

    // effect for tier one
    useEffect(() => {
        if (shouldUseBvn) {
            setShouldDisableTierOneUploadButton(tierOneUploadedFilesNumber === 1);
        } else {
            setShouldDisableTierOneUploadButton(tierOneUploadedFilesNumber > 10);
        }

        let tierOneFormValid: boolean;

        const isAddressValid = address.trim() !== '';
        setAddressValidation(!isAddressValid ? 'Address should not be empty' : '');
        const isCityValid = city.trim() !== '';
        setCityValidation(!isCityValid ? 'City should not be empty' : '');
        const isStateValid = state.trim() !== '';
        setStateValidation(!isStateValid ? 'State should not be empty' : '');
        const isPostalCodeValid = postalCode.trim() !=='';
        setPostalCodeValidation(!isPostalCodeValid ? 'Postal code should not be empty' : '');

        let isBirthdayValid = true;
        if (birthDate == null) {
            isBirthdayValid = false;
            setBirthdayValidation('Birthday should not be empty');
        } else {
            const userBirthDate = new Date(birthDate);
            const currentDate = new Date();
            const userAge = currentDate.getFullYear() - userBirthDate.getFullYear();
            if (userAge < 18) {
                isBirthdayValid = false;
                setBirthdayValidation('User should be at least 18 years old');
            } else {
                isBirthdayValid = true;
                setBirthdayValidation('');
            }
        }

        tierOneFormValid = isAddressValid && isCityValid && isStateValid && isPostalCodeValid && isBirthdayValid;

        if (shouldUseBvn) {
            const isBvnNumberValid = bvnNumber.trim() !== '' && /^[1-9]\d*$/.test(bvnNumber) && bvnNumber.length > 7;
            setBvnNumberValidation(!isBvnNumberValid ? 'BVN number should have a minimum length of 7 characters' : '');
            tierOneFormValid = tierOneFormValid && isBvnNumberValid;
            tierOneFormValid = tierOneFormValid && (tierOneUploadedFilesNumber === 1);
        }

        setIsTierOneFormValid(tierOneFormValid);

        setShouldDisableTierOneRequestButton(!tierOneFormValid);
    }, [address,
              city,
              state,
              postalCode,
              birthDate,
              bvnNumber,
              shouldUseBvn,
              allTierOneFiles,
              shouldDisableTierOneRequestButton,
              tierOneUploadedFilesNumber,
              shouldDisableTierOneUploadButton,
              user]);

    // effect for tier Two
    useEffect(() => {

        setShouldDisableTierTwoUploadButton(tierTwoUploadedFilesNumber > 10);

        const isEmployeeStatusValid = EmployeeStatusOptions.some((option) => option.label === employmentStatus);
        const isFieldOfActivityValid = ActivityFieldOptions.some((option) => option.label === activityField);
        const isMoneySourceValid = SourceOfIncomeOptions.some((option) => option.label === moneySource);
        const isFiscalResidenceValid = FiscalResidenceOptions.some((option) => option.key === fiscalResidence);
        const isEmployerNameValid = employerName.trim() !== '';
        const isEmployerStateInstitutionValid = YesNoOptions.some((option) => option.key === employerStateInstitution);
        const isEmployerListedOnStockExchangeValid = YesNoOptions.some((option) => option.key === employerListedOnStockExchange);
        const isPurposeOfTransactionValid = TransactionPurposeOptions.some((option) => option.key === purposeOfTransaction);
        const isUserPublicPersonValid = YesNoOptions.some((option) => option.key === userPublicPerson);

        const tierTwoFormValid = isEmployeeStatusValid &&
            isFieldOfActivityValid &&
            isMoneySourceValid &&
            isFiscalResidenceValid &&
            isEmployerNameValid &&
            isEmployerStateInstitutionValid &&
            isEmployerListedOnStockExchangeValid &&
            isPurposeOfTransactionValid &&
            isUserPublicPersonValid &&
            tierTwoUploadedFilesNumber >= 1;

        setIsTierTwoFormValid(tierTwoFormValid);

        setShouldDisableTierTwoRequestButton(!tierTwoFormValid);
    }, [employmentStatus,
              activityField,
              moneySource,
              fiscalResidence,
              employerName,
              employerStateInstitution,
              employerListedOnStockExchange,
              purposeOfTransaction,
              userPublicPerson,
              allTierTwoFiles,
              shouldDisableTierTwoRequestButton,
              tierTwoUploadedFilesNumber,
              shouldDisableTierTwoUploadButton,
              user]);

    const handleTierSubmitClick = (tier: string)=> {
        if (tier === 'tier1') {
            setSubmitTierModalMessage('Confirm Tier 1 Submission');
            setSubmitTierModalFunctor(() => handleTierOneClick);
            setSubmitTierModalHeight('120px');
            setSubmitTierModalOpen(true);
        } else if (tier === 'tier2') {
            setSubmitTierModalMessage('Confirm Tier 2 Submission');
            setSubmitTierModalHeight('120px');
            if (shouldUseBvn) {
                setSubmitTierModalMessage('Ensure that you have successfully completed the BVN Liveness Check. If you proceed without completing the liveness check, you will be required to undergo tier two verification again.');
                setSubmitTierModalHeight('170px');
            }
            setSubmitTierModalFunctor(() => handleTierTwoClick);
            setSubmitTierModalOpen(true);
        }
    }

    const handleTierOneClick = async () => {

        if (!isTierOneFormValid) {
            return;
        }

        const formatDateToYYYYMMDD = (date: Date) =>
            `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        if (shouldUsePersonaCheck) {
            window.location.href = api.common.PERSONA_CHECK + '?' +
                'customer_reference=' + encodeURIComponent(user?.id || 0) +
                '&city=' + encodeURIComponent(city) +
                '&state=' + encodeURIComponent(state) +
                '&postal_code=' + encodeURIComponent(postalCode) +
                '&birthdate=' + encodeURIComponent(formatDateToYYYYMMDD(new Date(birthDate || new Date()))) +
                '&address=' + encodeURIComponent(address);
            return;
        }

        const tierOneRequest: TierOneRequestViewModel = {
            city: city,
            state: state,
            postalCode: postalCode,
            birthdate: new Date(birthDate || new Date()),
            address: address,
            extraInfo: shouldUseBvn ? {bvnNumber: bvnNumber} : {}
        };

        const files = allTierOneFiles
            .filter((doc) => doc.status === 'TO BE UPLOADED' && doc.file)
            .map((doc) => doc.file as File);

        const response = await userService.requestTierOne(tierOneRequest, files);

        if (response && response.status === 200) {
            showSuccessToast(toast, 'Verification requested. Your data will be processed by our staff');
            await userService.fetchUser();
            setUser(userService.getUser());
        } else {
            showErrorToast(toast, 'An error occurred while submitting your data. Please try again later. If the problem persists, contact our staff.');
        }
    }

    const handleTierTwoClick = async () => {
        if (!isTierTwoFormValid) {
            return;
        }

        const tierTwoRequest: TierTwoRequestViewModel = {
            employeeStatus: employmentStatus,
            activityField: activityField,
            moneySource: moneySource,
            purposeOfTransaction: purposeOfTransaction,
            employerName: employerName,
            fiscalResidence: fiscalResidence,
            residence: fiscalResidence,
            isEmployerStateInstitution: employerStateInstitution === 'Yes',
            isEmployerListedOnStockExchange: employerListedOnStockExchange === 'Yes',
            isUserPublicPerson: userPublicPerson === 'Yes',
        };

        const files = allTierTwoFiles
            .filter((doc) => doc.status === 'TO BE UPLOADED' && doc.file)
            .map((doc) => doc.file as File);

        const response = await userService.requestTierTwo(tierTwoRequest, files);

        if (response && response.status === 200) {
            showSuccessToast(toast, 'Verification requested. Your data will be processed by our staff');
            await userService.fetchUser();
            setUser(userService.getUser());
        } else {
            showErrorToast(toast, 'An error occurred while submitting your data. Please try again later. If the problem persists, contact our staff.');
        }
    }

    const performBvnLivenessCheck = async () => {
        window.location.href = api.common.BVN_LIVENESS_CHECK + '?' +
            'firstname=' + encodeURIComponent(firstname)
            + '&lastname=' + encodeURIComponent(lastname)
            + '&customer_reference=' + encodeURIComponent(user?.id || 0);
    }

    const handleTierOneFileChange = () => {
        const files = fileInputRef.current?.files;
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map((file) => ({
                name: file.name,
                size: file.size,
                file: file,
                status: 'TO BE UPLOADED',
                docId: 0,
                type: '',
                tierPurpose: 1,
            }));
            setAllTierOneFiles([...allTierOneFiles, ...newFiles]);
            setTierOneUploadedFilesNumber(tierOneUploadedFilesNumber + 1);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Clear the input for the same file to trigger onChange
            }
        }
    };

    const handleTierTwoFileChange = () => {
        const files = fileInputRef.current?.files;
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map((file) => ({
                name: file.name,
                size: file.size,
                file: file,
                status: 'TO BE UPLOADED',
                docId: 0,
                type: '',
                tierPurpose: 2,
            }));
            setAllTierTwoFiles([...allTierTwoFiles, ...newFiles]);
            setTierTwoUploadedFilesNumber(tierTwoUploadedFilesNumber + 1);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Clear the input for the same file to trigger onChange
            }
        }
    };

    const handleTierFileUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the file input click when "Add Files" is clicked
        }
    };

    const handleTierOneFileDelete = (index: number) => {
        const newUploadedFiles = [...allTierOneFiles];
        newUploadedFiles.splice(index, 1);
        setAllTierOneFiles(newUploadedFiles);
        setTierOneUploadedFilesNumber(tierOneUploadedFilesNumber - 1);
    };

    const handleTierTwoFileDelete = (index: number) => {
        const newUploadedFiles = [...allTierTwoFiles];
        newUploadedFiles.splice(index, 1);
        setAllTierTwoFiles(newUploadedFiles);
        setTierTwoUploadedFilesNumber(tierTwoUploadedFilesNumber - 1);
    };

    const handleFileDownload = async (file: UserDocumentFileViewModel) => {
        try {
            // Fetch the blob data from the server
            const res = await userService.downloadUserFile(file.docId.toString());

            // Create a blob URL for the data
            const blob = new Blob([res || new Blob()], { type: file.type });
            const blobUrl = URL.createObjectURL(blob);

            // Extract the file extension from the name
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts.pop();
            const fileNameWithoutExtension = fileNameParts.join('.');

            // Construct the file name
            const downloadedFileName = `${fileNameWithoutExtension}.${fileExtension}`;

            // Create a temporary anchor element for the download
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = downloadedFileName;

            // Trigger the download
            a.click();

            // Clean up by revoking the blob URL
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    const handleTierClick = (tier: string) => {
        setSelectedTier(tier);
    };

    const handleAddressChange = (event: any) => {
        setAddress(event.target.value);
    };

    const handleCityChange = (event: any) => {
        setCity(event.target.value);
    };

    const handleBvnNumberChange = (event: any) => {
        setBvnNumber(event.target.value);
    };

    const handleStateChange = (event: any) => {
        setState(event.target.value);
    };

    const handlePostalCodeChange = (event: any) => {
        setPostalCode(event.target.value);
    };

    const handleBirthDateChange = (event: any) => {
        setBirthDate(event);
    };

    const handleEmploymentStatusChange = (event: any) => {
        setEmploymentStatus(event.target.value);
    };

    const handleActivityFieldChange = (event: any) => {
        setActivityField(event.target.value);
    };

    const handleMoneySourceChange = (event: any) => {
        setMoneySource(event.target.value);
    };

    const handleFiscalResidenceChange = (event: any) => {
        setFiscalResidence(event.target.value);
    };

    const handleEmployerNameChange = (event: any) => {
        setEmployerName(event.target.value);
    };

    const handleIsEmployerStateInstitutionChange = (event: any) => {
        setEmployerStateInstitution(event.target.value);
    };

    const handleIsEmployerListedOnStockExchangeChange = (event: any) => {
        setEmployerListedOnStockExchange(event.target.value);
    };

    const handlePurposeOfTransactionChange = (event: any) => {
        setPurposeOfTransaction(event.target.value);
    };

    const handleIsUserPublicPersonChange = (event: any) => {
        setUserPublicPerson(event.target.value);
    };


    return (
        <div>
            <div className="center-container">
                <div className="menu">
                    <Link to="/dashboard" className="link-custom"><p>DASHBOARD</p></Link>
                    <Link to="/orders" className="link-custom"><p>ORDERS</p></Link>
                    <Link to="/trade" className="link-custom"><p>TRADE</p></Link>
                    <Link to="/deposit" className="link-custom"><p>ADD FUNDS</p></Link>
                    <Link to="/withdraw" className="link-custom"><p>WITHDRAW FUNDS</p></Link>
                </div>
            </div>

            <h1 className="title">Account</h1>

            <div className="dashboard-container">
                <div className="deposit-titles">
                    <Link
                        to="/account?selectedTier=tier1"
                        className={selectedTier === 'tier1' ? 'deposit-active-tab' : 'deposit-inactive-tab'}
                        onClick={() => handleTierClick('tier1')}
                    >
                        <p>Tier 1 Verification</p>
                    </Link>
                    <Link
                        to="/account?selectedTier=tier2"
                        className={selectedTier === 'tier2' ? 'deposit-active-tab' : 'deposit-inactive-tab'}
                        onClick={() => handleTierClick('tier2')}
                    >
                        <p>Tier 2 Verification</p>
                    </Link>
                </div>

                {isSubmitTierModalOpen && <ConfirmSubmitTierModal
                    closeModal={() => setSubmitTierModalOpen(false)}
                    dismiss={() => {}}
                    height={submitTierModalHeight}
                    confirm={submitTierModalFunctor}
                    message={submitTierModalMessage}/>
                }

                { selectedTier === 'tier1' && (<div>
                    <div className="account-tier-one-form-container" style={{display: screenWidth > 600 ? 'flex' : 'block' }}>
                        <div className="account-tier-one-form-box" style={{marginBottom: screenWidth > 600 ? '0px' : '10px' }}>
                            <input type="text" placeholder="First Name" value={firstname} disabled={true} title="First Name"/>
                            <input type="email" placeholder="Email address" value={email} disabled={true} title="Email"/>
                            <input type="text" placeholder="Address*" value={address} onChange={handleAddressChange} disabled={hasTierOne || hasPendingTierOne} title="Address"/>
                            {addressValidation && address && <div className="account-validation-message">{addressValidation}</div>}
                            <input type="text" placeholder="City*" value={city} onChange={handleCityChange} disabled={hasTierOne || hasPendingTierOne} title="City"/>
                            {cityValidation && city && <div className="account-validation-message">{cityValidation}</div>}
                            <input type="text" placeholder="Country" value={country} disabled={true} title="Country"/>
                            { shouldUseBvn && (<input type="number" placeholder="BVN Number*" value={bvnNumber} onChange={handleBvnNumberChange} disabled={hasTierOne || hasPendingTierOne} title="BVN Number"/>) }
                            { shouldUseBvn && bvnNumberValidation && bvnNumber && <div className="account-validation-message">{bvnNumberValidation}</div>}
                        </div>
                        <div className="account-tier-one-form-box">
                            <input type="text" placeholder="Last Name" value={lastname}  disabled={true} title="Last Name"/>
                            <input type="text" placeholder="Phone Number" value={phoneNumber}  disabled={true} title="Phone Number"/>
                            <input type="text" placeholder="State/Province*" value={state} onChange={handleStateChange} disabled={hasTierOne || hasPendingTierOne} title="State/Province"/>
                            {stateValidation && state && <div className="account-validation-message">{stateValidation} disabled={hasTierOne || hasPendingTierOne}</div>}
                            <input type="text" placeholder="Zip/Postal Code*" value={postalCode} onChange={handlePostalCodeChange} disabled={hasTierOne || hasPendingTierOne} title="Zip/Postal Code"/>
                            {postalCodeValidation && postalCode && <div className="account-validation-message">{postalCodeValidation}</div>}
                            <DatePicker
                                title="Birthday"
                                selected={birthDate}
                                onChange={handleBirthDateChange}
                                placeholderText="Birthdate*"
                                dateFormat="MM/dd/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                scrollableYearDropdown
                                minDate={new Date('1940-01-01')}
                                maxDate={new Date('2004-12-31')}
                                yearDropdownItemNumber={40}
                                disabled={hasTierOne || hasPendingTierOne}
                            />
                            {birthdayValidation && birthDate && <div className="account-validation-message">{birthdayValidation}</div>}
                        </div>
                    </div>

                    <div className="rectangle" style={{marginBottom: '20px'}}></div>

                    { shouldUsePersonaCheck && (<p className="tier-one-one-instruction-text">Please provide your current address and birthday. Once you've completed this step, you'll be guided through a simple verification process. This will involve taking a selfie and uploading a national document.</p>)}
                    { shouldUseBvn && (<div>
                        <p className="tier-one-one-instruction-text">1. Provide your current address, birthday and BVN identification number.</p>
                        <p className="tier-one-one-instruction-text">2. Confirm your identity by uploading a selfie (picture of yourself) in one of the following formats (.pdf,.jpg,.jpeg,.png,.pdf) of maximum 5MB size. </p>
                        </div>)}
                    { !shouldUsePersonaCheck && !shouldUseBvn && (<div>
                        <p className="tier-one-one-instruction-text">Please provide your current address, birthday. Also, confirm your identity by uploading the following documents (.pdf,.jpg,.jpeg,.png,.pdf) of maximum 5MB size:</p>
                        <p className="tier-one-one-instruction-text">1. A copy of Passport or ID card from both sides. The information must be clearly visible and readable</p>
                        <p className="tier-one-one-instruction-text">2. A selfie (picture of yourself) holding your uploaded ID</p>
                    </div>)}

                    { (shouldUseBvn || !shouldUsePersonaCheck) && (<div>
                        <div className="rectangle" style={{backgroundColor: "#DDD", marginBottom: '20px', marginTop: '20px'}}></div>

                        <div className="account-files-table-container">
                            <table style={{width: '100%'}}>
                                <thead>
                                <tr>
                                    <th style={{fontWeight: 'bold', width: '50%'}} className="account-files-table-text">Document</th>
                                    <th style={{fontWeight: 'bold', width: '20%'}} className="account-files-table-text">Size</th>
                                    <th style={{fontWeight: 'bold', width: '20%'}} className="account-files-table-text">Status</th>
                                    <th style={{fontWeight: 'bold', width: '10%'}} className="account-files-table-text">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {allTierOneFiles.map((file, index) => (
                                    <tr key={index}>
                                        <td className="account-files-table-text">{file.name}</td>
                                        <td className="account-files-table-text">{(file.size / 1024).toFixed(2)} KB</td>
                                        <td className="account-files-table-text">{file.status}</td>
                                        <td className="account-files-table-text">
                                            <div className="account-files-actions-container">
                                                {file.status === 'TO BE UPLOADED' && (<p className="account-files-table-text" onClick={() => handleTierOneFileDelete(index)} style={{cursor: 'pointer'}} title="Delete document">X</p>)}
                                                {file.status !== 'TO BE UPLOADED' && (<p className="account-files-table-text" onClick={() => handleFileDownload(file)} style={{cursor: 'pointer', fontWeight: 'bold'}} title="Download document">↓</p>)}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="account-files-table-upload-container">
                            <input type="file" accept=".pdf, .jpg, .jpeg, .png" onChange={handleTierOneFileChange} ref={fileInputRef} style={{ display: 'none' }}/>
                            <button onClick={handleTierFileUploadClick}
                                    style={{ backgroundColor: shouldDisableTierOneUploadButton || hasTierOne || hasPendingTierOne ? '#BBB' : '#111' }}
                                    className="account-files-upload-button"
                                    disabled={shouldDisableTierOneUploadButton || hasTierOne || hasPendingTierOne}
                            >+ ADD FILES</button>
                        </div>
                    </div>)}

                    <div className="account-request-verification-button-container">
                        <button className="account-request-verification-button"
                                disabled={shouldDisableTierOneRequestButton || hasTierOne || hasPendingTierOne}
                                style={{ backgroundColor: shouldDisableTierOneRequestButton || hasTierOne || hasPendingTierOne ? '#BBB' : '#111' }}
                                onClick={() => {handleTierSubmitClick('tier1')}}>
                            REQUEST VERIFICATION
                        </button>
                    </div>
                </div>)}

                { selectedTier === 'tier2' && (<div>
                    <div className="account-tier-one-form-container" style={{display: screenWidth > 600 ? 'flex' : 'block' }}>
                        <div className="account-tier-two-form-box" style={{marginBottom: screenWidth > 600 ? '0px' : '10px' }}>
                            <select value={employmentStatus} onChange={handleEmploymentStatusChange} className="account-tier-two-dropdown" title="Employment Status" disabled={hasTierTwo || hasPendingTierTwo} >
                                <option value="">Select Employment Status*</option>
                                {EmployeeStatusOptions.length > 0 &&
                                    EmployeeStatusOptions.map((statusOption) => (
                                        <option key={statusOption.key} value={statusOption.label}>
                                            {statusOption.label}
                                        </option>
                                    ))}
                            </select>
                            <select value={moneySource} onChange={handleMoneySourceChange} className="account-tier-two-dropdown" title="Main source of income" disabled={hasTierTwo || hasPendingTierTwo} >
                                <option value="">Select your main source of income*</option>
                                {SourceOfIncomeOptions.length > 0 &&
                                    SourceOfIncomeOptions.map((statusOption) => (
                                        <option key={statusOption.key} value={statusOption.label}>
                                            {statusOption.label}
                                        </option>
                                    ))}
                            </select>
                            <input type="text" placeholder="Employer*" value={employerName} onChange={handleEmployerNameChange} disabled={hasTierTwo || hasPendingTierTwo} title="Employer"/>
                            <select value={purposeOfTransaction} onChange={handlePurposeOfTransactionChange} className="account-tier-two-dropdown" disabled={hasTierTwo || hasPendingTierTwo} title="Purpose of transaction">
                                <option value="">TransactionPurpose*</option>
                                {TransactionPurposeOptions.length > 0 &&
                                    TransactionPurposeOptions.map((statusOption) => (
                                        <option key={statusOption.key} value={statusOption.key}>
                                            {statusOption.label}
                                        </option>
                                    ))}
                            </select>
                            <select value={userPublicPerson} onChange={handleIsUserPublicPersonChange} className="account-tier-two-dropdown" title="Are you or your family politically exposed?" disabled={hasTierTwo || hasPendingTierTwo} >
                                <option value="">Are you or your family politically exposed?*</option>
                                {YesNoOptions.length > 0 &&
                                    YesNoOptions.map((statusOption) => (
                                        <option key={statusOption.key} value={statusOption.key}>
                                            {statusOption.key}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="account-tier-two-form-box">
                            <select value={activityField} onChange={handleActivityFieldChange} className="account-tier-two-dropdown" title="Field of activity" disabled={hasTierTwo || hasPendingTierTwo} >
                                <option value="">Field of activity*</option>
                                {ActivityFieldOptions.length > 0 &&
                                    ActivityFieldOptions.map((statusOption) => (
                                        <option key={statusOption.key} value={statusOption.label}>
                                            {statusOption.label}
                                        </option>
                                    ))}
                            </select>
                            <select value={fiscalResidence} onChange={handleFiscalResidenceChange} className="account-tier-two-dropdown" title="Your fiscal residence" disabled={hasTierTwo || hasPendingTierTwo} >
                                <option value="">Your fiscal residence*</option>
                                {FiscalResidenceOptions.length > 0 &&
                                    FiscalResidenceOptions.map((statusOption) => (
                                        <option key={statusOption.key} value={statusOption.key}>
                                            {statusOption.label}
                                        </option>
                                    ))}
                            </select>
                            <select value={employerStateInstitution} onChange={handleIsEmployerStateInstitutionChange} className="account-tier-two-dropdown" title="Is your employer company a state company?" disabled={hasTierTwo || hasPendingTierTwo} >
                                <option value="">Is your employer company a state company?*</option>
                                {YesNoOptions.length > 0 &&
                                    YesNoOptions.map((statusOption) => (
                                        <option key={statusOption.key} value={statusOption.key}>
                                            {statusOption.key}
                                        </option>
                                    ))}
                            </select>
                            <select value={employerListedOnStockExchange} onChange={handleIsEmployerListedOnStockExchangeChange} className="account-tier-two-dropdown" title="Is your employer a publicly traded company?" disabled={hasTierTwo || hasPendingTierTwo} >
                                <option value="">Is your employer company a publicly traded company?*</option>
                                {YesNoOptions.length > 0 &&
                                    YesNoOptions.map((statusOption) => (
                                        <option key={statusOption.key} value={statusOption.key}>
                                            {statusOption.key}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="rectangle" style={{marginBottom: '20px'}}></div>

                    <p className="tier-one-one-instruction-text">1. Please provide your financial statement.</p>
                    <p className="tier-one-one-instruction-text">2. Confirm your address by uploading the a copy of any utility services bill (electricity/water/gas or any other) assigned to your address in one of the following formats (.jpg, .jpeg, .png, .pdf) of maximum 5MB size. The invoice can be assigned to a person other than you living in the same address.</p>
                    { shouldUseBvn && (<p className="tier-one-one-instruction-text">3. Perform BVN Liveness verification check to confirm your identity.</p>)}

                    <div className="rectangle" style={{backgroundColor: "#DDD", marginBottom: '20px', marginTop: '20px'}}></div>

                    <div className="account-files-table-container">
                        <table style={{width: '100%'}}>
                            <thead>
                            <tr>
                                <th style={{fontWeight: 'bold', width: '50%'}} className="account-files-table-text">Document</th>
                                <th style={{fontWeight: 'bold', width: '20%'}} className="account-files-table-text">Size</th>
                                <th style={{fontWeight: 'bold', width: '20%'}} className="account-files-table-text">Status</th>
                                <th style={{fontWeight: 'bold', width: '10%'}} className="account-files-table-text">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {allTierTwoFiles.map((file, index) => (
                                <tr key={index}>
                                    <td className="account-files-table-text">{file.name}</td>
                                    <td className="account-files-table-text">{(file.size / 1024).toFixed(2)} KB</td>
                                    <td className="account-files-table-text">{file.status}</td>
                                    <td className="account-files-table-text">
                                        <div className="account-files-actions-container">
                                            {file.status === 'TO BE UPLOADED' && (<p className="account-files-table-text" onClick={() => handleTierTwoFileDelete(index)} style={{cursor: 'pointer'}} title="Delete document">X</p>)}
                                            {file.status !== 'TO BE UPLOADED' && (<p className="account-files-table-text" onClick={() => handleFileDownload(file)} style={{cursor: 'pointer', fontWeight: 'bold'}} title="Download document">↓</p>)}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="account-files-table-upload-container">
                        <input type="file" accept=".pdf, .jpg, .jpeg, .png" onChange={handleTierTwoFileChange} ref={fileInputRef} style={{ display: 'none' }}/>
                        <button onClick={handleTierFileUploadClick}
                                style={{ backgroundColor: shouldDisableTierTwoUploadButton || hasTierTwo || hasPendingTierTwo ? '#BBB' : '#111' }}
                                className="account-files-upload-button"
                                disabled={shouldDisableTierTwoUploadButton || hasTierTwo || hasPendingTierTwo}
                        >+ ADD FILES</button>
                    </div>

                    { shouldUseBvn && (<div>
                        <div className="rectangle" style={{backgroundColor: "#DDD", marginBottom: '20px', marginTop: '20px'}}></div>

                        <div className="account-request-verification-button-container">
                            <button className="account-files-upload-button"
                                    disabled={hasTierTwo || hasPendingTierTwo}
                                    style={{ backgroundColor: hasTierTwo || hasPendingTierTwo ? '#BBB' : '#111' }}
                                    onClick={performBvnLivenessCheck}>
                                PERFORM BVN LIVENESS
                            </button>
                        </div>

                        <div className="rectangle" style={{backgroundColor: "#DDD", marginBottom: '20px', marginTop: '20px'}}></div>
                    </div>)}

                    <div className="account-request-verification-button-container">
                        <button className="account-request-verification-button"
                                disabled={shouldDisableTierTwoRequestButton || hasTierTwo || hasPendingTierTwo}
                                style={{ backgroundColor: shouldDisableTierTwoRequestButton || hasTierTwo || hasPendingTierTwo ? '#BBB' : '#111' }}
                                onClick={() => {handleTierSubmitClick('tier2')}}>
                            REQUEST VERIFICATION
                        </button>
                    </div>
                </div>)}

                <div className="rectangle" style={{backgroundColor: "#DDD", marginTop: '50px', marginBottom: '20px'}}></div>
                <div className="account-tier-status-line">
                    <p className="account-tier-status-text">Tier 0</p>
                    <div className="account-verified-container-with-icon">
                        <div className="account-checked-icon-container">
                            <img src={checkedIcon} alt="ic" className="account-checked-icon" />
                        </div>
                        <p className="account-tier-status-text" style={{color: '#128754'}}>Verified</p>
                    </div>

                </div>
                <p className="account-tier-status-text" style={{marginTop: '-5px'}}>&#x2022; Access to Profile & Dashboard</p>

                <div className="rectangle" style={{backgroundColor: "#DDD", marginBottom: '20px', marginTop: '30px'}}></div>
                <div className="account-tier-status-line">
                    <p className="account-tier-status-text">Tier 1</p>
                    {!hasPendingTierOne && !hasTierOne && (<p className="account-tier-status-text">Not verified</p>)}
                    {hasPendingTierOne && (<p className="account-tier-status-text">Verification in progress</p>)}
                    {hasTierOne &&
                        (<div className="account-verified-container-with-icon">
                            <div className="account-checked-icon-container">
                                <img src={checkedIcon} alt="ic" className="account-checked-icon" />
                            </div>
                            <p className="account-tier-status-text" style={{color: '#128754'}}>Verified</p>
                        </div>)}

                </div>
                <p className="account-tier-status-text" style={{marginTop: '-5px'}}>&#x2022; Deposit crypto & fiat</p>
                <p className="account-tier-status-text" style={{marginTop: '-5px'}}>&#x2022; $2,000 daily limit</p>

                <div className="rectangle" style={{backgroundColor: "#DDD", marginBottom: '20px', marginTop: '30px'}}></div>
                <div className="account-tier-status-line">
                    <p className="account-tier-status-text">Tier 2</p>
                    {!hasPendingTierTwo && !hasTierTwo && (<p className="account-tier-status-text">Not verified</p>)}
                    {hasPendingTierTwo && (<p className="account-tier-status-text">Verification in progress</p>)}
                    {hasTierTwo &&
                        (<div className="account-verified-container-with-icon">
                            <div className="account-checked-icon-container">
                                <img src={checkedIcon} alt="ic" className="account-checked-icon" />
                            </div>
                            <p className="account-tier-status-text" style={{color: '#128754'}}>Verified</p>
                        </div>)}
                </div>
                <p className="account-tier-status-text" style={{marginTop: '-5px'}}>&#x2022; Withdraw crypto & fiat</p>
                <p className="account-tier-status-text" style={{marginTop: '-5px'}}>&#x2022; $8,000 daily limit</p>

                <div className="rectangle" style={{backgroundColor: "#DDD", marginBottom: '40px', marginTop: '30px'}}></div>
            </div>

        </div>
    );
};

export default Account;
