import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import '../dashboard/Dashboard.css'
import UserService from "../../services/user/UserService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import copyIcon from "../deposit/icons/copy.png";
import {showSuccessToast} from "../../services/utils/toastUtils";

const Referrals = () => {

    const userService = UserService.getInstance();

    const [userReference, setUserReference] = useState('');

    useEffect(() => {

        const user = userService.getUser();
        if (user) {
            setUserReference(user?.publicIdentifier);
        }

    }, []); // Empty dependency array means the effect runs only once

    const copyToClipboard = (text: string) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    };

    const handleCopyClick = () => {
        const link = 'https://app.vtrader.io/register?ref=' + userReference;
        copyToClipboard(link);
        showSuccessToast(toast, 'Referral link copied to clipboard');
    };

    return (
        <div>
            <div className="center-container">
                <div className="menu">
                    <Link to="/dashboard" className="link-custom"><p>DASHBOARD</p></Link>
                    <Link to="/orders" className="link-custom"><p>ORDERS</p></Link>
                    <Link to="/trade" className="link-custom"><p>TRADE</p></Link>
                    <Link to="/deposit" className="link-custom"><p>ADD FUNDS</p></Link>
                    <Link to="/withdraw" className="link-custom"><p>WITHDRAW FUNDS</p></Link>
                </div>
            </div>

            <h1 className="title">Referrals</h1>
            <div className="dashboard-container">

                <div className="deposit-titles">
                    <Link to="/referrals" className="deposit-active-tab"><p>Invite</p></Link>
                    <Link to="/referrals" className="deposit-inactive-tab"><p>History</p></Link>
                </div>

                <div className="referrals-container">
                    <p className="referrals-text">Invite a friend and receive $10!</p>

                    <button className="deposit-copy-to-clipboard-button" onClick={handleCopyClick}>
                        <span className="copy-icon" style={{backgroundImage: `url(${copyIcon})`}}></span>
                        COPY TO CLIPBOARD
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Referrals;
