// Define the TypeScript enum for NotificationType
import {formatNumber} from "../../../utils/formatNumber";
import {getCurrencySymbol} from "../../../utils/cryptoSymbols";

export enum NotificationType {
    WELCOME = 'WELCOME',
    PASSWORD_CHANGED = 'PASSWORD_CHANGED',
    ORDER_COMPLETED = 'ORDER_COMPLETED',
    ORDER_PARTIALLY_EXECUTED = 'ORDER_PARTIALLY_EXECUTED',
    VOUCHER_CLAIMED = 'VOUCHER_CLAIMED',
    VOUCHER_RECEIVED = 'VOUCHER_RECEIVED',
    WITHDRAWAL_REQUEST_DECLINED = 'WITHDRAWAL_REQUEST_DECLINED',
    WITHDRAWAL_REQUEST_CONFIRMED = 'WITHDRAWAL_REQUEST_CONFIRMED',
    DEPOSIT_CREDITED = 'DEPOSIT_CREDITED',
    DEPOSIT_RETURNED = 'DEPOSIT_RETURNED',
    CRYPTO_WITHDRAWAL_CANCELED = 'CRYPTO_WITHDRAWAL_CANCELED',
    CRYPTO_WITHDRAWAL_AUTHORIZED = 'CRYPTO_WITHDRAWAL_AUTHORIZED',
    CRYPTO_DEPOSIT_CREDITED = 'CRYPTO_DEPOSIT_CREDITED',
    TIER_VERIFIED = 'TIER_VERIFIED',
    TIER_DECLINED = 'TIER_DECLINED'
}

// Define the TypeScript interface for Notification
export interface NotificationViewModel {
    id: number;
    userId: number;
    type: NotificationType;
    viewed: boolean;
    clicked: boolean;
    created: number;
    updated: number;
    param1: string;
    param2: string;
    param3: string;
    param4: string;
    refObject: string;
}

export function areNotificationsEqual(notification1: NotificationViewModel, notification2: NotificationViewModel): boolean {
    // Compare each field of the two notifications
    return (
        notification1.id === notification2.id &&
        notification1.userId === notification2.userId &&
        notification1.type === notification2.type &&
        notification1.viewed === notification2.viewed &&
        notification1.clicked === notification2.clicked &&
        notification1.created === notification2.created &&
        //notification1.updated === notification2.updated &&
        notification1.param1 === notification2.param1 &&
        notification1.param2 === notification2.param2 &&
        notification1.param3 === notification2.param3 &&
        notification1.param4 === notification2.param4 &&
        notification1.refObject === notification2.refObject
    );
}

export const getNotificationDescription = (notif: NotificationViewModel): string => {
    try {
        if (notif.type === NotificationType.WELCOME) {
            return 'Welcome to vTrader!';
        }

        if (notif.type === NotificationType.ORDER_COMPLETED) {
            const type = (notif.param1 === 'LIMIT_BUY') ? 'buy' : 'sell';
            const quantity = formatNumber(parseFloat(notif.param2), 6, true);
            const coinSymbol = getCurrencySymbol(notif.param3);
            return 'Your ' + type + ' order of ' + coinSymbol + quantity + ' was successfully processed.';
        }

        if (notif.type === NotificationType.ORDER_PARTIALLY_EXECUTED) {
            const type = (notif.param1 === 'LIMIT_BUY') ? 'buy' : 'sell';
            const totalQuantity = formatNumber(parseFloat(notif.param2), 6, true);
            const filledQuantity = formatNumber(parseFloat(notif.param3), 6, true);
            const coinSymbol = getCurrencySymbol(notif.param4);
            return 'Your ' + type + ' order has been partially filled with ' +
                getCurrencySymbol(coinSymbol) + filledQuantity + ' out of ' + getCurrencySymbol(coinSymbol) + totalQuantity + '.';
        }

        if (notif.type === NotificationType.CRYPTO_WITHDRAWAL_CANCELED) {
            const quantity = notif.param1;
            const coinSymbol = getCurrencySymbol(notif.param2);
            return 'Your withdrawal of ' + coinSymbol + quantity + ' has been canceled. Your funds have been returned to your vTrader wallet.';
        }

        if (notif.type === NotificationType.WITHDRAWAL_REQUEST_DECLINED) {
            const ob = JSON.parse(notif.refObject);
            const currency = ob.initialCurrency;
            const initialAmount = ob.initialAmount;
            const toWallet = ob.toWallet;
            return 'Your withdrawal of ' + getCurrencySymbol(currency) + initialAmount + ' to ' + toWallet + ' was declined.';
        }

        if (notif.type === NotificationType.PASSWORD_CHANGED) {
            return 'Your password has been successfully changed.';
        }

        if (notif.type === NotificationType.TIER_VERIFIED) {
            const tier = notif.param1 === 'ROLE_USER_TIER_1' ? 'Tier 1' : 'Tier 2';
            return 'Your ' + tier + ' update request has been approved.';
        }

        if (notif.type === NotificationType.TIER_DECLINED) {
            const tier = notif.param1 === 'PENDING_VERIFICATION_TIER_1' ? 'Tier 1' : 'Tier 2';
            return 'Your ' + tier + ' request has been declined.';
        }

        if (notif.type === NotificationType.WITHDRAWAL_REQUEST_CONFIRMED) {
            const ob = JSON.parse(notif.refObject);
            const currency = ob.initialCurrency;
            const initialAmount = ob.initialAmount;
            const toWallet = ob.toWallet;
            return 'Your withdrawal of ' + getCurrencySymbol(currency) + initialAmount + ' to ' + toWallet + ' has been confirmed.';
        }

        if (notif.type === NotificationType.CRYPTO_DEPOSIT_CREDITED) {
            const quantity = formatNumber(parseFloat(notif.param1), 6, true);
            const coinSymbol = getCurrencySymbol(notif.param2);
            return 'Your deposit of ' + coinSymbol + quantity + ' has been credited.';
        }

        if (notif.type === NotificationType.CRYPTO_WITHDRAWAL_AUTHORIZED) {
            const quantity = formatNumber(parseFloat(notif.param1), 6, true);
            const coinSymbol = getCurrencySymbol(notif.param2);
            return 'Your withdrawal of ' + coinSymbol + quantity + ' has been authorized.';
        }

        if (notif.type === NotificationType.DEPOSIT_CREDITED) {
            const quantity = formatNumber(parseFloat(notif.param1), 6, true);
            const coinSymbol = getCurrencySymbol(notif.param2);
            return 'Your deposit of ' + coinSymbol + quantity + ' has been credited.';
        }

        if (notif.type === NotificationType.DEPOSIT_RETURNED) {
            const quantity = formatNumber(parseFloat(notif.param1), 6, true);
            const coinSymbol = getCurrencySymbol(notif.param2);
            return 'Your deposit of ' + coinSymbol + quantity + ' has been returned.';
        }

        if (notif.type === NotificationType.VOUCHER_CLAIMED) {
            const quantity = formatNumber(parseFloat(notif.param1), 6, true);
            const coinSymbol = getCurrencySymbol(notif.param2);
            const destinationUser = notif.param3;
            return 'Your voucher of ' + coinSymbol + quantity + ' has been claimed by ' + destinationUser + '.';
        }

        if (notif.type === NotificationType.VOUCHER_RECEIVED) {
            const quantity = formatNumber(parseFloat(notif.param1), 6, true);
            const coinSymbol = getCurrencySymbol(notif.param2);
            const sourceName = notif.param3 + ' ' + notif.param4;
            return 'You have received a voucher of ' + coinSymbol + quantity + ' from ' + sourceName + '.';
        }

        return '';
    } catch (e) {
        return '';
    }
};

export const isNotificationGood = (notif: NotificationViewModel): boolean => {
    switch (notif.type) {
        case NotificationType.WELCOME:
        case NotificationType.PASSWORD_CHANGED:
        case NotificationType.ORDER_COMPLETED:
        case NotificationType.ORDER_PARTIALLY_EXECUTED:
        case NotificationType.VOUCHER_CLAIMED:
        case NotificationType.VOUCHER_RECEIVED:
        case NotificationType.WITHDRAWAL_REQUEST_CONFIRMED:
        case NotificationType.DEPOSIT_CREDITED:
        case NotificationType.CRYPTO_WITHDRAWAL_AUTHORIZED:
        case NotificationType.CRYPTO_DEPOSIT_CREDITED:
        case NotificationType.TIER_VERIFIED: {
            return true;
        }

        case NotificationType.DEPOSIT_RETURNED:
        case NotificationType.CRYPTO_WITHDRAWAL_CANCELED:
        case NotificationType.TIER_DECLINED:
        case NotificationType.WITHDRAWAL_REQUEST_DECLINED: {
            return false;
        }

        default: {
            return true;
        }
    }
}
