import HttpClient from "../api/HttpClient";
import JwtUser from "../api/common/jwtUser/JwtUser";
import {api} from "../api/api";
import Bank from "../api/common/response/Bank";
import TierOneRequestViewModel from "../api/web/request/TierOneRequestViewModel";
import BasicResponse from "../api/web/response/BasicResponse";
import TierTwoRequestViewModel from "../api/web/request/TierTwoRequestViewModel";
import ChangePasswordViewModel from "../api/web/request/ChangePasswordViewModel";
import TwoFactorValidationRequestViewModel from "../api/web/request/TwoFactorValidationRequestModel";
import TwoFactorValidationRequestModel from "../api/web/request/TwoFactorValidationRequestModel";
import TransactionsReadRequestViewModel from "../api/web/request/TransactionsReadRequestViewModel";
import WithdrawalRequestViewModel from "../api/web/request/WithdrawalRequestViewModel";
import GenericTransactionViewModel from "../api/web/response/GenericTransactionViewModel";
import {areOrdersEqual, OrderViewModel} from "../api/web/response/OrderViewModel";
import {PlaceOrderRequestViewModel} from "../api/web/request/PlaceOrderRequestViewModel";
import {CancelOrderRequestViewModel} from "../api/web/request/CancelOrderRequestViewModel";
import {areNotificationsEqual, NotificationViewModel} from "../api/web/response/NotificationModel";
import {MobileUserTransferViewModel} from "../api/web/response/MobileUserTransferViewModel";

class UserService {
    private static instance: UserService | null = null;
    private httpClient: HttpClient = new HttpClient();
    private authToken: string | null = null;

    private user: JwtUser | null = null;
    private banks: Array<Bank> = [];

    private deposits: Array<GenericTransactionViewModel> = [];
    private withdrawals: Array<GenericTransactionViewModel> = [];
    private orders: Array<OrderViewModel> = [];
    private notifications: Array<NotificationViewModel> = [];
    private mobileTransfers: Array<MobileUserTransferViewModel> = [];

    private constructor() {}

    static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    setAuthToken(token: string | null) {
        this.authToken = token;
    }

    setUser(user: JwtUser) {
        this.user = user;
    }

    addOrder(order: OrderViewModel) {
        this.orders.push(order);
        this.orders.sort((a, b) => (a.datePlaced - b.datePlaced) * -1);
    }

    modifyOrderIfExists(order: OrderViewModel) {
        for (let i = 0; i < this.orders.length; i++) {
            if (this.orders[i].id === order.id) {
                this.orders[i] = order;
                break;
            }
        }
    }

    doesOrderExistIdentical(order: OrderViewModel): boolean {
        return this.orders.some(existingOrder => areOrdersEqual(existingOrder, order));
    }

    doesNotificationExistsIdentical(notification: NotificationViewModel): boolean {
        return this.notifications.some(existingNotification  => areNotificationsEqual(existingNotification, notification));
    }

    addNotification(notification: NotificationViewModel) {
        this.notifications.push(notification);
        this.notifications.sort((a, b) => (a.created - b.created) * -1);
    }

    addWithdrawal(withdrawal: GenericTransactionViewModel) {
        const index = this.withdrawals.findIndex((item) => item.id === withdrawal.id);
        if (index !== -1) {
            this.withdrawals[index] = withdrawal;
        } else {
            this.withdrawals.push(withdrawal);
            this.withdrawals.sort((a, b) => (a.created - b.created) * -1);
        }
    }

    addDeposit(deposit: GenericTransactionViewModel) {
        const index = this.deposits.findIndex((item) => item.id === deposit.id);
        if (index !== -1) {
            this.deposits[index] = deposit;
        } else {
            this.deposits.push(deposit);
            this.deposits.sort((a, b) => (a.created - b.created) * -1);
        }
    }

    getUser() {
        return this.user;
    }

    getBanks() {
        return this.banks;
    }

    getDeposits() {
        return this.deposits;
    }

    getWithdrawals() {
        return this.withdrawals;
    }

    getOrders() {
        return this.orders;
    }

    getNotifications() {
        return this.notifications;
    }

    getMobileTransfers() {
        return this.mobileTransfers;
    }

    private async fetchTransactions(transactionsReadRequestViewModel: TransactionsReadRequestViewModel) {
        try {
            return await this.httpClient.post<TransactionsReadRequestViewModel, BasicResponse>(api.web.GET_TRANSACTIONS, transactionsReadRequestViewModel, this.authToken || '');
        } catch (error) {
            console.log(error);
        }
    }

    async downloadUserFile(fileId: string) {
        try {
            return await this.httpClient.getBlob(api.web.DOWNLOAD_USER_FILE + fileId, undefined, this.authToken);
        } catch (error) {
            console.log(error);
        }
    }

    async requestTierOne(tierOneRequestViewModel: TierOneRequestViewModel, files: File[]) {
        try {
            return await this.httpClient.postWithFiles<TierOneRequestViewModel, BasicResponse>(api.web.REQUEST_TIER_ONE, tierOneRequestViewModel, files, this.authToken || '');
        } catch (error) {
            console.log(error);
        }
    }

    async requestTierTwo(tierTwoRequestViewModel: TierTwoRequestViewModel, files: File[]) {
        try {
            return await this.httpClient.postWithFiles<TierTwoRequestViewModel, BasicResponse>(api.web.REQUEST_TIER_TWO, tierTwoRequestViewModel, files, this.authToken || '');
        } catch (error) {
            console.log(error);
        }
    }

    async requestWithdrawal(withdrawalRequestViewModel: WithdrawalRequestViewModel) {
        try {
            return await this.httpClient.post<WithdrawalRequestViewModel, BasicResponse>(api.web.WITHDRAWAL, withdrawalRequestViewModel, this.authToken || '');
        } catch (error) {
            console.log(error);
        }
    }

    async placeOrder(placeOrderRequestViewModel: PlaceOrderRequestViewModel) {
        try {
            return await this.httpClient.post<PlaceOrderRequestViewModel, BasicResponse>(api.web.PLACE_ORDER, placeOrderRequestViewModel, this.authToken || '');
        } catch (error) {
            console.log(error);
        }
    }

    async cancelOrder(cancelOrderRequestViewModel: CancelOrderRequestViewModel) {
        try {
            return await this.httpClient.post<CancelOrderRequestViewModel, BasicResponse>(api.web.CANCEL_ORDER, cancelOrderRequestViewModel, this.authToken || '');
        } catch (error) {
            console.log(error);
        }
    }

    async changePassword(changePasswordViewModel: ChangePasswordViewModel) {
        try {
            return await this.httpClient.post<ChangePasswordViewModel, BasicResponse>(api.web.CHANGE_PASSWORD, changePasswordViewModel, this.authToken || '');
        } catch (error) {
            console.log(error);
        }
    }

    async changeNewsletterFlag(flag: boolean) {
        try {
            return await this.httpClient.get<BasicResponse>(api.web.RECEIVE_NEWSLETTER + flag, undefined, this.authToken);
        } catch (error) {
            console.log(error);
        }
    }

    async generateTwoFactorSecretAndQrLink() {
        try {
            return await this.httpClient.get<BasicResponse>(api.web.GENERATE_TWO_FACTOR_SECRET_AND_QR_LINK, undefined, this.authToken);
        } catch (error) {
            console.log(error);
        }
    }

    async enableTwoFactor(twoFactorValidationRequestViewModel: TwoFactorValidationRequestViewModel) {
        try {
            return await this.httpClient.post<TwoFactorValidationRequestModel, BasicResponse>(api.web.ENABLE_TWO_FACTOR, twoFactorValidationRequestViewModel, this.authToken || '');
        } catch (error) {
            console.log(error);
        }
    }

    async disableTwoFactor(twoFactorValidationRequestViewModel: TwoFactorValidationRequestViewModel) {
        try {
            return await this.httpClient.post<TwoFactorValidationRequestModel, BasicResponse>(api.web.DISABLE_TWO_FACTOR, twoFactorValidationRequestViewModel, this.authToken || '');
        } catch (error) {
            console.log(error);
        }
    }

    async fetchUser() {
        try {
            const response = await this.httpClient.get<JwtUser>(api.web.JWT_USER, undefined, this.authToken);
            this.user = response.data;
        } catch (error) {
            console.log(error);
        }
    }

    async fetchBankDetails() {
        try {
            const response = await this.httpClient.get<Array<Bank>>(api.web.BANK_DETAILS, undefined, this.authToken);
            this.banks = response.data;
        } catch (error) {
            console.log(error);
        }
    }

    async fetchDeposits() {
        const transactionsReadRequestViewModel: TransactionsReadRequestViewModel = {
            type: "DEPOSIT"
        };

        await this.fetchTransactions(transactionsReadRequestViewModel).then(response => {
            if (response && response.status === 200) {
                if (Array.isArray(response.data)) {
                    const data: GenericTransactionViewModel[] = response.data;
                    data.sort((a, b) => (a.created - b.created) * -1);
                    this.deposits = data;
                } else {
                    console.log(response.data + ' is not an array of GenericDepositViewModel');
                }
            } else {
                console.log('Unable to fetch deposit.');
            }
        });
    }

    async fetchWithdrawals() {
        const transactionsReadRequestViewModel: TransactionsReadRequestViewModel = {
            type: "WITHDRAW"
        };

        await this.fetchTransactions(transactionsReadRequestViewModel).then(response => {
            if (response && response.status === 200) {
                if (Array.isArray(response.data)) {
                    const data: GenericTransactionViewModel[] = response.data;
                    data.sort((a, b) => (a.created - b.created) * -1);
                    this.withdrawals = data;
                } else {
                    console.log(response.data + ' is not an array of GenericDepositViewModel');
                }
            } else {
                console.log('Unable to fetch withdrawals.');
            }
        });
    }

    async fetchOrders() {
        const transactionsReadRequestViewModel: TransactionsReadRequestViewModel = {
            type: "ORDER"
        };

        await this.fetchTransactions(transactionsReadRequestViewModel).then(response => {
            if (response && response.status === 200) {
                if (Array.isArray(response.data)) {
                    const data: OrderViewModel[] = response.data;
                    data.sort((a, b) => (a.datePlaced - b.datePlaced) * -1);
                    this.orders = data;
                } else {
                    console.log(response.data + ' is not an array of GenericDepositViewModel');
                }
            } else {
                console.log('Unable to fetch orders.');
            }
        });
    }

    async fetchMobileTransfers() {
        const transactionsReadRequestViewModel: TransactionsReadRequestViewModel = {
            type: "MOBILE"
        };

        await this.fetchTransactions(transactionsReadRequestViewModel).then(response => {
            if (response && response.status === 200) {
                if (Array.isArray(response.data)) {
                    const data: MobileUserTransferViewModel[] = response.data;
                    data.sort((a, b) => (a.created - b.created) * -1);
                    this.mobileTransfers = data;
                } else {
                    console.log(response.data + ' is not an array of MobileUserTransferViewModel');
                }
            } else {
                console.log('Unable to fetch mobile transfers.');
            }
        });
    }

    async fetchNotifications() {
        try {
            const response = await this.httpClient.get<Array<NotificationViewModel>>(api.web.GET_NOTIFICATIONS, undefined, this.authToken);
            if (response && response.status === 200) {
                this.notifications = response.data;
                this.notifications.sort((a, b) => (a.created - b.created) * -1);
            } else {
                console.log('Unable to fetch notifications')
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export default UserService;