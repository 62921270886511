import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';

import {useHistory} from "react-router-dom";
import UserService from "../../services/user/UserService";
import aaveIcon from "./icons/coin-icons/icon_aave.png";
import adaIcon from "./icons/coin-icons/icon_ada.png";
import apeIcon from "./icons/coin-icons/icon_ape.png";
import avaxIcon from "./icons/coin-icons/icon_avax.png";
import algoIcon from "./icons/coin-icons/icon_algo.png";
import atomIcon from "./icons/coin-icons/icon_atom.png";
import btcIcon from "./icons/coin-icons/icon_btc.png";
import compIcon from "./icons/coin-icons/icon_comp.png";
import daiIcon from "./icons/coin-icons/icon_dai.png";
import dogeIcon from "./icons/coin-icons/icon_doge.png";
import dotIcon from "./icons/coin-icons/icon_dot.png";
import ethIcon from "./icons/coin-icons/icon_eth.png";
import grtIcon from "./icons/coin-icons/icon_grt.png";
import linkIcon from "./icons/coin-icons/icon_link.png";
import ltcIcon from "./icons/coin-icons/icon_ltc.png";
import maticIcon from "./icons/coin-icons/icon_matic.png";
import mkrIcon from "./icons/coin-icons/icon_mkr.png";
import solIcon from "./icons/coin-icons/icon_sol.png";
import umaIcon from "./icons/coin-icons/icon_uma.png";
import uniIcon from "./icons/coin-icons/icon_uni.png";
import usdcIcon from "./icons/coin-icons/icon_usdc.png";
import usdtIcon from "./icons/coin-icons/icon_usdt.png";
import xlmIcon from "./icons/coin-icons/icon_xlm.png";
import xrpIcon from "./icons/coin-icons/icon_xrp.png";
import {formatNumber} from "../../services/utils/formatNumber";

const customStyles = {
    content: {
        height: '100%',
        left: 'auto',
        right: '0',
        bottom: '0',
        padding: '0',
        margin: '0',
        boxShadow: 'none',
        border: 'none',
    },
};


interface RightPanelModalProps {
    closeModal: () => void;
}

Modal.setAppElement('#root');

const WalletSidebarModal: React.FC<RightPanelModalProps> = ({closeModal}) => {
    const [scrollDisabled, setScrollDisabled] = useState(false);

    const history = useHistory();
    const [wallets, setWallets] = useState<any>(null);
    const userService = UserService.getInstance();

    const coinData = [
        {name: 'AAVE', icon: aaveIcon},
        {name: 'ADA', icon: adaIcon},
        {name: 'APE', icon: apeIcon},
        {name: 'AVAX', icon: avaxIcon},
        {name: 'ALGO', icon: algoIcon},
        {name: 'ATOM', icon: atomIcon},
        {name: 'BTC', icon: btcIcon},
        {name: 'COMP', icon: compIcon},
        {name: 'DAI', icon: daiIcon},
        {name: 'DOGE', icon: dogeIcon},
        {name: 'DOT', icon: dotIcon},
        {name: 'ETH', icon: ethIcon},
        {name: 'GRT', icon: grtIcon},
        {name: 'LINK', icon: linkIcon},
        {name: 'LTC', icon: ltcIcon},
        {name: 'MATIC', icon: maticIcon},
        {name: 'MKR', icon: mkrIcon},
        {name: 'SOL', icon: solIcon},
        {name: 'UMA', icon: umaIcon},
        {name: 'UNI', icon: uniIcon},
        {name: 'USDC', icon: usdcIcon},
        {name: 'USDT', icon: usdtIcon},
        {name: 'XLM', icon: xlmIcon},
        {name: 'XRP', icon: xrpIcon},
    ];

    useEffect(() => {
        if (!scrollDisabled) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        const user = userService.getUser();
        if (user) {
            setWallets(user?.wallets);
        }

        return () => {
            document.body.style.overflow = 'auto';
        };

    }, [scrollDisabled, wallets]);

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <div style={{paddingLeft: '20px'}}>
                    <div style={{display: 'flex', justifyContent: 'start', cursor: 'pointer'}} onClick={() => {
                        closeModal();
                        setScrollDisabled(true);
                    }}>
                        <p className="wallet-details-text" style={{marginRight: '4px'}}>&lt;</p>
                        <p className="wallet-details-text">Back</p>
                    </div>

                    <p>Wallet</p>

                    {wallets && (
                        <table>
                            <div className="rectangle" style={{backgroundColor: "#DDD", marginBottom: '15px'}}></div>
                            <tbody>
                            {coinData.map((coin, index) => (
                                <div>
                                    <div className="wallet-details-coin-box" style={{width: '365px'}}>
                                        <td style={{width: '25px'}}>
                                            <img src={coin.icon} alt={`${coin.name} Icon`}
                                                 className="wallet-details-icon"/>
                                        </td>
                                        <td className="wallet-details-text" style={{width: '40px'}}>{coin.name}</td>
                                        <td className="wallet-details-text" style={{
                                            width: '210px',
                                            textAlign: 'right'
                                        }}>{formatNumber(wallets[coin.name]?.balance, 8) || 0.00}</td>
                                        <td style={{paddingLeft: '20px', paddingBottom: '5px'}}>
                                            <button className="button-with-text-only" style={{marginRight: '20px'}} onClick={() => {
                                                history.push(`/withdraw?selectedCoin=${coin.name}`);
                                                closeModal();
                                            }}>&minus;</button>
                                            <button className="button-with-text-only" onClick={() => {
                                                history.push(`/deposit?selectedCoin=${coin.name}`);
                                                closeModal();
                                            }}>+
                                            </button>
                                        </td>
                                    </div>
                                    <div className="rectangle" style={{
                                        backgroundColor: "#DDD",
                                        marginBottom: '15px',
                                        marginTop: '15px'
                                    }}></div>
                                </div>
                            ))}
                            </tbody>
                        </table>
                    )}

                    <div style={{marginBottom: '50px'}}></div>
                </div>
            </Modal>
        </div>
    );
}

export default WalletSidebarModal;
