import Stomp from 'stompjs';
import { api } from '../services/api/api';

export type WebSocketMessageCallback = (message: any) => void;

interface ChannelSubscription {
    channel: string;
    callback: WebSocketMessageCallback;
}

class WebSocketService {
    private client: Stomp.Client | null;
    private isConnected: boolean;
    private isConnecting: boolean;
    private reconnectTimeout: NodeJS.Timeout | null;
    private subscriptions: ChannelSubscription[];

    constructor() {
        this.client = null;
        this.isConnected = false;
        this.isConnecting = false;
        this.reconnectTimeout = null;
        this.subscriptions = [];
    }

    connect(username: string, wsToken: string, channelSubscriptions: ChannelSubscription[]) {
        if (this.isConnecting || this.isConnected) {
            // Already connecting or connected, do nothing
            return;
        }

        const headers = {
            login: username,
            passcode: wsToken,
        };

        this.isConnecting = true;
        this.client = Stomp.client(api.SOCKET_URL);

        const onConnect = () => {
            console.log('Connected to WebSocket');
            this.isConnected = true;
            this.isConnecting = false;

            // Subscribe to channels based on channelSubscriptions
            channelSubscriptions.forEach((subscription) => {
                this.client?.subscribe(subscription.channel, (message: any) => {
                    const { body } = message;
                    subscription.callback(body);
                });
            });

            // Clear any existing reconnection attempts
            if (this.reconnectTimeout) {
                clearTimeout(this.reconnectTimeout);
                this.reconnectTimeout = null;
            }
        };

        const onDisconnect = () => {
            console.log('Disconnected from WebSocket');
            this.isConnected = false;
            this.isConnecting = false;

            // Schedule a reconnection attempt (adjust the delay as needed)
            const reconnectDelay = 1000; // 3 seconds
            this.reconnectTimeout = setTimeout(() => {
                this.connect(username, wsToken, channelSubscriptions);
            }, reconnectDelay);
        };

        this.client.connect(
            headers,
            onConnect,
            (error: any) => {
                console.error('WebSocket connection error:', error);
                onDisconnect(); // Handle the disconnect on error
            }
        );
    }

    disconnect() {
        if (this.client && (this.isConnected || this.isConnecting)) {
            this.client.disconnect(() => {
                console.log('Disconnected from WebSocket');
                this.isConnected = false;
                this.isConnecting = false;
            });
        }
    }
}

export default WebSocketService;
