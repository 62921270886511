import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Header from './components/header/Header';
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import Register from "./components/register/Register";
import {useAuth} from "./services/auth/AuthContext";
import ConfirmEmail from "./components/confirm-email/ConfirmEmail";
import Orders from "./components/orders/Orders";
import Trade from "./components/trade/Trade";
import Deposit from "./components/deposit/Deposit";
import Withdraw from "./components/withdraw/Withdraw";
import BittrexMarketSummaryService from "./services/bittrex-market-summary/BittrexMarketSummaryService";
import FeesTickersService from "./services/api/common/fees-tickers-service/FeesTickersService";
import UserService from "./services/user/UserService";
import AuthService from "./services/auth/AuthService";
import Account from "./components/account/Account";
import Settings from "./components/settings/Settings";
import DepositHistory from "./components/deposit/DepositHistory";
import WithdrawHistory from "./components/withdraw/WithdrawHistory";
import {useTradeWebSocket} from "./websocket/WebsocketContext";
import {WebSocketMessageCallback} from "./websocket/WebSocketService";
import {showErrorToast, showInfoToast, showSuccessToast} from "./services/utils/toastUtils";
import {toast, ToastContainer} from "react-toastify";
import logoIcon from "./components/header/icons/lg.png";
import MobileActivity from "./components/mobile-activity/MobileActivity";
import Referrals from "./components/referrals/Referrals";
import Help from "./components/help/Help";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import GenerateNewPassword from "./components/generate-new-password/GenerateNewPassword";
import TermsAndConditions from "./components/terms-and-conditions/TermsAndConditions";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
import CookiePolicy from "./components/cookie-policy/CookiePolicy";
import AmlPolicy from "./components/aml-policy/AmlPolicy";
import RefundPolicy from "./components/refund-policy/RefundPolicy";
import eventBus from "./EventBus";
import {
    getNotificationDescription,
    isNotificationGood,
    NotificationType,
    NotificationViewModel
} from "./services/api/web/response/NotificationModel";
import Faq from "./components/faq/Faq";
import About from "./components/about/About";
import Fees from "./components/fees/Fees";
import ExchangedAssets from "./components/exchanged-assets/ExchangedAssets";

interface PrivateRouteProps {
    component: React.ComponentType<any>;
    path: string;
    exact?: boolean;
}

const AppRouter = () => {
    const [isLoading, setIsLoading] = useState(true); // State to track loading status

    const {isLoggedIn, login} = useAuth();

    const userService = UserService.getInstance();

    const {webSocketService} = useTradeWebSocket();

    useEffect(() => {
        const initializeApp = async () => {
            const marketSummaryService = BittrexMarketSummaryService.getInstance();
            marketSummaryService.startFetchingInterval();

            const feesTickersService = FeesTickersService.getInstance();
            feesTickersService.fetchFeesAndMinTradeValueInSatoshi();
            feesTickersService.startFetchingInterval();

            const authService = new AuthService();
            if (authService.isLoggedIn()) {
                const token = authService.getToken() || '';
                login(token, true);
                await userService.fetchUser();
                await userService.fetchBankDetails();
                await userService.fetchDeposits();
                await userService.fetchWithdrawals();
                await userService.fetchOrders();
                await userService.fetchMobileTransfers();
                await userService.fetchNotifications();

                const onMessageCallback: WebSocketMessageCallback = (message) => {
                    try {
                        message = JSON.parse(message);
                        const newOrder = message.order;
                        if (!userService.doesOrderExistIdentical(newOrder)) {
                            userService.modifyOrderIfExists(message.order);
                            userService.setUser(message.user);
                            if (message.order.state === 'CLOSED') {
                                showInfoToast(toast, 'Order #' + message.order.id + ' was completed / closed.');
                            } else {
                                showInfoToast(toast, 'Order #' + message.order.id + ' was partially filled.');
                            }

                            eventBus.emit('refreshOrders');
                        }
                    } catch (error) {
                        console.log(error);
                    }
                };

                const onNotificationMessageCallback: WebSocketMessageCallback = async (message) => {
                    try {
                        message = JSON.parse(message);

                        const notif: NotificationViewModel = message;
                        if (!userService.doesNotificationExistsIdentical(notif)) {
                            userService.addNotification(notif);

                            const notifDescription = getNotificationDescription(notif);
                            if (isNotificationGood(notif)) {
                                showSuccessToast(toast, notifDescription);
                            } else {
                                showErrorToast(toast, notifDescription);
                            }

                            if (notif.type === NotificationType.WITHDRAWAL_REQUEST_DECLINED
                                || notif.type === NotificationType.WITHDRAWAL_REQUEST_CONFIRMED
                                || notif.type === NotificationType.CRYPTO_WITHDRAWAL_CANCELED
                                || notif.type === NotificationType.CRYPTO_WITHDRAWAL_AUTHORIZED) {
                                if (notif.refObject !== null) {
                                    userService.addWithdrawal(JSON.parse(notif.refObject));
                                    eventBus.emit('refreshWithdrawals');
                                }
                            } else if (notif.type === NotificationType.CRYPTO_DEPOSIT_CREDITED
                                || notif.type === NotificationType.DEPOSIT_CREDITED
                                || notif.type === NotificationType.DEPOSIT_RETURNED) {
                                if (notif.refObject !== null) {
                                    userService.addDeposit(JSON.parse(notif.refObject));
                                    eventBus.emit('refreshDeposits');
                                }
                            } else if (notif.type === NotificationType.TIER_VERIFIED
                                || NotificationType.TIER_DECLINED) {
                                await userService.fetchUser();
                                eventBus.emit('refreshUser');
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                };

                const channelSubscriptions = [
                    {channel: '/user/rooms/order_channel', callback: onMessageCallback},
                    {channel: '/user/rooms/notification_channel', callback: onNotificationMessageCallback},
                ];

                webSocketService.connect(userService.getUser()?.tierZeroInfo?.username || '', userService.getUser()?.wsToken || '', channelSubscriptions);
            }

            setIsLoading(false);

            return () => {
                webSocketService.disconnect();
            };
        };

        initializeApp().then(() => {
        });
    }, []);

    const PrivateRoute: React.FC<PrivateRouteProps> = ({component: Component, ...rest}) => (
        <Route {...rest} render={(props) => (
            isLoggedIn ? <Component {...props} /> : <Redirect to="/login"/>
        )}/>
    );

    // Render the loading spinner or the application content based on isLoading
    if (isLoading) {
        return <div>
            <div className="loading-container">
                <img className="logo-image" src={logoIcon} alt="Logo"/>
                <div style={{textAlign: 'center', paddingTop: '40px'}}>Loading...</div>
                <div className="loading-spinner"></div>
            </div>
        </div>;
    }

    return (
        <Router>
            <Header/>
            <ToastContainer bodyClassName="toastify-style" style={{minWidth: "350px", fontSize: '15px'}}/>
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/register" component={Register}/>
                <Route path="/forgot-password" component={ForgotPassword}/>
                <Route path="/auth/email-handler/:confirmationToken" component={ConfirmEmail}/>
                <Route path="/auth/reset-password/:forgotPasswordToken" component={GenerateNewPassword}/>

                <Route path="/terms-and-conditions" component={TermsAndConditions}/>
                <Route path="/privacy-policy" component={PrivacyPolicy}/>
                <Route path="/cookie-policy" component={CookiePolicy}/>
                <Route path="/aml-policy" component={AmlPolicy}/>
                <Route path="/refund-policy" component={RefundPolicy}/>
                <Route path="/faq" component={Faq}/>
                <Route path="/about" component={About}/>
                <Route path="/fees" component={Fees}/>
                <Route path="/exchanged-assets" component={ExchangedAssets}/>

                <PrivateRoute path="/dashboard" component={Dashboard}/>
                <PrivateRoute path="/orders" component={Orders}/>

                <PrivateRoute path="/trade" component={Trade}/>
                <PrivateRoute path="/deposit" component={Deposit}/>
                <PrivateRoute path="/deposit-history" component={DepositHistory}/>

                <PrivateRoute path="/withdraw" component={Withdraw}/>
                <PrivateRoute path="/withdraw-history" component={WithdrawHistory}/>

                <PrivateRoute path="/account" component={Account}/>
                <PrivateRoute path="/settings" component={Settings}/>
                <PrivateRoute path="/mobile-activity" component={MobileActivity}/>
                <PrivateRoute path="/referrals" component={Referrals}/>
                <PrivateRoute path="/help" component={Help}/>

                <Redirect from="/" to="/login"/>
            </Switch>
        </Router>
    );
};

export default AppRouter;
